import { useMemo } from 'react';

import { Indicator } from 'modules/creator/types';
import { EditedItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { getIndicatorParams } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';

export const useIndicatorParams = (editedItem?: EditedItem) => {
  const indicator = editedItem?.item as Indicator;
  const inDataOptions = useMemo(
    () => indicator.params.indata.data.map((item) => ({ label: item, value: item })),
    [indicator.params.indata.data],
  );
  const outDataOptions = useMemo(
    () =>
      indicator.params.outdata.map((outParam) => ({
        ...outParam,
        label: outParam.displayKey,
        value: outParam.name,
      })),
    [indicator.params.outdata],
  );
  const previousOutData = editedItem?.subItems?.indicator?.chosenOut;
  const previousInData = editedItem?.subItems?.indicator?.indata;
  const [displayableParams, nonDisplayableParams] = useMemo(
    () => getIndicatorParams(editedItem?.item as Indicator),
    [editedItem?.item],
  );

  return {
    indicator,
    inDataOptions,
    outDataOptions,
    defaultChosenOut:
      outDataOptions.length > 1 ? outDataOptions.find((option) => option.name === previousOutData) ?? null : null,
    defaultChosenIn: inDataOptions.find((option) => option.value === previousInData) ?? null,
    displayableParams: displayableParams.map((param) => ({
      ...param,
      defaultValue: editedItem?.subItems?.indicator
        ? editedItem.subItems?.indicator[param.backendName] ?? param.defaultValue
        : param.defaultValue,
    })),
    nonDisplayableParams,
  };
};
