import styled from 'styled-components/macro';

import { useStrategyContext } from 'modules/creator/context';
import { CircleLoader, Icon } from 'common/components';

export const AutoSaveInfo = () => {
  const { isStrategySaving } = useStrategyContext();
  return isStrategySaving ? (
    <Styled.Wrapper>
      <CircleLoader size="S" />
      <span>Saving...</span>
    </Styled.Wrapper>
  ) : (
    <Styled.Wrapper>
      <Icon icon="tick" />
      <span>Saving complete!</span>
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
  `,
};
