import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

type BoxProps = {
  width?: string;
};

export const Box = ({ children, width }: PropsWithChildren<BoxProps>) => (
  <Styled.Container width={width}>{children}</Styled.Container>
);

const Styled = {
  Container: styled.div<{ width?: string }>`
    display: flex;
    position: relative;
    width: ${({ width = 'auto' }) => width};
    flex-direction: column;
    padding: 24px;
    border: 1px solid ${({ theme }) => theme.colors.black[3]};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.elevation[1]};
    gap: 16px;
  `,
};
