import { isStrategyFlowEmpty } from 'common/utils';
import { PartialStrategy } from 'types';

export const isStrategyEmpty = (strategy: PartialStrategy) => {
  const fieldsToIgnoreFields = ['botColor', 'created', 'isExpanded', 'timestamp', 'modals'];
  const strategyKeysWithoutIgnored = Object.keys(strategy).filter((field) => !fieldsToIgnoreFields.includes(field));
  const isEmpty =
    strategyKeysWithoutIgnored.length === 0 ||
    (strategyKeysWithoutIgnored.length === 1 &&
      strategyKeysWithoutIgnored.includes('flow') &&
      isStrategyFlowEmpty(strategy.flow));

  return isEmpty;
};
