export type BasicColors = {
  paleGreen: string;
  blue: string;
  orange: string;
};

export type ColorPalettes = {
  purple: string[];
  black: string[];
  white: string[];
  elevation: string[];
  green: string[];
  red: string[];
  yellow: string[];
};

export type ButtonColors = {
  default: string;
  hover: string;
  pressed: string;
  inactive: string;
};

export type Buttons = {
  default: ButtonColors;
  primary: ButtonColors;
  secondary: ButtonColors;
  ghost: ButtonColors;
};

export type ZIndexes = {
  modalLeveling: (level: number) => number;
  tooltip: 400;
  notification: 300;
  modal: 104;
  modalOverlay: 103;
  dropdown: 200;
  overlay: 101;
  layoutBelow: 99;
};

export type Theme = {
  colors: BasicColors & ColorPalettes;
  buttons: Buttons;
  zIndexes: ZIndexes;
};

export const theme: Theme = {
  colors: {
    purple: ['#9233c2', '#844ac7', '#6546a8', '#795fce', '#8074d7'],
    black: ['#141622', '#20222d', '#363842', '#4a4c55'],
    elevation: ['#141622', '#1D1F2B', '#262833', '#2F313B', '#373943', '#3F414B'],
    white: ['#fafbfb', '#bec3c7', '#969797'],
    red: ['#ff5458', '#FF989B', '#FF7679', '#993235'],
    green: ['#00ad74', '#66CEAC'],
    orange: '#EC9411',
    paleGreen: '#33BD90',
    yellow: ['#FBF7CF', '#F7EFA0', '#F4E670', '#F0DE41', '#ECD611', '#BDAB0E', '#8E800A', '#2F2B03', '#2F2B03'],
    blue: '#5737c2',
  },
  buttons: {
    default: {
      default: '#4a4c55',
      hover: '#5e6068',
      pressed: '#31323b',
      inactive: '#363842',
    },
    primary: {
      default: '#9c319e',
      hover: '#a646a8',
      pressed: '#7d277e',
      inactive: '#693470',
    },
    secondary: {
      default: '#FCEE6F',
      hover: '#F7EFA0',
      pressed: '#F0DE41',
      inactive: '#BDAB0E',
    },
    ghost: {
      default: 'transparent',
      hover: 'transparent',
      pressed: 'transparent',
      inactive: 'transparent',
    },
  },
  zIndexes: {
    modalLeveling: (level: number) => 104 + level,
    tooltip: 400,
    notification: 300,
    modal: 104,
    modalOverlay: 103,
    dropdown: 200,
    overlay: 101,
    layoutBelow: 99,
  },
};
