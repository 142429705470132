import { useCallback } from 'react';

import { useWriteDocument } from 'common/hooks';
import { PartialStrategy } from 'types';

export const useCopyStrategy = () => {
  const writeDocument = useWriteDocument();

  const copyStrategy = useCallback(
    (oldStrategyId: string, newStrategyId: string, strategy: PartialStrategy) => {
      const numberOfVersions = strategy.subStrategies?.length;

      writeDocument(`strategies/${newStrategyId}`, {
        ...strategy,
        subStrategies: null,
        created: Date.now(),
        timestamp: Date.now(),
      });
      writeDocument(`strategies/${oldStrategyId}/subStrategies`, [...(strategy.subStrategies ?? []), newStrategyId]);

      if (strategy.name) {
        const newName = `${strategy.name} <${(numberOfVersions ?? 0) + 1}>`;

        writeDocument(`strategies/${newStrategyId}/name`, newName);
        writeDocument(`indexes/strategiesNames/${newStrategyId}`, newName);
      }
    },
    [writeDocument],
  );

  return copyStrategy;
};
