import pluralize from 'pluralize';

import { SelectOption } from 'types';
import { Unit } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

export type TimeFrame = {
  scope: number;
  unit: Unit;
};

const internalTimeFrames: SelectOption[] = [
  {
    scope: 1,
    unit: { value: 'minute', label: 'minute', symbol: 'm' },
  },
  {
    scope: 2,
    unit: { value: 'minute', label: 'minutes', symbol: 'm' },
  },
  {
    scope: 3,
    unit: { value: 'minute', label: 'minutes', symbol: 'm' },
  },
  {
    scope: 5,
    unit: { value: 'minute', label: 'minutes', symbol: 'm' },
  },
  {
    scope: 15,
    unit: { value: 'minute', label: 'minutes', symbol: 'm' },
  },
  {
    scope: 30,
    unit: { value: 'minute', label: 'minutes', symbol: 'm' },
  },
  {
    scope: 45,
    unit: { value: 'minute', label: 'minutes', symbol: 'm' },
  },
  {
    scope: 1,
    unit: { value: 'hour', label: 'hour', symbol: 'h' },
  },
  {
    scope: 2,
    unit: { value: 'hour', label: 'houres', symbol: 'h' },
  },
  {
    scope: 3,
    unit: { value: 'hour', label: 'houres', symbol: 'h' },
  },
  {
    scope: 4,
    unit: { value: 'hour', label: 'houres', symbol: 'h' },
  },
  {
    scope: 1,
    unit: { value: 'day', label: 'days', symbol: 'D' },
  },
  {
    scope: 1,
    unit: { value: 'week', label: 'weeks', symbol: 'W' },
  },
];

export const timeFrames: SelectOption<TimeFrame>[] = internalTimeFrames.map((item) => ({
  ...item,
  label: `${item.scope} ${item.unit.label}`,
  value: `${item.scope} ${item.unit.value}`,
}));
