export enum InputWidth {
  S = '100px',
  M = '130px',
  L = '240px',
  MAX = '100%',
}

export type SelectOption<T = any> = {
  value: string;
  label: string;
} & T;
