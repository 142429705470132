import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components/macro';

import { OrderActions, TradingType } from 'types';
import { Button, Heading } from 'common/components';
import { CloseIcon } from 'assets/icons';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { FlowControls, ConditionsLayer } from './components';
import { convertFlowTypeToText, getDefaultConditionsFlowData, isValueConditionModal } from './utils';
import { useDraggableLayer, useFlowData, useFlowResultData } from './hooks';

export const ConditionsFlow = () => {
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const container = useRef<HTMLDivElement>(null);
  const flowItemsRef = useRef<HTMLDivElement>(null);
  const { writeStrategy, removeInStrategy } = useStrategyContext();
  const [details] = useFlowData('details');
  const { handleDrag, handleDragStart, handleScrolling, draggableRef } = useDraggableLayer();
  const result = useFlowResultData(orderAction);

  const { position } = details || {};

  const saveFlowData = () => {
    writeStrategy(`modals/${tradingType}/${orderAction}`, false);
    if (result.text) {
      writeStrategy(`flow/${tradingType}/${orderAction}/result`, result);
    } else {
      removeInStrategy(`flow/${tradingType}/${orderAction}`);
    }
  };

  const getClassName = () => {
    if (tradingType !== TradingType.long) {
      return undefined;
    }

    if (orderAction === OrderActions.OPEN) {
      return 'onboarding-strategy-open-save';
    }

    return 'onboarding-strategy-stop-loss-save';
  };

  useEffect(() => {
    if (details === null) {
      writeStrategy(
        `flow/${tradingType}/${orderAction}/details`,
        getDefaultConditionsFlowData(isValueConditionModal(orderAction)),
      );
    }
  }, [details, writeStrategy, orderAction, tradingType]);

  if (!details) return null;

  return createPortal(
    <Styled.Container ref={container}>
      <Styled.DraggableLayer
        draggable
        onDragOver={handleDrag}
        onDragStart={handleDragStart}
        onWheel={handleScrolling}
        ref={draggableRef}
      />
      <ConditionsLayer position={position} flowItemsRef={flowItemsRef} />
      <Styled.TopWrapper placement="left">
        <Heading level="h2">
          {`Set ${isValueConditionModal(orderAction) ? 'value' : 'conditions'} for ${convertFlowTypeToText(
            orderAction,
          )}`}
        </Heading>
      </Styled.TopWrapper>
      <Styled.TopWrapper placement="right">
        <Button onClick={saveFlowData} variant="ghost">
          <CloseIcon />
        </Button>
      </Styled.TopWrapper>
      <Styled.SaveButton variant="primary" fitWidth onClick={saveFlowData} className={getClassName()}>
        Save
      </Styled.SaveButton>
      <FlowControls container={container} flowItemsRef={flowItemsRef} />
    </Styled.Container>,
    document.getElementById('modal') as HTMLElement,
  );
};

const Styled = {
  Container: styled.div`
    position: fixed;
    width: 80vw;
    height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.black[1]};
    z-index: ${({ theme }) => theme.zIndexes.modal};
    border-radius: 20px;
    overflow: hidden;
  `,

  TopWrapper: styled.div<{ placement: 'right' | 'left' }>`
    position: absolute;
    z-index: ${({ theme }) => theme.zIndexes.modal};
    padding: ${({ placement }) => (placement === 'left' ? '30px 0 0 30px' : '30px 30px 0 0')};
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ placement }) =>
      placement === 'left'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
  `,

  SaveButton: styled(Button)`
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 125px;
    z-index: ${({ theme }) => theme.zIndexes.modal};
  `,

  DraggableLayer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${({ theme }) => theme.zIndexes.modalOverlay};
    cursor: grab;
  `,
};
