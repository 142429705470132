import { BoundingBoxType, ConditionFlowItemType } from 'modules/creator/components/ConditionsFlow/types';
import { getDefaultFlowItems } from 'modules/creator/components/ConditionsFlow/utils';

export const normalizeFlowItems = (
  items: Record<string, ConditionFlowItemType | null>,
  firstIt = true,
): Record<string, ConditionFlowItemType | null> => {
  const normalizedItems = items;
  const lastInBoxes = [];

  type AssignItemsToNewParentsArgs = {
    child: ConditionFlowItemType; /// Child of item -> child of parent
    parent?: BoundingBoxType; /// Parent of item -> parent of child (parent is undefined when it is the last "main" box)
    item: BoundingBoxType; /// Will be deleted
  };

  /// Removing box if it is single child of other box. Assign children of this box to its parent.
  const assignItemsToNewParents = ({ child, parent, item }: AssignItemsToNewParentsArgs) => {
    if (child?.type === 'box') {
      normalizedItems[child.id] = {
        ...child,
        depth: parent?.depth ?? 0,
        parent: parent?.id ?? '',
        whichInOrder: item.whichInOrder,
      };
    } else {
      normalizedItems[child.id] = {
        ...child,
        parent: parent?.id ?? '',
        whichInOrder: item.whichInOrder,
        relationType: parent?.childrenRelationType ?? 'or',
      };
    }

    if (parent) {
      normalizedItems[parent.id] = {
        ...parent,
        children: [...parent.children, child.id],
      };
    }

    normalizedItems[item.id] = null;
  };

  /// Removing children of deleted box.
  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'box') {
      const filtretedChildren = item.children.filter((child) => normalizedItems[child] !== null);
      normalizedItems[item.id] = { ...item, children: filtretedChildren };
    }
  }
  const recurrency = (deleted: boolean) => {
    deleted = false;
    for (const key in normalizedItems) {
      const item = normalizedItems[key];
      if (item && normalizedItems[item.parent] === null) {
        deleted = true;
        normalizedItems[item.id] = null;
      }
    }

    return deleted;
  };

  let deleted = false;
  do {
    deleted = recurrency(deleted);
  } while (deleted);

  /// prepare for removing unnecessary arrows, and removing single box in single box.
  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'box') {
      const singleChild = normalizedItems[item.children[0]];
      if (
        item.children.length === 1 &&
        ((singleChild?.type === 'node' && item.parent) || singleChild?.type === 'box')
      ) {
        assignItemsToNewParents({
          child: singleChild,
          parent: normalizedItems[item.parent] as BoundingBoxType | undefined,
          item: item,
        });
      }
    }
  }

  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'box') {
      const filtretedChildren = item.children.filter((child) => normalizedItems[child] !== null);
      normalizedItems[item.id] = { ...item, children: filtretedChildren };
    }
  }

  /// Removing placeholder nodes (We have to remove them to maintain the proper structure).
  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'node') {
      if (!(item.rel || item.text)) {
        normalizedItems[key] = null;
      }
    }
  }

  // Merge boxes when one is inside another, and children have the same direction.
  for (const key in normalizedItems) {
    const item = { ...normalizedItems[key] } as ConditionFlowItemType | null;
    if (item?.type === 'box') {
      const childId = item.children[0];
      const child = normalizedItems[childId];
      if (child) {
        const childrenRelation = child.type === 'node' ? child.relationType : child.selfRelationType;
        const parent = normalizedItems[item.parent];
        if (parent?.type === 'box' && childrenRelation === parent.childrenRelationType) {
          /// Change item children order
          item.children.forEach((id) => {
            const childItem = normalizedItems[id];

            if (childItem) {
              normalizedItems[id] = {
                ...childItem,
                parent: parent.id,
                whichInOrder: item.whichInOrder + childItem.whichInOrder,
              };
            }
          });
          /// Change children order of parent of item (new item siblings)
          parent.children.forEach((childElId) => {
            const childEl = normalizedItems[childElId];
            if (childEl) {
              normalizedItems[childElId] = {
                ...childEl,
                whichInOrder:
                  childEl.whichInOrder > item.whichInOrder
                    ? childEl.whichInOrder + item.children.length - 1
                    : childEl.whichInOrder,
              };
            }
          });
          normalizedItems[key] = null;
          if (parent) {
            normalizedItems[parent.id] = {
              ...parent,
              children: [...parent.children.filter((id) => !!normalizedItems[id]), ...item.children],
            };
          }
        }
      }
    }
  }

  // Add arrow to all items, which have parent.
  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item) {
      normalizedItems[key] = {
        ...item,
        arrow: !!item.parent,
      };
    }
  }

  // Prepare array with last item in each box.
  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'box') {
      const lastInBox = item.children.reduce<{ order: number; item: ConditionFlowItemType | null }>(
        (prev, curr) => {
          const currentItem = normalizedItems[curr];
          if (currentItem && currentItem.whichInOrder > prev.order) {
            return { order: currentItem.whichInOrder, item: currentItem };
          }
          return prev;
        },
        { order: -1, item: null },
      );

      lastInBoxes.push(lastInBox);
    }
  }

  /// Removing unnecessary arrows.
  lastInBoxes.forEach(({ item }) => {
    if (!item) return;
    normalizedItems[item.id] = {
      ...item,
      arrow: false,
    };
  });

  /// If nothing remains after deletion, create default items.
  if (Object.values(normalizedItems).filter((item) => item?.type === 'node').length === 0) {
    Object.keys(normalizedItems).forEach((key) => delete normalizedItems[key]);
    const { node, box } = getDefaultFlowItems();
    normalizedItems[node.id] = node;
    normalizedItems[box.id] = box;
  }

  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'box') {
      const singleChild = normalizedItems[item.children[0]];
      if (item.children.length === 1 && singleChild) {
        if (singleChild.type === 'node') {
          normalizedItems[item.children[0]] = {
            ...singleChild,
            relationType: null,
          };
        }
        if (item.parent) {
          normalizeFlowItems(normalizedItems, false);
        }
      }
    }
  }

  /// Repeat process if necessary
  for (const key in normalizedItems) {
    const item = normalizedItems[key];
    if (item?.type === 'box') {
      const singleChild = normalizedItems[item.children[0]];
      if (item.children.length === 1 && singleChild && item.parent) {
        normalizeFlowItems(normalizedItems, false);
      }
    }
  }

  // Repeat process at least once
  if (firstIt) {
    normalizeFlowItems(normalizedItems, false);
  }

  return normalizedItems;
};
