import { forwardRef, TextareaHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components/macro';

import { Flex } from 'common/components/Flex';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  error?: FieldError;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ name, label, error, ...props }, ref) => (
  <Flex direction={'column'} align="flex-start" gap={8}>
    {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
    <Styled.TextArea name={name} {...props} ref={ref} />
    {error && <Styled.Error>{error.message}</Styled.Error>}
  </Flex>
));

TextArea.displayName = 'TextArea';

const Styled = {
  TextArea: styled.textarea`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black[2]};
    height: 116px;
    border-radius: 3px;
    display: flex;
    align-items: flex-start;
    padding: 5px;
    color: ${({ theme }) => theme.colors.white[0]};
    font-size: 16px;
    border: none;
    resize: none;

    &:focus {
      outline: none;
    }
  `,

  Label: styled.label`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white[1]};
    white-space: normal;
  `,

  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
