import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

interface TagProps {
  color?: string;
}

export const Tag = ({ children, ...props }: PropsWithChildren<TagProps>) => (
  <Styled.Box {...props}>{children}</Styled.Box>
);

const Styled = {
  Box: styled.div<TagProps>`
    color: ${({ theme }) => theme.colors.white[0]};
    display: flex;
    align-items: center;
    background-color: ${({ color = 'default' }) => color};
    border-radius: 3px;
    gap: 4px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 4px 8px;
  `,
};
