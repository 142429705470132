import { Indicator } from 'modules/creator/types';

export const getIndicatorParams = (indicator: Indicator) => {
  const indicatorParams = indicator.params;
  const displayableParams = [];
  const nonDisplayableParams = [];

  for (const key in indicatorParams) {
    if (['indata', 'outdata'].includes(key)) {
      continue;
    }

    if (indicatorParams[key].toDisplay) {
      displayableParams.push({ backendName: key, ...indicatorParams[key] });
    }

    nonDisplayableParams.push({ backendName: key, ...indicatorParams[key] });
  }

  return [displayableParams, nonDisplayableParams];
};
