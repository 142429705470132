import { useState } from 'react';
import styled from 'styled-components/macro';

import { Button, Icon } from 'common/components';
import { SummaryStrategyModal } from 'modules/creator/components/SummaryStrategModal';
import { useStrategyChecklist } from 'modules/creator/components/Checklist/hooks';

export const Checklist = () => {
  const [isOpenSummaryModal, setIsOpenSummaryModal] = useState(false);
  const { isStrategyDone, checklist } = useStrategyChecklist();

  return (
    <Styled.Wrapper className="onboarding-checklist">
      <Styled.Heading>Check list:</Styled.Heading>
      {isOpenSummaryModal && <SummaryStrategyModal setIsModalOpen={setIsOpenSummaryModal} />}
      <Styled.List>
        {checklist.map(({ label, isDone }) => (
          <Styled.ListItem key={label} isDone={isDone}>
            {isDone && (
              <Styled.ItemTick>
                <Icon icon="tick" />
              </Styled.ItemTick>
            )}
            <span>{label}</span>
          </Styled.ListItem>
        ))}
      </Styled.List>
      <Button
        onClick={() => setIsOpenSummaryModal(true)}
        variant="primary"
        disabled={!isStrategyDone}
        className="onboarding-checklist-complete"
      >
        <Icon icon="bot" />
        Complete
      </Button>
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div`
    background-color: ${({ theme }) => theme.colors.elevation[3]};
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
    min-width: 275px;
    height: fit-content;
  `,

  Heading: styled.h1`
    margin: 0;
    font-size: 36px;
    font-weight: 400;
  `,

  List: styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style-type: none;
  `,

  ListItem: styled.li<{ isDone: boolean }>`
    padding-left: 24px;
    position: relative;
    font-size: 16px;
    text-decoration: ${({ isDone, theme }) => (isDone ? `line-through ${theme.colors.green[1]}` : 'none')};
    color: ${({ isDone, theme }) => (isDone ? theme.colors.green[1] : 'inherit')};
  `,

  ItemTick: styled.div`
    position: absolute;
    left: 0;
    height: 24px;
    top: -4px;
  `,
};
