import { useCallback, useState } from 'react';

import { TestedReportResponseType, PendingReportResponseType } from 'types/StrategyTypes';
import { sendAuthorizedApiRequest } from 'common/utils';
import { TestedStrategy } from 'modules/strategies/types';

export const useGetReports = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<TestedStrategy[]>([]);
  const [pendingReports, setPendingReports] = useState<TestedStrategy[]>([]);
  const [testedReports, setTestedReports] = useState<TestedStrategy[]>([]);

  const handleGetPendingReports = useCallback(async () => {
    setLoading(true);
    const res = await sendAuthorizedApiRequest({
      url: `${process.env.REACT_APP_API_V1}/reports/pending`,
      method: 'GET',
    });

    if (!res?.data) return;

    const parsedPendingReports = res.data.map((item: PendingReportResponseType) => ({
      id: item.testid,
      name: item.strategyname,
      type: 'pending',
      asset: {
        symbol: item.symbol,
        market: item.market,
      },
      ...item,
    }));

    setPendingReports(parsedPendingReports);
    setReports((prevState) => [...prevState, ...parsedPendingReports]);
    setLoading(false);
  }, []);

  const handleGetTestedReports = useCallback(async () => {
    setLoading(true);
    const res = await sendAuthorizedApiRequest({
      url: `${process.env.REACT_APP_API_V1}/reports/tested`,
      method: 'GET',
    });

    if (!res?.data) return;

    const parsedTestedReports = res.data.map((item: TestedReportResponseType) => ({
      id: item.testid,
      name: item.strategyname,
      type: 'tested',
      asset: {
        symbol: item.symbol,
        market: item.market,
      },
      ...item,
    }));

    setTestedReports(parsedTestedReports);
    setReports((prevState) => parsedTestedReports);
    setLoading(false);
  }, []);

  const handleGetAllReports = useCallback(async () => {
    setLoading(true);
    await handleGetPendingReports();
    await handleGetTestedReports();
    setLoading(false);
  }, [handleGetPendingReports, handleGetTestedReports]);

  return {
    reports,
    pendingReports,
    testedReports,
    testedReportsCount: testedReports.length,
    loading,
    handleGetAllReports,
    handleGetPendingReports,
    handleGetTestedReports,
  };
};
