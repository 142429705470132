import { FC } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import { LoginView, ResetPasswordView, ConfirmPasswordView, ConfirmPasswordSuccessView } from 'modules';

import { routes } from './routes';

export const UnauthenticatedRouter: FC = () => (
  <Routes>
    <Route path={routes.login} element={<LoginView />} />
    <Route path={routes.reset} element={<ResetPasswordView />} />
    <Route path={routes.confirmPassword} element={<ConfirmPasswordView />} />
    <Route path={routes.confirmPasswordSuccess} element={<ConfirmPasswordSuccessView />} />
    <Route path="*" element={<Navigate to={routes.login} />} />
  </Routes>
);
