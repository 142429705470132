import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  * {
    &::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.colors.black[2]};
      width: 8px;
      border-radius: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.black[3]};
      border-radius: 1px;
      border: 1px solid #595b62; // The color should be replaced by a variable when added to the palette.
    }
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  html {
    font-size: 62.5%; // 10px, happy rems
  }

  body {
    background-color: ${({ theme }) => theme.colors.black[0]};
    color: ${({ theme }) => theme.colors.white[0]};
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }

  textarea {
    font-family: inherit;
  }

  button {
    font-family: "Inter", sans-serif;
    border: none;
    background-color: inherit;
    color: inherit;

    &:hover {
      cursor: pointer;
    }
  }
`;
