import { createContext, PropsWithChildren, useContext, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Dictionary } from 'modules/creator/types';
import { useDocument, useRemoveDocument, useWriteDocument } from 'common/hooks';
import { PositionType } from 'modules/creator/components/ConditionsFlow/types';
import { useGetDictionary, useCreateStrategy } from 'modules/creator/hooks';

export enum Status {
  LOADING = 'loading',
  Done = 'done',
}

type StrategyCreatorContextType = {
  dictionary?: Dictionary;
  strategyStatus: Status;
  strategy?: any;
  writeStrategy: (path: string, data: any) => void;
  isStrategySaving: boolean;
  cursorPosition?: PositionType;
  removeInStrategy: (path: string) => void;
};

const StrategyCreatorContext = createContext<StrategyCreatorContextType>({
  strategyStatus: Status.LOADING,
  writeStrategy: (path, data) => {},
  isStrategySaving: false,
  removeInStrategy: (path) => {},
});

export const StrategyCreatorDataProvider = ({ children }: PropsWithChildren) => {
  const { dictionary } = useGetDictionary();
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const [isStrategySaving, setIsStrategySaving] = useState(false);
  const [firebaseUpdateCount, setFirebaseUpdateCount] = useState(0);
  const { id } = useParams();
  const [strategy] = useDocument(`strategies/${id}`);
  const remover = useRemoveDocument();
  const writeDocument = useWriteDocument();
  const createStrategy = useCreateStrategy();

  const writeStrategy = useCallback(
    async (path: string, data: any) => {
      setFirebaseUpdateCount((prev) => ++prev);

      await writeDocument(`strategies/${id}/${path}`, data);
      await writeDocument(`strategies/${id}/timestamp`, Date.now());

      setTimeout(() => setFirebaseUpdateCount((prev) => --prev), 200);
    },
    [id, writeDocument],
  );

  const removeInStrategy = useCallback(
    (path: string) => {
      if (!path) {
        return;
      }
      remover(`strategies/${id}/${path}`);
    },
    [id, remover],
  );

  useEffect(() => {
    if (strategy === null && id) {
      createStrategy(id);
    }
  }, [strategy, id, createStrategy]);

  useEffect(() => {
    if (strategy === undefined || id === undefined) {
      setStatus(Status.LOADING);
    } else {
      setStatus(Status.Done);
    }
  }, [id, strategy]);

  useEffect(() => {
    if (firebaseUpdateCount > 0) {
      setIsStrategySaving(true);
    } else {
      setIsStrategySaving(false);
    }
  }, [firebaseUpdateCount]);

  return (
    <StrategyCreatorContext.Provider
      value={{
        dictionary,
        strategyStatus: status,
        strategy,
        writeStrategy,
        isStrategySaving,
        removeInStrategy,
      }}
    >
      {children}
    </StrategyCreatorContext.Provider>
  );
};

export const useStrategyContext = () => {
  const strategyData = useContext(StrategyCreatorContext);

  return strategyData;
};
