import { useMemo } from 'react';

import { HintableItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { useDocument } from 'common/hooks';

export const useFavouriteHints = () => {
  const [favouriteItems] = useDocument<HintableItem[]>('favouriteIndicators');
  const safeFavouriteItems = useMemo(() => favouriteItems ?? [], [favouriteItems]);

  return [safeFavouriteItems];
};
