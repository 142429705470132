import { useMemo, useEffect, useState } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { theme } from 'styles/theme';
import { Input, Modal, SelectInput, Icon, Tooltip } from 'common/components';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { Candle, CandleVar, TextEngineItemTypes } from 'modules/creator/types';
import {
  createResultCandleObject,
  getLastUsedCandle,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';
import { SelectOption } from 'types';
import { timeFrames } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/components/IndicatorSetterModal/timeFrames';
import { useConditionSetterContext } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';

type Frame = {
  scope: string;
  unit: SelectOption;
};

type CandleValues = {
  candle: SelectOption<Candle> | null;
  frame: Frame;
  rangeScope: string;
};

export const CandleSetterModal = () => {
  const [isDefaultCandleUnitRespected, setIsDefaultCandleUnitRespected] = useState(true);
  const { resultSentence, setResultSentence, setDisplayedModalInfo, updateSentenceAndHints, editedItem } =
    useConditionSetterContext();
  const candleVar = editedItem?.item as CandleVar;
  const { dictionary } = useStrategyContext();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    resetField,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<CandleValues>({
    defaultValues: {
      candle: null,
      frame: {
        scope: '',
        unit: '',
      },
      rangeScope: '',
    },
  });
  const watchCandle = watch('candle');
  const candleTypes = useMemo(
    () => Object.values(dictionary?.candles || {}).map((item) => ({ ...item, label: item.name, value: item.name })),
    [dictionary?.candles],
  );

  const { lastUsedResultCandle, lastUsedCandleType, frameDefaultValue } = useMemo(
    () =>
      getLastUsedCandle({
        sentence: resultSentence,
        candlesList: candleTypes,
        index: editedItem?.index,
        isDefaultCandleUnitRespected,
      }),
    [resultSentence, candleTypes, editedItem?.index, isDefaultCandleUnitRespected],
  );

  useEffect(() => {
    if (lastUsedCandleType) {
      setValue('candle', lastUsedCandleType);
    }
  }, [lastUsedCandleType, setValue]);

  useEffect(() => {
    if (lastUsedResultCandle?.unit) {
      if (lastUsedResultCandle.unit.value !== 'pip') {
        setValue('frame.scope', lastUsedResultCandle.value);
      } else {
        setValue('rangeScope', lastUsedResultCandle.value);
      }
    }
  }, [lastUsedResultCandle, setValue]);

  useEffect(() => {
    if (frameDefaultValue?.unit) {
      setValue('frame.unit', frameDefaultValue.unit);
    }
  }, [frameDefaultValue?.unit, setValue]);

  const isValidAndReadyToSubmit = ({ candle, frame, rangeScope }: CandleValues) => {
    if (candle?.windowtype === 'time' && (!frame.unit || !frame.scope)) {
      return false;
    }

    if (candle?.windowtype === 'range' && !rangeScope) {
      return false;
    }

    return !!candle;
  };

  const cancelCandle = () => {
    setDisplayedModalInfo(null);
  };
  const submitCandle: SubmitHandler<CandleValues> = (candleValues) => {
    const { candle, frame, rangeScope } = candleValues;
    if (!isValidAndReadyToSubmit(candleValues) || !candle) return;

    const resultCandle = createResultCandleObject(candle, {
      scope: candle.windowtype === 'time' ? frame.scope : rangeScope,
      unit: frame.unit,
    });
    const stringContent = {
      main: candleVar.name.toUpperCase(),
      params: `(${candle.windowtype === 'time' ? frame.scope : rangeScope}${frame.unit.symbol}, ${candle.name})`,
    };
    setResultSentence((prev) =>
      editedItem?.index !== undefined && editedItem?.subItems
        ? prev.map((item, index) =>
            index === editedItem.index
              ? {
                  stringContent,
                  origin: candleVar,
                  type: TextEngineItemTypes.CandleVar,
                  items: { candle: resultCandle },
                }
              : item,
          )
        : [
            ...prev,
            {
              stringContent,
              origin: candleVar,
              type: TextEngineItemTypes.CandleVar,
              items: { candle: resultCandle },
            },
          ],
    );
    updateSentenceAndHints({ hint: { item: candleVar, type: TextEngineItemTypes.CandleVar } });
    setDisplayedModalInfo(null);
  };

  return (
    <Modal
      heading={candleVar.name.toUpperCase()}
      cancel={{
        onClick: cancelCandle,
        variant: 'outlined',
      }}
      formProps={{ onSubmit: handleSubmit(submitCandle) }}
      overlayProps={{ zIndex: theme.zIndexes.modalLeveling(1) }}
      width="400px"
    >
      <Styled.Container>
        <Styled.Heading>Candles</Styled.Heading>
        <Styled.FormPosition>
          <Styled.Text>Candle type</Styled.Text>
          <Controller
            control={control}
            name="candle"
            rules={{
              required: {
                value: true,
                message: 'Choose candle',
              },
            }}
            render={({ field }) => (
              <SelectInput<Candle>
                {...field}
                name="candle"
                placeholder="Type"
                options={candleTypes}
                defaultValue={lastUsedCandleType}
                onChange={(option) => {
                  resetField('rangeScope', { keepError: true });
                  resetField('frame', { keepError: true });
                  setIsDefaultCandleUnitRespected(false);
                  setValue('candle', option);
                  clearErrors('candle');
                }}
                error={errors.candle as FieldError}
              />
            )}
          />
        </Styled.FormPosition>
        {/*The two conditional renders below, must be in separate logical products. Do not change them to ternary oparator!*/}
        {watchCandle?.windowtype !== 'range' && (
          <Styled.FormPosition>
            <Styled.Text>Time frame</Styled.Text>
            <Controller
              control={control}
              name="frame"
              rules={{
                required: {
                  value: true,
                  message: 'Choose time frame',
                },
                validate: () =>
                  !!Object.entries(getValues('frame') || {}).filter(([, value]) => value && !!value.length).length ||
                  'Choose time frame',
              }}
              render={({ field }) => (
                <SelectInput
                  {...field}
                  name="frame"
                  placeholder="Time"
                  defaultValue={frameDefaultValue}
                  onChange={(option) => {
                    setValue('frame.scope', String(option?.scope) ?? '');
                    setValue('frame.unit', option?.unit ?? '');
                  }}
                  options={timeFrames}
                  disabled={!watchCandle && !lastUsedCandleType}
                  error={errors.frame as FieldError}
                />
              )}
            />
            <Tooltip
              id="time-frame-help"
              content="The period of time that each candlestick represents"
              wrapperProps={{ height: '46px', align: 'center' }}
            >
              <Icon icon="bold-help" color={theme.colors.black[3]} />
            </Tooltip>
          </Styled.FormPosition>
        )}
        {watchCandle?.windowtype === 'range' && (
          <Styled.FormPosition>
            <Styled.Text>Range frame</Styled.Text>
            <Controller
              control={control}
              name="rangeScope"
              rules={{
                required: {
                  value: true,
                  message: 'Choose range frame',
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  onKeyDown={(e) => ['-', '+', 'e'].includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue('rangeScope', e.target.value);
                    setValue('frame.unit', { label: 'pip', value: 'pip', symbol: 'p' });
                  }}
                  type="number"
                  disabled={!watchCandle && !lastUsedCandleType}
                  min={0}
                  error={errors.rangeScope}
                />
              )}
            />
            <Tooltip
              id="time-range-help"
              content="As unit of candle range we use smallest value of current strategy asset e.g. cent/pence"
              wrapperProps={{ height: '46px', align: 'center' }}
            >
              <Icon icon="bold-help" color={theme.colors.black[3]} />
            </Tooltip>
          </Styled.FormPosition>
        )}
      </Styled.Container>
    </Modal>
  );
};

const Styled = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.elevation[4]};
  `,

  Heading: styled.h6`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.colors.white[1]};
  `,

  Text: styled.p`
    height: 46px;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
  `,

  FormPosition: styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: flex-start;

    & > * {
      width: 35%;

      &:nth-child(3) {
        width: fit-content;
      }
    }
  `,
};
