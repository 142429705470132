import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { FieldPath, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ProgressBar, Step } from 'react-step-progress-bar';
import styled, { css } from 'styled-components/macro';

import { theme } from 'styles';
import { AssetType } from 'types';
import { Flex, Icon, Modal, Text } from 'common/components';
import { sendAuthorizedApiRequest } from 'common/utils';
import { useAuth } from 'modules/auth/hooks';
import { useGetReports } from 'common/hooks';
import { usePrepareStrategyForBacktest } from 'modules/strategies/hooks';
import { useGetDataSources } from 'modules/creator/hooks';
import { useStrategiesContext } from 'modules/strategies/context';
import { useOnboardingContext } from 'modules/onboarding/context';

import {
  StartBacktestCapitalForm,
  StartBacktestCommissionForm,
  StartBacktestRiskForm,
  StartBacktestSummary,
  StartBacktestTimeFrameForm,
} from './components';
import { BacktestForm, BacktestValues } from './types';

type StartBacktestModalProps = {
  setIsModalOpen: (isOpen: boolean) => void;
  strategyName: string;
  id: string;
  asset: AssetType;
};

type FormStepAttrs = {
  name: string;
  title: ReactNode;
  Component: FunctionComponent<any>;
  helpContent?: string;
  fields?: FieldPath<BacktestForm>[];
};

const getFormStepsAttrs = (strategyName: string): FormStepAttrs[] => [
  {
    name: 'Capital',
    title: (
      <span>
        On <span style={{ fontWeight: 600 }}>what capital</span> do you want to backtest for &quot;{strategyName}&quot;?
      </span>
    ),
    Component: StartBacktestCapitalForm,
    fields: ['capital'],
    helpContent: 'The value of the input amount for backtesting. You don`t need to have that many in your account.',
  },
  {
    name: 'Risk management',
    title: (
      <span>
        <span style={{ fontWeight: 600 }}>What risk management</span> do you want for backtest for &quot;{strategyName}
        &quot;?
      </span>
    ),
    Component: StartBacktestRiskForm,
    fields: ['riskType', 'percentageRiskType', 'riskValue'],
    helpContent: '',
  },
  {
    name: 'Fees',
    title: (
      <span>
        How are <span style={{ fontWeight: 600 }}>trading fees</span> calculated for &quot;{strategyName}&quot;?
      </span>
    ),
    Component: StartBacktestCommissionForm,
    fields: ['commission'],
    helpContent: 'Per trade applies to each side of the deal.',
  },
  {
    name: 'Time frame',
    title: (
      <span>
        On <span style={{ fontWeight: 600 }}>what time frame</span> do you want to backtest for &quot;{strategyName}
        &quot;?
      </span>
    ),
    Component: StartBacktestTimeFrameForm,
    fields: ['date'],
    helpContent: 'The range of dates on which the backtest will be run.',
  },
  {
    name: 'Summary',
    title: <span>Backtest for &quot;{strategyName}&quot;</span>,
    Component: StartBacktestSummary,
  },
];

export const StartBacktestModal = ({ setIsModalOpen, strategyName, id, asset }: StartBacktestModalProps) => {
  const [step, setStep] = useState(0);
  const [allowedDateRange, setAllowedDateRange] = useState<(Date | null)[]>([null, null]);
  const [currentComponentProps, setCurrentComponentProps] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { uid } = useAuth();
  const methods = useForm<BacktestForm>();
  const formStepsAttrs = getFormStepsAttrs(strategyName);
  const { handleGetPendingReports } = useGetReports();
  const strategyData = usePrepareStrategyForBacktest(id);
  const { dataSources } = useGetDataSources();
  const { setOptimisticallyInProgress } = useStrategiesContext();
  const { helpers } = useOnboardingContext();
  const { title, Component, helpContent, fields } = formStepsAttrs[step];

  useEffect(() => {
    if (dataSources && asset) {
      for (const { exchanges } of Object.values(dataSources)) {
        const found = exchanges.find(({ dsid }) => dsid.toString() === asset.dsid);

        if (found) {
          setAllowedDateRange(found.range.map((d) => new Date(Number(d) * 1000)));
        }
      }
    }
  }, [dataSources, asset]);

  const getCircleType = (accomplished: boolean, index: number) => {
    if (index === step) {
      return 'current';
    }

    return accomplished ? 'accomplished' : 'subsequent';
  };

  const getNextStep = async () => {
    if (step === formStepsAttrs.length - 1) {
      return;
    }

    await methods.trigger(fields);

    if (fields?.filter((field) => methods.getFieldState(field).error).length === 0) {
      if (step === 2) {
        setCurrentComponentProps({ allowedDateRange });
      } else if (step === 0 || step === 3) {
        setCurrentComponentProps({ symbolKey: asset.symbolKey });
      }
      setStep((prev) => ++prev);
      helpers?.next();
    }
  };

  const getBackendRiskType = (riskType: string, percentageRiskType?: string) => {
    switch (riskType) {
      case 'Percentage':
        return percentageRiskType === 'fixed capital' ? '3' : '4';
      case 'Amount':
        return '2';
      case 'Size':
        return '1';
      default:
        return '1';
    }
  };

  const submitBacktest: SubmitHandler<BacktestForm> = async (values) => {
    getNextStep();

    if (step !== formStepsAttrs.length - 1) {
      return;
    }
    setIsLoading(true);

    const [datefrom, dateto] = values.date.map((d) => Intl.DateTimeFormat('sv-SE').format(d));
    const currentNumberTimestamp = Math.floor(Date.now() / 1000);
    const currentTimestamp = String(currentNumberTimestamp);
    const testId = `${uid}-${id}-${currentTimestamp}`;

    const data: BacktestValues = {
      strategy: strategyData,
      testrequest: {
        testid: testId,
        uid,
        market: asset.market,
        exchange: asset.exchange,
        symbol: asset.symbol,
        capital: values.capital,
        datefrom,
        dateto,
        risktype: getBackendRiskType(values.riskType, values.percentageRiskType),
        riskvalue: values.riskValue,
        timestamp: currentTimestamp,
        commission: values?.commission ? values.commission.toString() : undefined,
      },
    };
    if (setOptimisticallyInProgress) {
      setOptimisticallyInProgress({ timestamp: currentNumberTimestamp, testid: testId });
    }
    await sendAuthorizedApiRequest({
      url: `${process.env.REACT_APP_API_V1}/backtest`,
      method: 'POST',
      data,
    });
    await handleGetPendingReports();
    setIsLoading(false);
    methods.reset();
    setIsModalOpen(false);
  };

  const goBack = () => {
    setStep((prev) => --prev);
  };

  return (
    <FormProvider {...methods}>
      <Modal
        heading="Start backtest"
        close={() => setIsModalOpen(false)}
        cancel={{
          content: 'Skip',
          variant: 'outlined',
          visibility: step !== 2 ? 'hidden' : 'visible',
          className: 'onboarding-disabled',
          onClick: getNextStep,
        }}
        submit={{
          loading: isLoading,
          content: step === formStepsAttrs.length - 1 ? 'Start backtest' : 'Next',
          className: 'onboarding-start-backtest-button',
        }}
        leftCancel={{
          content: 'Back',
          onClick: goBack,
          variant: 'outlined',
          className: 'onboarding-disabled',
          visibility: step === 0 ? 'hidden' : 'visible',
        }}
        formProps={{
          onSubmit: methods.handleSubmit(submitBacktest),
          onKeyDown: (e) => e.key === 'Enter' && e.preventDefault(),
          noValidate: true,
        }}
        width="50%"
        height="70%"
        buttonsAlign="space-between"
        wrapperProps={{ className: 'onboarding-start-backtest-container' }}
      >
        <Flex direction="column" align="flex-start" justify="space-between" height="100%">
          <Flex direction="column" align="flex-start" width="100%">
            <Styled.ProgressBarWrapper>
              <ProgressBar
                filledBackground={theme.buttons.primary.default}
                unfilledBackground={theme.colors.white[1]}
                percent={step * 25}
                height={1}
              >
                {formStepsAttrs.map(({ name }) => (
                  <Step key={name}>
                    {({ accomplished, index }) => (
                      <Styled.Circle type={getCircleType(accomplished, index)} name={name}>
                        {getCircleType(accomplished, index) === 'accomplished' && <Icon icon="tick" />}
                      </Styled.Circle>
                    )}
                  </Step>
                ))}
              </ProgressBar>
            </Styled.ProgressBarWrapper>
            <Styled.Title>{title}</Styled.Title>
            <Component {...currentComponentProps} />
          </Flex>
          {helpContent && (
            <Flex gap={12}>
              <Icon icon="help" />
              <Text size="lg" color={theme.colors.white[1]}>
                {helpContent}
              </Text>
            </Flex>
          )}
        </Flex>
      </Modal>
    </FormProvider>
  );
};

const Styled = {
  ProgressBarWrapper: styled.div`
    width: 100%;
    padding: 16px 48px 48px 48px;
    align-self: center;
  `,

  Circle: styled.div<{ name?: string; type?: 'accomplished' | 'current' | 'subsequent' }>`
    width: 20px;
    height: 20px;
    border: ${({ type, theme }) =>
      `1px solid ${type !== 'subsequent' ? theme.buttons.primary.default : theme.colors.white[1]}`};
    background-color: ${({ type, theme }) =>
      type === 'accomplished' ? theme.buttons.primary.default : theme.colors.black[1]};
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '${({ name }) => name ?? ''}';
      color: ${({ theme }) => theme.colors.white[1]};
      position: absolute;
      top: 30px;
      left: 50%;
      z-index: 1000;
      white-space: nowrap;
      transform: translateX(-50%);
    }

    ${({ type }) =>
      type === 'current' &&
      css`
        &::before {
          content: '';
          width: 4px;
          height: 4px;
          background-color: ${({ theme }) => theme.buttons.primary.default};
          border: none;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 400;
  `,
};
