import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { routes } from 'routes';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useStrategy } from 'modules/creator/hooks';
import { Flex, Input, Modal } from 'common/components';
import { TextArea } from 'common/components/TextArea';
import { AssetType } from 'types';
import { useDocument, useWriteDocument } from 'common/hooks';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';

type SummaryStrategyModalProps = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

type SummaryValues = {
  name: string;
  description: string;
};

export const SummaryStrategyModal = ({ setIsModalOpen }: SummaryStrategyModalProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [name] = useStrategy<string>('name');
  const [description] = useStrategy<string>('description');
  const [chosenAsset] = useStrategy<AssetType>('asset');
  const [strategiesNames] = useDocument<Record<string, string>>('indexes/strategiesNames');
  const { writeStrategy } = useStrategyContext();
  const { helpers, onboardingsState } = useOnboardingContext();
  const writeDocument = useWriteDocument();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<SummaryValues>({
    defaultValues: {
      name: name || '',
      description: description || '',
    },
  });

  const watchedName = watch('name');

  useEffect(() => {
    reset({ name, description });
  }, [reset, name, description]);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const saveDraft: SubmitHandler<SummaryValues> = ({ name, description }) => {
    writeStrategy('isCompleted', true);
    writeStrategy('name', name);
    writeDocument(`indexes/strategiesNames/${id}`, name);
    writeStrategy('description', description);
    if (!onboardingsState[OnboardingNames.STRATEGYCREATOR].run) {
      navigate(routes.strategies);
    } else {
      setTimeout(() => helpers?.next(), 200);
    }
  };

  return (
    <Modal
      cancel={{ onClick: onClose, className: 'onboarding-disabled' }}
      submit={{ className: 'onboarding-checklist-complete-modal-button' }}
      heading="Save your strategy"
      formProps={{ onSubmit: handleSubmit(saveDraft) }}
      width="40vw"
      wrapperProps={{ className: 'onboarding-checklist-complete-modal' }}
    >
      <Styled.Wrapper>
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: 'You must set a name',
            },
            validate: () =>
              !Object.entries(strategiesNames ?? [])
                .filter(([key]) => key !== id)
                .some(([, strategyName]) => strategyName === watchedName) || 'You already have strategy with that name',
          }}
          render={({ field }) => (
            <Input label="Strategy name" type="text" autoComplete="off" {...field} error={errors.name} width="MAX" />
          )}
        />
        <Controller
          control={control}
          name="description"
          rules={{
            required: {
              value: true,
              message: 'You must set a description',
            },
          }}
          render={({ field }) => (
            <TextArea label="Strategy description" autoComplete="off" {...field} error={errors.description} />
          )}
        />
        <Flex direction="column" align="flex-start" gap={10}>
          <Styled.Label>Market</Styled.Label>
          <Styled.AssetWrapper>
            <span>{chosenAsset?.symbol}</span>
            <span>{chosenAsset?.exchange}</span>
          </Styled.AssetWrapper>
        </Flex>
      </Styled.Wrapper>
    </Modal>
  );
};

const Styled = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    gap: 16px;
  `,
  Label: styled.label`
    color: ${({ theme }) => theme.colors.white[1]};
    font-size: 14px;
  `,
  AssetWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
};
