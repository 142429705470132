import { theme } from 'styles';

export const getInputLabelColor = (isError: boolean, variant?: 'default' | 'horizontal') => {
  if (isError) {
    return theme.colors.red[2];
  }
  if (variant !== 'horizontal') {
    return theme.colors.white[1];
  }
  return 'inherit';
};
