import styled from 'styled-components/macro';

import { theme } from 'styles';
import { Modal } from 'common/components';
import { TextEngineItemTypes } from 'modules/creator/types';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { HintableItem, Sentence, Word } from './types';
import { isSentenceFinishedAndValid } from './cotracts';
import { TextEngineMessage } from './components/TextEngineMessage';
import {
  CandleSetterModal,
  ConditionSetterHints,
  ConditionSetterTextInput,
  IndicatorSetterModal,
  TextEngineList,
} from './components';
import { useConditionSetterContext } from './context';

type ConditionSetterModalProps = {
  submitFn: (sentence: Word[], workSentence: Sentence | null) => void;
  closeFn: () => void;
};

export type UpdateSentenceAndHintsArgs = {
  hint?: HintableItem;
  deleteItem?: boolean;
};

export const ConditionSetterModal = ({ submitFn, closeFn }: ConditionSetterModalProps) => {
  const { resultSentence, sentence, displayedModalInfo, typingError, prepareMessage } = useConditionSetterContext();
  const { orderAction } = useOrderActionSectionContext();

  return (
    <Modal
      heading="Set condition"
      submit={{
        onClick: () => submitFn(resultSentence, sentence),
        disabled: !isSentenceFinishedAndValid(sentence, orderAction),
        className: 'onboarding-strategy-condition-save',
      }}
      cancel={{
        onClick: closeFn,
        className: 'onboarding-disabled',
      }}
      overlayProps={{ zIndex: theme.zIndexes.modalOverlay }}
      width="60%"
      wrapperProps={{ className: 'onboarding-strategy-text-box' }}
    >
      {displayedModalInfo && (
        <>
          {displayedModalInfo === TextEngineItemTypes.Indicator && <IndicatorSetterModal />}
          {displayedModalInfo === TextEngineItemTypes.CandleVar && <CandleSetterModal />}
        </>
      )}
      <TextEngineMessage {...prepareMessage()} />
      <Styled.MainSection typingError={typingError}>
        <ConditionSetterTextInput />
        <ConditionSetterHints />
      </Styled.MainSection>
      <TextEngineList />
    </Modal>
  );
};

const Styled = {
  MainSection: styled.div<{ typingError?: boolean }>`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: ${({ typingError }) => (typingError ? '5px' : '16px')};
  `,
};
