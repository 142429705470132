import { TextEngineItemTypes } from 'modules/creator/types';
import { SentenceItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

import { isHintVolumeCandle } from './isHintVolumeCandle';
import { isAssignedCandlePhrase } from './isAssignedCandlePhrase';
import { isHintVolumeInidcator } from './isHintVolumeIndicator';

export const isVolumeEntity = (hint: SentenceItem) =>
  isHintVolumeCandle(hint) ||
  (hint.type === TextEngineItemTypes.Subsentence &&
    isAssignedCandlePhrase(hint.item.assignedPhrase) &&
    hint.item.items.length > 1 &&
    (isHintVolumeInidcator(hint.item.items[1]) || isHintVolumeCandle(hint.item.items[1])));
