import { NotificationData } from 'modules/notifications/types';

export enum ToastActionKind {
  ADD_TOAST = 'ADD_TOAST',
  DELETE_TOAST = 'DELETE_TOAST',
}

type ToastAddAction = {
  type: ToastActionKind.ADD_TOAST;
  payload: NotificationData;
};

type ToastDeleteAction = {
  type: ToastActionKind.DELETE_TOAST;
  payload: string;
};

type ToastAction = ToastAddAction | ToastDeleteAction;

type ToastState = {
  toasts: NotificationData[];
};

export const toastReducer = (state: ToastState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionKind.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case ToastActionKind.DELETE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.nid !== action.payload),
      };
  }
};
