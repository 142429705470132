import { useFormContext } from 'react-hook-form';

import { theme } from 'styles';
import { Flex, Heading, Text } from 'common/components';
import { BacktestForm } from 'modules/strategies/components/StartBacktestModal/types';

type StartBacktestSummaryProps = {
  symbolKey: string;
};

export const StartBacktestSummary = ({ symbolKey }: StartBacktestSummaryProps) => {
  const { watch } = useFormContext<BacktestForm>();

  const capital = watch('capital');
  const date = watch('date');
  const riskType = watch('riskType');
  const percentageRiskType = watch('percentageRiskType');
  const riskValue = watch('riskValue');
  const commission = watch('commission');

  const getProperRiskUnit = () => {
    switch (riskType) {
      case 'Percentage':
        return `% of ${percentageRiskType}`;
      case 'Amount':
        return 'USD';
      case 'Size':
        return symbolKey.split('-')[0];
    }
  };

  return (
    <Flex direction="column" gap={16} align="flex-start">
      <Flex direction="column" gap={4} align="flex-start">
        <Heading level="h3" color={theme.colors.white[1]}>
          Initial Capital:
        </Heading>
        <Text size="lg">{capital} USD</Text>
      </Flex>
      <Flex direction="column" gap={4} align="flex-start">
        <Heading level="h3" color={theme.colors.white[1]}>
          Risk management
        </Heading>
        <Text size="lg">
          {riskValue} {getProperRiskUnit()}
        </Text>
      </Flex>
      <Flex direction="column" gap={4} align="flex-start">
        <Heading level="h3" color={theme.colors.white[1]}>
          Commissions:
        </Heading>
        <Text size="lg">{commission ?? 'Skipped'}</Text>
      </Flex>
      <Flex direction="column" gap={4} align="flex-start">
        <Heading level="h3" color={theme.colors.white[1]}>
          Time frame for test:
        </Heading>
        <Text size="lg">{date.map((d) => d.toLocaleDateString('en-GB').replace(/\//g, '-')).join(' - ')}</Text>
      </Flex>
    </Flex>
  );
};
