import { HTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { NavBar, NavSidebar } from 'common/components';

interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  snapToRight?: boolean;
  noSidebar?: boolean;
  alignContent?: 'flex-start' | 'center' | 'normal' | 'flex-end';
}

export const Layout = ({ children, noSidebar, snapToRight, alignContent, ...rest }: PropsWithChildren<LayoutProps>) => (
  <Styled.Wrapper {...rest}>
    <NavBar />
    {!noSidebar && <NavSidebar />}
    <Styled.Content snapToRight={snapToRight} noSidebar={noSidebar} alignContent={alignContent}>
      {children}
    </Styled.Content>
  </Styled.Wrapper>
);

const Styled = {
  Wrapper: styled.div`
    min-height: 100vh;
    padding-top: 88px;
  `,

  Content: styled.div<Pick<LayoutProps, 'snapToRight' | 'noSidebar' | 'alignContent'>>`
    display: flex;
    flex-direction: column;
    justify-content: ${({ alignContent = 'normal' }) => alignContent};
    min-height: calc(100vh - 88px);
    padding: 16px ${({ snapToRight }) => (snapToRight ? 0 : '24px')} 16px
      ${({ noSidebar }) => (noSidebar ? '32px' : '292px')};
  `,
};
