import axios, { AxiosError } from 'axios';

import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from 'modules/auth/utils';

type GoogleApiResponse = {
  expires_in: number;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: string;
};

type SendAuthorizedApiRequestArgs = {
  url: string;
  data?: unknown;
  method?: string;
  headers?: Record<string, string>;
};

export const sendAuthorizedApiRequest = async (
  { url, data = null, method = 'GET', headers = {} }: SendAuthorizedApiRequestArgs,
  retry?: boolean,
) => {
  const token = getAccessToken();

  try {
    const response = await axios({
      method,
      url,
      headers: { Authorization: `Bearer ${token}`, ...headers },
      data,
    });
    if (response?.data) {
      return response.data;
    }
  } catch (e) {
    if (retry) {
      return new Error('Unsuccessful retry');
    }
    const refreshToken = getRefreshToken();
    try {
      const response = await axios<GoogleApiResponse>({
        url: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_KEY}`,
        method: 'POST',
        data: { grant_type: 'refresh_token', refresh_token: refreshToken },
      });
      if (response.data) {
        setAccessToken(response.data.id_token);
        setRefreshToken(response.data.refresh_token);
      }
      await sendAuthorizedApiRequest({ url, data, method, headers }, true);
    } catch (err) {
      return new Error('Unsuccessful retry');
    }
  }
};
