import styled from 'styled-components/macro';

import { StrategyBar } from 'modules/creator/components/StrategyBar';
import { TradingType } from 'types/StrategyTypes';

import { PositionSection, SymbolBar } from './components';

export const StrategyInterface = () => (
  <Styled.Container>
    <StrategyBar />
    <SymbolBar />
    {Object.values(TradingType).map((type) => (
      <PositionSection tradingType={type} key={type} />
    ))}
  </Styled.Container>
);

const Styled = {
  Container: styled.div`
    border: 2px solid ${({ theme }) => theme.colors.elevation[4]};
    border-radius: 8px;
    overflow: hidden;
  `,
};
