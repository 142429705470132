import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  StrategyView,
  DashboardView,
  StrategiesView,
  NotificationsView,
  StrategyDetailsView,
  CheckoutErrorView,
  CheckoutSuccessView,
} from 'modules';

import { routes } from './routes';

export const AuthenticatedRootRouter: FC = () => (
  <Routes>
    <Route path={routes.dashboard} element={<DashboardView />} />
    <Route path={`${routes.create}/:id`} element={<StrategyView />} />
    <Route path={routes.strategies}>
      <Route index element={<StrategiesView />} />
      <Route path=":id" element={<StrategyDetailsView />} />
    </Route>
    <Route path={routes.notifications} element={<NotificationsView />} />
    <Route path={routes.checkoutSuccess} element={<CheckoutSuccessView />} />
    <Route path={routes.checkoutError} element={<CheckoutErrorView />} />
    <Route path="*" element={<Navigate to={routes.dashboard} />} />
  </Routes>
);
