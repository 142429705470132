import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Input, Text } from 'common/components';
import { BacktestForm } from 'modules/strategies/components/StartBacktestModal/types';

export const StartBacktestCommissionForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BacktestForm>();
  return (
    <Flex gap={8} align="flex-end">
      <Controller
        control={control}
        name="commission"
        rules={{
          min: {
            value: 0,
            message: 'Commission must be positive',
          },
        }}
        render={({ field }) => (
          <Input
            type="number"
            label="commission per position"
            {...field}
            error={errors.commission}
            postInputContent={<Text size="lg">USD</Text>}
            step="0.01"
            min={0}
          />
        )}
      />
    </Flex>
  );
};
