import {
  SentenceItem,
  TypableVolumeCandleVar,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const isHintVolumeCandle = (hint: SentenceItem): hint is TypableVolumeCandleVar => {
  const { type, item } = hint;
  return type === TextEngineItemTypes.CandleVar && item.result_type === 'volume';
};
