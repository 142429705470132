import styled, { keyframes } from 'styled-components/macro';

export const ProgressBar = () => (
  <Styled.Wrapper>
    <Styled.Bar>
      <Styled.BarInner />
    </Styled.Bar>
  </Styled.Wrapper>
);

const progress = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 24px 0;
  }
`;

const Styled = {
  Wrapper: styled.div`
    padding: 8px 0;
  `,

  Bar: styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 8px;
    border-radius: 4px;
  `,

  BarInner: styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: -24px;
    right: -24px;
    background: ${({ theme }) =>
      `repeating-linear-gradient(-25deg, #CB96CC, #CB96CC 5px, ${theme.buttons.primary.default} 5px, ${theme.buttons.primary.default} 10px)`};
    animation: ${progress} 1s linear infinite;
  `,
};
