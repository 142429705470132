import {
  TypablePhrase,
  TypableValuePhrase,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

export const isAssignedCandlePhrase = (phrase?: TypableValuePhrase | TypablePhrase) => {
  if (!phrase) {
    return false;
  }

  return phrase.item.key === 'current' || phrase.item.key === 'previous';
};
