import { useCallback, useEffect, useState } from 'react';

import { getReport } from 'common/utils';
import { ReportedStrategy } from 'modules/strategies/types';

export const useGetReport = (testid?: string) => {
  const [report, setReport] = useState<ReportedStrategy | null>();

  const handleGetReport = useCallback(async () => {
    if (!testid) {
      return setReport(null);
    }
    const data = await getReport(testid);

    setReport(data);
  }, [testid]);

  useEffect(() => {
    handleGetReport();
  }, [handleGetReport]);

  return {
    report,
  };
};
