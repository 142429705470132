import {
  SentenceItem,
  TypableAbsoluteIndicator,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const isHintAbsoluteIndicator = (hint: SentenceItem): hint is TypableAbsoluteIndicator => {
  const { type, item } = hint;
  return type === TextEngineItemTypes.Indicator && item.result_type === 'absolute';
};
