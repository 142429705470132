import {
  isHintAbsoluteCandle,
  isHintAbsoluteIndicator,
  isHintAnyPhrase,
  isHintAnyVariable,
  isHintCandle,
  isHintIndicator,
  isHintPhrase,
  isHintRelativeIndicator,
  isHintTableVariable,
  isHintValue,
  isHintValuePhrase,
  isHintVariable,
  isHintVolumeCandle,
  isHintVolumeInidcator,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import { HintableItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

export const splitHintsByType = (allHints: HintableItem[]) => {
  const allPhrases = allHints.filter(isHintPhrase);
  const allValuePhrases = allHints.filter(isHintValuePhrase);
  const allIndicators = allHints.filter(isHintIndicator);
  const allVariables = allHints.filter(isHintVariable);
  const allCandleVariables = allHints.filter(isHintCandle);
  const allValues = allHints.filter(isHintValue);
  const allAnyVariables = allHints.filter(isHintAnyVariable);
  const allAnyPhrases = allHints.filter(isHintAnyPhrase);
  const allVolumeCandles = allHints.filter(isHintVolumeCandle);
  const allAbsoluteCandles = allHints.filter(isHintAbsoluteCandle);
  const allVolumeIndicators = allHints.filter(isHintVolumeInidcator);
  const allAbsoluteIndicators = allHints.filter(isHintAbsoluteIndicator);
  const allRelativeIndicators = allHints.filter(isHintRelativeIndicator);
  const allTableVariables = allHints.filter(isHintTableVariable);

  return {
    allPhrases,
    allValuePhrases,
    allIndicators,
    allVariables,
    allCandleVariables,
    allValues,
    allAnyPhrases,
    allAnyVariables,
    allAbsoluteCandles,
    allVolumeCandles,
    allVolumeIndicators,
    allAbsoluteIndicators,
    allRelativeIndicators,
    allTableVariables,
  };
};
