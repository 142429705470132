import { isHintAnyPhrase } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import {
  HintableItem,
  TypableValuePhrase,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

export const filterHintsForValueSentenceStart = (allHints: HintableItem[], allValuePhrases: TypableValuePhrase[]) => {
  const hintsWithoutPhrases = allHints.filter((hint) => !isHintAnyPhrase(hint));
  const allowedTypes = allValuePhrases.map(({ item }) => item.structure[0]).flat();

  return hintsWithoutPhrases.filter((hint) => allowedTypes.some((type) => type === hint.type));
};
