import styled from 'styled-components/macro';

import { theme } from 'styles';
import { AssetType } from 'types';
import { Box, Divider, Flex, Heading, Text } from 'common/components';
import { useStrategy } from 'modules/creator/hooks';
import { ReportedStrategy } from 'modules/strategies/types';
import { ReportChart } from 'modules/strategies/components';
import { useGetReportColumns } from 'modules/strategies/hooks';

type ValueProps = {
  color?: string;
};

export const BacktesterReport = ({
  testid,
  report,
  commission,
  strategyname,
  summary_pic,
  datefrom,
  dateto,
  riskvalue,
  risktype,
  symbol,
}: ReportedStrategy) => {
  const { columns, maxtime } = useGetReportColumns(testid);
  const [asset] = useStrategy<AssetType>('asset');

  const handleGetColor = (value: number, name: string) => {
    if (name.includes('$') || name.includes('Largest') || name === 'Total P/L' || name === 'Return') {
      if (value > 0) {
        return theme.colors.green[0];
      } else if (value < 0) {
        return theme.colors.red[2];
      }
      return theme.colors.white[0];
    }
    return theme.colors.white[0];
  };

  const handlAddUnit = (value: number, name: string) => {
    if (name.includes('%') || name === 'Return') {
      return `${value}%`;
    }
    if (name.includes('$') || name.includes('Largest') || name.includes('capital') || name === 'Total P/L') {
      return value < 0 ? `-$${value * -1}` : `$${value}`;
    }
    return value;
  };

  const handleGetRiskUnit = () => {
    switch (risktype) {
      case '4':
        return '% of total capital';
      case '3':
        return '% of fixed capital';
      case '2':
        return ' USD';
      case '1':
      default:
        return ` ${symbol}`;
    }
  };

  return (
    <Flex direction="column" align="stretch" gap={16}>
      <Box>
        <Heading level="h1">Backtest returns</Heading>
        <Divider />
        <Flex align="flex-end" gap={16}>
          {report?.summary.map((item) => (
            <div key={item.name}>
              <Flex direction="column" align="flex-start" gap={16}>
                <Styled.Label>{item.name}</Styled.Label>
                <Styled.Value color={handleGetColor(item.value, item.name)}>
                  {handlAddUnit(item.value, item.name)}
                </Styled.Value>
              </Flex>
            </div>
          ))}
          {report?.details.map((item) => {
            if (item.name === 'Reward/Risk' || item.name === 'Win ratio %') {
              return (
                <Flex key={item.name} direction="column" align="flex-start" gap={16}>
                  <Styled.Label>{item.name}</Styled.Label>
                  <Styled.Value color={handleGetColor(item.all_trades, item.name)}>
                    {handlAddUnit(item.all_trades, item.name)}
                  </Styled.Value>
                </Flex>
              );
            }
          })}
        </Flex>
      </Box>
      <Flex align="stretch" gap={16}>
        {summary_pic && (
          <Box width="60%">
            <Heading level="h1"> Capital chart</Heading>
            <Divider />
            <img src={`data:image/jpeg;base64,${summary_pic}`} alt="raport chart" width="100%" />
          </Box>
        )}
        <Box width="40%">
          <Heading level="h1">Backtest details</Heading>
          <Divider />
          <Flex direction="column" align="flex-start" gap={12}>
            <Styled.Label>Time frame for test</Styled.Label>
            <Text size="lg">
              {datefrom} - {dateto}
            </Text>
          </Flex>
          <Flex direction="column" align="flex-start" gap={12}>
            <Styled.Label>Risk managment</Styled.Label>
            <Text size="lg">
              {riskvalue}
              {handleGetRiskUnit()}
            </Text>
          </Flex>
          <Flex direction="column" align="flex-start" gap={12}>
            <Styled.Label>Commissions</Styled.Label>
            <Text size="lg">{commission || 'skipped'}</Text>
          </Flex>
        </Box>
      </Flex>
      <Box>
        <Heading level="h1">{strategyname}</Heading>
        <Divider />
        <Text size="sm" color={theme.colors.white[1]}>
          Time frame for test
        </Text>
        <Styled.Papper>
          <Styled.Row>
            <Styled.FirstCell />
            <Styled.Cell>
              <Text size="sm" color={theme.colors.white[1]}>
                All trades
              </Text>
            </Styled.Cell>
            <Styled.Cell>
              <Text size="sm" color={theme.colors.white[1]}>
                Long trades
              </Text>
            </Styled.Cell>
            <Styled.Cell>
              <Text size="sm" color={theme.colors.white[1]}>
                Short trades
              </Text>
            </Styled.Cell>
          </Styled.Row>
          {report?.details.map((item, index) => (
            <Styled.Row key={item.name}>
              <Styled.FirstCell>
                <Text size="sm" color={theme.colors.white[1]}>
                  {item.name}
                </Text>
              </Styled.FirstCell>
              <Styled.Cell>
                <Styled.Value color={handleGetColor(item.all_trades, item.name)}>
                  {handlAddUnit(item.all_trades, item.name)}
                </Styled.Value>
              </Styled.Cell>
              <Styled.Cell>
                <Styled.Value color={handleGetColor(item.long_trades, item.name)}>
                  {handlAddUnit(item.long_trades, item.name)}
                </Styled.Value>
              </Styled.Cell>
              <Styled.Cell>
                <Styled.Value color={handleGetColor(item.short_trades, item.name)}>
                  {handlAddUnit(item.short_trades, item.name)}
                </Styled.Value>
              </Styled.Cell>
            </Styled.Row>
          ))}
        </Styled.Papper>
        {testid && columns && maxtime && asset && (
          <ReportChart id={testid} columns={columns} maxtime={maxtime} asset={asset} />
        )}
      </Box>
    </Flex>
  );
};

const Styled = {
  Papper: styled.div`
    padding: 12px 24px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.elevation[2]};
  `,

  Image: styled.img`
    width: 180px;
  `,

  Label: styled.span`
    color: ${({ theme }) => theme.colors.white[1]};
    font-size: 14px;
    white-space: nowrap;
  `,

  Value: styled.span<ValueProps>`
    font-size: 22px;
    font-weight: 400;
    color: ${({ theme, color }) => color ?? theme.colors.white[0]};
  `,

  Row: styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
    max-width: 840px;
  `,

  FirstCell: styled.div`
    flex: 0.7;
    padding: 10px;
  `,

  Cell: styled.div`
    flex: 1;
    padding: 10px;
    text-align: right;
  `,
};
