import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { routes } from 'routes';
import { theme } from 'styles';
import { Button, Flex, Heading, Input, TextLink, Text, Icon } from 'common/components';
import { ConfirmPasswordValues } from 'modules/auth/types';
import { useAuth } from 'modules/auth/hooks';

export const ConfirmPasswordForm: FC = () => {
  const location = useLocation();
  const oobCode = new URLSearchParams(location.search).get('oobCode');
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<ConfirmPasswordValues>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const { confirmPassword, error } = useAuth();
  const isPasswordMinimumLength = watch('newPassword').length >= 8;
  const hasSmallAndCapital = /[A-Z]/.test(watch('newPassword')) && /[[a-z]/.test(watch('newPassword'));
  const hasSpecial = /[^A-Za-z 0-9]/g.test(watch('newPassword'));
  const hasDigit = /\d/.test(watch('newPassword'));

  const handleConfirmPassword: SubmitHandler<ConfirmPasswordValues> = (data) => {
    confirmPassword({ newPassword: data.newPassword, oobCode: oobCode || '' });
    reset();
  };

  return (
    <Flex direction="column" align="normal" width="100%" gap={40}>
      <Flex direction="column" align="normal" gap={8}>
        <Heading level="h1">Create new password</Heading>
        <Text size="sm">Make sure to make it strong.</Text>
      </Flex>
      <form onSubmit={handleSubmit(handleConfirmPassword)}>
        <Flex direction="column" align="normal" gap={24}>
          <Controller
            control={control}
            name="newPassword"
            rules={{
              required: 'You must specify a password',
            }}
            render={({ field }) => (
              <Input
                label="New password"
                type="password"
                autoComplete="off"
                {...field}
                error={errors.newPassword}
                width="MAX"
              />
            )}
          />
          <Flex direction="column" align="normal" gap={16}>
            <Flex gap={10}>
              <Styled.Bar isChecked={isPasswordMinimumLength} />
              <Styled.Bar isChecked={hasSmallAndCapital} />
              <Styled.Bar isChecked={hasSpecial} />
              <Styled.Bar isChecked={hasDigit} />
            </Flex>
            <Flex direction="column" align="normal" gap={4}>
              <Flex gap={4}>
                <Icon
                  icon={isPasswordMinimumLength ? 'tick' : 'close'}
                  color={isPasswordMinimumLength ? theme.colors.paleGreen : theme.colors.red[2]}
                  size={18}
                />
                <Text size="sm" color={isPasswordMinimumLength ? theme.colors.paleGreen : theme.colors.red[2]}>
                  minimum 8 characters
                </Text>
              </Flex>
              <Flex gap={4}>
                <Icon
                  icon={hasSmallAndCapital ? 'tick' : 'close'}
                  size={16}
                  color={hasSmallAndCapital ? theme.colors.paleGreen : theme.colors.red[2]}
                />
                <Text size="sm" color={hasSmallAndCapital ? theme.colors.paleGreen : theme.colors.red[2]}>
                  small and capital letters
                </Text>
              </Flex>
              <Flex gap={4}>
                <Icon
                  icon={hasSpecial ? 'tick' : 'close'}
                  size={16}
                  color={hasSpecial ? theme.colors.paleGreen : theme.colors.red[2]}
                />
                <Text size="sm" color={hasSpecial ? theme.colors.paleGreen : theme.colors.red[2]}>
                  minimum 1 special character
                </Text>
              </Flex>
              <Flex gap={4}>
                <Icon
                  icon={hasDigit ? 'tick' : 'close'}
                  size={16}
                  color={hasDigit ? theme.colors.paleGreen : theme.colors.red[2]}
                />
                <Text size="sm" color={hasDigit ? theme.colors.paleGreen : theme.colors.red[2]}>
                  minimum 1 digit
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Controller
            control={control}
            name="confirmNewPassword"
            rules={{
              required: 'You must confirm password',
              validate: (value) => value === watch('newPassword') || 'The passwords do not match',
            }}
            render={({ field }) => (
              <Input
                label="Confirm password"
                type="password"
                autoComplete="off"
                {...field}
                error={errors.confirmNewPassword}
                width="MAX"
              />
            )}
          />
          <Button
            type="submit"
            variant="primary"
            disabled={!isPasswordMinimumLength || !hasDigit || !hasSmallAndCapital || !hasSpecial}
          >
            Continue
          </Button>
          {error && <Styled.Error>{error.message}</Styled.Error>}
          <TextLink to={routes.login}>Go back to log in</TextLink>
        </Flex>
      </form>
    </Flex>
  );
};

const Styled = {
  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,

  Bar: styled.div<{ isChecked: boolean }>`
    height: 8px;
    flex: 1;
    border-radius: 4px;
    background-color: ${({ isChecked }) => (isChecked ? theme.buttons.primary.default : theme.colors.black[2])};
  `,
};
