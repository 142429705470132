import {
  Dispatch,
  KeyboardEventHandler,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useStrategyContext } from 'modules/creator/context';
import { TextEngineItemTypes } from 'modules/creator/types';
import {
  EditedItem,
  HintableItem,
  ModalInfo,
  Sentence,
  Word,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';
import { useFlowItems } from 'modules/creator/components/ConditionsFlow/hooks';
import {
  isHintIndicatorOrCandle,
  isHintValue,
  isSentenceFinishedAndValid,
  isValueCorrect,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import {
  convertToHintableArray,
  getHintableItemNameOrKey,
  getNewSentence,
  getNextHints,
  removeLastSentenceElement,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';
import { UpdateSentenceAndHintsArgs } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal';
import {
  TextEngineMessageType,
  TextEngineMessages,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/components/TextEngineMessage';

type ConditionSetterContextType = {
  displayedModalInfo: ModalInfo;
  setDisplayedModalInfo: Dispatch<SetStateAction<ModalInfo>>;
  editedItem?: EditedItem;
  setEditedItem: Dispatch<SetStateAction<EditedItem | undefined>>;
  allHints: HintableItem[];
  sentence: Sentence | null;
  resultSentence: Word[];
  setResultSentence: Dispatch<SetStateAction<Word[]>>;
  currentHints: HintableItem[];
  filteredHints: HintableItem[];
  conditionText: string;
  setConditionText: Dispatch<SetStateAction<string>>;
  typingError: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  handleHintClick: (hint: HintableItem, alias?: string) => void;
  handleKeyPress: KeyboardEventHandler<HTMLElement>;
  prepareMessage: () => TextEngineMessageType;
  onDelete: () => void;
  updateSentenceAndHints: ({ hint, deleteItem }: UpdateSentenceAndHintsArgs) => void;
};

type ConditionSetterContextProviderProps = {
  nodeId: string;
};

const ConditionSetterContext = createContext<ConditionSetterContextType>({
  displayedModalInfo: null,
  setDisplayedModalInfo: () => {},
  setEditedItem: () => {},
  allHints: [],
  sentence: null,
  resultSentence: [],
  setResultSentence: () => {},
  currentHints: [],
  filteredHints: [],
  conditionText: '',
  setConditionText: () => {},
  typingError: false,
  handleHintClick: () => {},
  handleKeyPress: () => {},
  prepareMessage: () => TextEngineMessages.DEFAULT,
  onDelete: () => {},
  updateSentenceAndHints: () => {},
});

export const ConditionSetterContextProvider = ({
  children,
  nodeId,
}: PropsWithChildren<ConditionSetterContextProviderProps>) => {
  const [displayedModalInfo, setDisplayedModalInfo] = useState<ModalInfo>(null);
  const [editedItem, setEditedItem] = useState<EditedItem>();
  const [hintableItems, setHintableItems] = useState<HintableItem[]>([]);
  const [sentence, setSentence] = useState<Sentence | null>(null);
  const [resultSentence, setResultSentence] = useState<Word[]>([]);
  const [currentHints, setCurrentHints] = useState<HintableItem[]>([]);
  const [filteredHints, setFilteredHints] = useState(currentHints);
  const [conditionText, setConditionText] = useState('');
  const [typingError, setTypingError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { orderAction } = useOrderActionSectionContext();
  const { dictionary } = useStrategyContext();
  const [items] = useFlowItems();
  const numericalRegex = useMemo(() => /^\d+(?:[.,]\d+)?$/, []);

  useEffect(() => {
    if (sentence === null && items && items[nodeId]) {
      const node = items[nodeId];
      if (node.type === 'node' && node.state) {
        setSentence(node.state.workSentence);
        setResultSentence(node.state.resultSentence);
      }
    }
  }, [items, nodeId, sentence]);

  useEffect(() => {
    if (typingError && (filteredHints.length > 0 || conditionText.match(numericalRegex))) {
      setTypingError(false);
    } else if (
      !typingError &&
      filteredHints.length === 0 &&
      !conditionText.match(numericalRegex) &&
      !isSentenceFinishedAndValid(sentence, orderAction)
    ) {
      setTypingError(true);
    }
  }, [typingError, filteredHints, conditionText, orderAction, sentence, numericalRegex]);

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, [resultSentence]);

  useEffect(() => {
    if (dictionary) {
      const hintableArray = convertToHintableArray(dictionary, orderAction);
      if (hintableItems.length === 0) {
        setHintableItems(hintableArray);
        const startHints = getNextHints(hintableArray, sentence, orderAction);
        setCurrentHints(startHints);
      }
    }
  }, [dictionary, setHintableItems, hintableItems, sentence, orderAction]);

  useEffect(() => {
    if (conditionText) {
      setFilteredHints(
        currentHints.filter(
          (hint) =>
            !!hint.item.aliases.filter((alias) => alias.toLowerCase().includes(conditionText.toLowerCase())).length,
        ),
      );
    } else {
      setFilteredHints(currentHints);
    }
  }, [conditionText, currentHints]);

  const updateSentenceAndHints = ({ hint, deleteItem }: UpdateSentenceAndHintsArgs) => {
    let newSentence = sentence;
    if (hint && !deleteItem) {
      newSentence = getNewSentence({ sentence, hint, flowType: orderAction });
    } else {
      newSentence = removeLastSentenceElement(sentence);
    }
    const newHints = getNextHints(hintableItems, newSentence, orderAction);
    setCurrentHints(newHints);
    setSentence(newSentence);
  };

  const handleHintClick = (hint: HintableItem, alias?: string) => {
    setEditedItem({ item: hint.item });
    setConditionText('');
    if (isHintIndicatorOrCandle(hint)) {
      setDisplayedModalInfo(hint.type);
    } else if (hint.type === TextEngineItemTypes.Value) {
      updateSentenceAndHints({ hint });
      setResultSentence((prev) => [
        ...prev,
        {
          stringContent: {
            main: String(alias ?? hint.item.name),
          },
          origin: hint.item,
          type: hint.type,
          items: {},
        },
      ]);
    } else {
      updateSentenceAndHints({ hint });
      setResultSentence((prev) => [
        ...prev,
        {
          stringContent: {
            main: getHintableItemNameOrKey(hint),
          },
          origin: hint.item,
          type: hint.type,
          items: {},
        },
      ]);
    }
  };

  const onDelete = () => {
    const lastResultSentenceItem = resultSentence.at(-1);
    if (lastResultSentenceItem) {
      setConditionText(
        `${lastResultSentenceItem.stringContent.main}${lastResultSentenceItem.stringContent.params ? ' ' : ''}`,
      );
    }
    setResultSentence((prev) => prev.slice(0, -1));
    updateSentenceAndHints({ deleteItem: true });
  };

  const handleKeyPress: KeyboardEventHandler<HTMLElement> = (event) => {
    const { key } = event;
    if (key === 'Enter' || key === ' ') {
      const found = currentHints.find(({ item, type }) =>
        type === TextEngineItemTypes.Own
          ? item.key.toUpperCase() === conditionText.toUpperCase()
          : item.name.toUpperCase() === conditionText.toUpperCase(),
      );
      if (found) {
        if (isHintIndicatorOrCandle(found)) {
          setDisplayedModalInfo(found.type);
        } else {
          updateSentenceAndHints({ hint: found });
          setResultSentence((prev) => [
            ...prev,
            { stringContent: { main: conditionText }, type: found.type, origin: found.item },
          ]);
        }
        setEditedItem({ item: found.item });
        setTypingError(false);
        setConditionText('');
        event.preventDefault();
      } else if (conditionText.match(numericalRegex)) {
        const valueHint = currentHints.find(isHintValue);
        if (!valueHint || !isValueCorrect(Number(conditionText), sentence, resultSentence)) {
          setTypingError(true);
          return;
        }
        setTypingError(false);
        setResultSentence((prev) => [
          ...prev,
          { stringContent: { main: conditionText }, type: TextEngineItemTypes.Value, origin: valueHint.item },
        ]);
        setConditionText('');
        setEditedItem({ item: valueHint.item });
        updateSentenceAndHints({ hint: valueHint });
        event.preventDefault();
      } else {
        setTypingError(true);
      }
    } else if (key === 'Backspace' && conditionText === '' && resultSentence.length > 0) {
      onDelete();
    }
  };

  const prepareMessage = () => {
    if (typingError) {
      return TextEngineMessages.ERROR;
    }

    if (isSentenceFinishedAndValid(sentence, orderAction)) {
      return TextEngineMessages.SUCCESS;
    }

    if (sentence == null || sentence.items.length === 0) {
      return TextEngineMessages.START;
    }

    return TextEngineMessages.DEFAULT;
  };

  return (
    <ConditionSetterContext.Provider
      value={{
        allHints: hintableItems,
        sentence,
        resultSentence,
        setResultSentence,
        typingError,
        conditionText,
        setConditionText,
        handleKeyPress,
        inputRef,
        onDelete,
        displayedModalInfo,
        setDisplayedModalInfo,
        editedItem,
        setEditedItem,
        prepareMessage,
        updateSentenceAndHints,
        filteredHints,
        handleHintClick,
        currentHints,
      }}
    >
      {children}
    </ConditionSetterContext.Provider>
  );
};

export const useConditionSetterContext = () => {
  const value = useContext(ConditionSetterContext);

  return value;
};
