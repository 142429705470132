import styled from 'styled-components/macro';

import { useAuth } from 'modules/auth/hooks';

interface UserDropdownListProps {
  isExpanded: boolean;
}

export const UserDropdownList = ({ isExpanded }: UserDropdownListProps) => {
  const { logout } = useAuth();

  const OptionsList = [
    {
      text: 'Log out',
      onClick: logout,
    },
  ];

  if (!isExpanded) return null;

  return (
    <Styled.List>
      {OptionsList.map(({ text, onClick }) => (
        <li key={text}>
          <Styled.ListItem onClick={onClick}>{text}</Styled.ListItem>
        </li>
      ))}
    </Styled.List>
  );
};

const Styled = {
  List: styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: ${({ theme }) => theme.colors.black[2]};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: absolute;
  `,

  ListItem: styled.button`
    padding: 8px 16px;
    white-space: nowrap;
    border: none;
    color: inherit;
    background-color: inherit;
  `,
};
