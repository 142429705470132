import {
  Sentence,
  SubsentenceItem,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const removeLastSentenceElement = (sentence: Sentence | null) => {
  if (!sentence || sentence.items.length === 0) {
    return sentence;
  }

  const itemIndex = sentence.items.length - 1;
  const subsentence = sentence.items[itemIndex] as SubsentenceItem;

  if (subsentence.type === TextEngineItemTypes.Subsentence && subsentence.item.items.length > 1) {
    const deletedItem = subsentence.item.items.pop();
    if (deletedItem?.type === TextEngineItemTypes.Own) {
      subsentence.item.assignedPhrase = undefined;
    }
  } else {
    const deletedItem = sentence.items.pop();
    if (deletedItem?.type === TextEngineItemTypes.Own) {
      sentence.assignedPhrase = undefined;
    }
  }
  return sentence;
};
