type generateSymbolProps = {
  exchange: string;
  symbol: string;
};

// Generates a symbol ID from a pair of the coins
export const generateSymbol = ({ exchange, symbol }: generateSymbolProps) => {
  const short = symbol.replace('-', '/');
  return {
    symbolShort: short,
    symbolFull: `${exchange}:${short}`,
  };
};

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol: string) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}
