import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import {
  browserSessionPersistence,
  setPersistence,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { FirebaseError } from '@firebase/util';

import { routes } from 'routes';
import { auth, authNotifications, authFirestore } from 'common/services/firebase';
import { useAuthContext } from 'modules/auth/context';
import { LoginValues } from 'modules/auth/types';

type ConfirmPasswordProps = {
  newPassword: string;
  oobCode: string;
};

export const useAuth = () => {
  const navigate = useNavigate();
  const { user, setLoading, error, setError } = useAuthContext();

  const login = useCallback(
    async (data: LoginValues) => {
      setLoading((prev) => ({ ...prev, login: true }));
      const { email, password, remember } = data;

      setError(undefined);

      if (!remember) {
        setPersistence(auth, browserSessionPersistence);
        setPersistence(authNotifications, browserSessionPersistence);
        setPersistence(authFirestore, browserSessionPersistence);
      }

      try {
        await signInWithEmailAndPassword(auth, email, password);
        await signInWithEmailAndPassword(authNotifications, email, password);
        await signInWithEmailAndPassword(authFirestore, email, password);
      } catch (error) {
        if (error instanceof FirebaseError) {
          setLoading((prev) => ({ ...prev, login: false }));
          setError(error);
        }
      } finally {
        setLoading((prev) => ({ ...prev, login: false }));
      }
    },
    [setLoading, setError],
  );

  const logout = async () => {
    await signOut(auth);
    await signOut(authNotifications);
    await signOut(authFirestore);
  };

  const resetPassword = useCallback(
    async (email: string) => {
      setError(undefined);

      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error) {
        if (error instanceof FirebaseError) {
          setError(error);
        }
      }
    },
    [setError],
  );

  const confirmPassword = useCallback(
    async ({ newPassword, oobCode }: ConfirmPasswordProps) => {
      setError(undefined);

      try {
        const verify = await verifyPasswordResetCode(auth, oobCode);

        if (verify) {
          await confirmPasswordReset(auth, oobCode, newPassword);
          navigate(routes.confirmPasswordSuccess);
        }
      } catch (error) {
        if (error instanceof FirebaseError) {
          setError(error);
        }
      }
    },
    [navigate, setError],
  );

  return useMemo(
    () => ({
      uid: user?.uid || '',
      name: user?.email?.split('@')[0] || '',
      error,
      login,
      logout,
      resetPassword,
      confirmPassword,
    }),
    [user, error, confirmPassword, login, resetPassword],
  );
};
