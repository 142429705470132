import { TextEngineItemTypes } from 'modules/creator/types';
import {
  Sentence,
  TypableIndicator,
  Word,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { getHintableItemNameOrKey } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';

import { isHintRelativeIndicator } from './isHintRelativeIndicator';
import { isHintVolumeInidcator } from './isHintVolumeIndicator';
import { isSentenceFinished } from './isSentenceFinished';

export const isValueCorrect = (value: number, sentence: Sentence | null, resultSentence: Word[]) => {
  if (!sentence) return true;

  const isValueInScope = (found: TypableIndicator) => {
    if (found) {
      const foundResult = resultSentence
        .map(({ items }) => items?.indicator)
        .find((indicator) => indicator?.name === getHintableItemNameOrKey(found).toLowerCase());
      const indicatorOutType = foundResult?.outdata ?? '';
      const outItem = found.item.params.outdata.find((outItems) => outItems.name === indicatorOutType);

      if (outItem) {
        const scopeStart = outItem.scope?.start;
        const scopeEnd = outItem.scope?.end;

        const isValueOutOfScope =
          (typeof scopeStart === 'number' && value < scopeStart) || (typeof scopeEnd === 'number' && value > scopeEnd);

        if (isValueOutOfScope) {
          return false;
        }
      }
    }

    return true;
  };

  const index = sentence.items.length - 1;
  if (sentence.items[index].type === TextEngineItemTypes.Subsentence) {
    const subsentence = sentence.items[index].item as Sentence;

    if (!isSentenceFinished(subsentence)) {
      const found = subsentence.items.find(
        (item) => isHintRelativeIndicator(item) || isHintVolumeInidcator(item),
      ) as TypableIndicator;

      return isValueInScope(found);
    }
  }
  const found = sentence.items.find(
    (item) => isHintRelativeIndicator(item) || isHintVolumeInidcator(item),
  ) as TypableIndicator;

  return isValueInScope(found);
};
