import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Input, SelectInput, Text } from 'common/components';
import { BacktestForm } from 'modules/strategies/components/StartBacktestModal/types';

enum RiskType {
  SIZE = 'Size',
  AMOUNT = 'Amount',
  PERCENTAGE = 'Percentage',
}

enum PercentageRiskTypes {
  FIXED_CAPITAL = 'fixed capital',
  TOTAL_CAPITAL = 'total capital',
}

const InputRange = {
  [RiskType.SIZE]: {
    min: 0,
    max: null,
  },
  [RiskType.AMOUNT]: {
    min: 0,
    max: null,
  },
  [RiskType.PERCENTAGE]: {
    min: 0,
    max: 100,
  },
};

export const riskTypes = [
  {
    label: RiskType.SIZE,
    value: RiskType.SIZE,
  },
  {
    label: RiskType.AMOUNT,
    value: RiskType.AMOUNT,
  },
  {
    label: RiskType.PERCENTAGE,
    value: RiskType.PERCENTAGE,
  },
];

export const percentageRiskTypes = [
  {
    label: PercentageRiskTypes.FIXED_CAPITAL,
    value: PercentageRiskTypes.FIXED_CAPITAL,
  },
  {
    label: PercentageRiskTypes.TOTAL_CAPITAL,
    value: PercentageRiskTypes.TOTAL_CAPITAL,
  },
];

type StartBacktestRiskFormProps = {
  symbolKey: string;
};

export const StartBacktestRiskForm = ({ symbolKey }: StartBacktestRiskFormProps) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<BacktestForm>();
  const riskType = watch('riskType');
  const percentageRiskType = watch('percentageRiskType');
  const currentRange = InputRange[(riskType ?? RiskType.SIZE) as RiskType];

  useEffect(() => {
    if (!riskType) {
      setValue('riskType', riskTypes[0].value);
    }
    if (!percentageRiskType) {
      setValue('percentageRiskType', percentageRiskTypes[0].value);
    }
  }, [setValue, riskType, percentageRiskType]);

  return (
    <Flex gap={8} align="flex-start" direction="column">
      <Controller
        control={control}
        name="riskType"
        rules={{
          required: {
            value: true,
            message: 'You must specify risk type',
          },
        }}
        render={({ field }) => (
          <SelectInput
            label="Risk management:"
            labelVariant="horizontal"
            options={riskTypes}
            defaultValue={riskTypes.find(({ value }) => value === riskType)}
            {...field}
            error={errors.riskType}
            onChange={(risk) => setValue('riskType', risk?.value ?? riskTypes[0].value, { shouldValidate: true })}
          />
        )}
      />
      <Flex align="flex-start">
        <Controller
          control={control}
          name="riskValue"
          rules={{
            required: {
              value: true,
              message: 'You must specify risk value',
            },
            min: {
              value: 0,
              message: 'Value must be more than 0',
            },
            max: currentRange.max
              ? {
                  value: currentRange.max,
                  message: `Value must be less than ${currentRange.max}`,
                }
              : undefined,
          }}
          render={({ field }) => (
            <Input
              label="Value:"
              labelVariant="horizontal"
              type="number"
              {...field}
              min={currentRange.min}
              max={currentRange.max ?? undefined}
              error={errors.riskValue}
              postInputContent={
                riskType !== RiskType.PERCENTAGE ? (
                  <Text size="lg">{riskType === RiskType.SIZE ? symbolKey.split('-')[0] : 'USD'}</Text>
                ) : undefined
              }
            />
          )}
        />
        {riskType === 'Percentage' && (
          <>
            <Text size="lg" style={{ padding: '12px' }}>
              % of
            </Text>
            <Controller
              control={control}
              name="percentageRiskType"
              rules={{
                required: {
                  value: true,
                  message: 'You must specify risk type',
                },
              }}
              render={({ field }) => (
                <SelectInput
                  options={percentageRiskTypes}
                  {...field}
                  error={errors.percentageRiskType}
                  onChange={(risk) =>
                    setValue('percentageRiskType', risk?.value ?? percentageRiskTypes[0].value, {
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
