import { useRef, useState, KeyboardEventHandler } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'styles';
import { Heading } from 'common/components';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

type BoundingBoxNameProps = {
  id: string;
  currentName: string;
};

export const BoundingBoxName = ({ id, currentName }: BoundingBoxNameProps) => {
  const [isInInputMode, setIsInInputMode] = useState(false);
  const [boxName, setBoxName] = useState(currentName);
  const input = useRef<HTMLInputElement>(null);
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();

  const submitInputName = () => {
    if (boxName.length === 0) {
      return;
    }

    setIsInInputMode(false);
    writeStrategy(`flow/${tradingType}/${orderAction}/details/items/${id}/name`, boxName);
  };

  const switchToInput = () => {
    setIsInInputMode(true);
    setTimeout(() => input.current?.focus(), 100);
  };

  const onKeyDown: KeyboardEventHandler = ({ code }) => {
    if (code === 'Enter') {
      submitInputName();
    }
  };

  return (
    <Styled.Wrapper>
      {isInInputMode ? (
        <Styled.Input
          type="text"
          value={boxName}
          onChange={(e) => setBoxName(e.target.value)}
          onBlur={submitInputName}
          ref={input}
          onKeyDown={onKeyDown}
        />
      ) : (
        <Heading level="h4" color={theme.colors.white[1]} onClick={switchToInput}>
          {currentName || 'group name'}
        </Heading>
      )}
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div`
    position: absolute;
    left: 0;
    top: -18px;
  `,
  Input: styled.input`
    font-weight: 500;
    font-size: 12px;
    opacity: 0.5;
  `,
};
