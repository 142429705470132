import { sendAuthorizedApiRequest } from 'common/utils';
import { ReportedStrategy } from 'modules/strategies/types';

export const getReport = async (testId: string) => {
  const { data }: { data: ReportedStrategy } = await sendAuthorizedApiRequest({
    url: `${process.env.REACT_APP_API_V1}/reports/tested/${testId}`,
    method: 'GET',
  });

  return data;
};
