import { Sentence } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

import { isSentenceFinished } from './isSentenceFinished';

export const areSubsentencesFinished = (sentence: Sentence) => {
  for (const { item, type } of sentence.items) {
    if (type === TextEngineItemTypes.Subsentence) {
      if (!isSentenceFinished(item)) {
        return false;
      }
      if (!areSubsentencesFinished(item)) {
        return false;
      }
    }
  }
  return true;
};
