type ConversionMap = {
  [key: string]: number | string;
};

const conversionMap: ConversionMap = {
  m: 1,
  h: 60,
  d: 'D',
};

export const convertValue = (value: string) => {
  const unit = value.slice(-1);

  if (Object.keys(conversionMap).includes(unit)) {
    const conversion = conversionMap[unit];

    if (unit === 'd') {
      return value.replace(unit, conversion as string);
    }
    return parseInt(value) * (conversion as number);
  }
};

export const convertValueBack = (value: string) => {
  if (value?.endsWith('D')) {
    return value.replace('D', 'd');
  }
  const numericValue = parseInt(value);
  if (!isNaN(numericValue)) {
    if (numericValue % 60 === 0) {
      return `${numericValue / 60}h`;
    }
    return `${numericValue}m`;
  }
  return value;
};

export const extractAndConvertValues = (keys: string[], data: string[]) => {
  const allValues = data?.flatMap((item) =>
    keys.flatMap((key) => {
      const match = item.match(new RegExp(`${key}(\\d+)(m|h|d)`));

      return match ? convertValue(match[1] + match[2]) : [];
    }),
  );

  return [...new Set(allValues)];
};
