import {
  SentenceItem,
  TypableCandleVar,
  TypableIndicator,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const isHintIndicatorOrCandle = (hint: SentenceItem): hint is TypableIndicator | TypableCandleVar => {
  const { type } = hint;
  return type === TextEngineItemTypes.CandleVar || type === TextEngineItemTypes.Indicator;
};
