import {
  isHintAnyPhrase,
  isSentenceFinished,
  isSentenceFinishedAndValid,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import {
  HintableItem,
  Sentence,
  SentenceItem,
  TypablePhrase,
  TypableValuePhrase,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import { TextEngineItemTypes } from 'modules/creator/types';
import { OrderActions } from 'types';

type GetNewSentenceArgs = {
  sentence: Sentence | null;
  hint: HintableItem;
  parent?: Sentence;
  flowType: OrderActions;
};

export const getNewSentence = ({ sentence, hint, parent, flowType }: GetNewSentenceArgs): Sentence => {
  if (sentence) {
    if (sentence && isSentenceFinishedAndValid(sentence, flowType)) {
      return sentence;
    }

    const lastSentenceItem = sentence.items.at(-1);
    if (lastSentenceItem?.type === TextEngineItemTypes.Subsentence && !isSentenceFinished(lastSentenceItem?.item)) {
      const newSubsentence = getNewSentence({ sentence: lastSentenceItem.item, hint, parent: sentence, flowType });
      return {
        items: [...sentence.items.slice(0, -1), { item: newSubsentence, type: TextEngineItemTypes.Subsentence }],
        assignedPhrase: sentence.assignedPhrase,
      };
    }

    if (hint.forSubsentence && !isValueConditionModal(flowType)) {
      let placeInSentence: number | undefined;
      let sentenceItemsToSubsentence: SentenceItem[] = [];
      let sentenceRestItems: SentenceItem[] = [];

      if (isHintAnyPhrase(hint)) {
        (hint as TypableValuePhrase).item.structure.forEach((allowedTypes, index) => {
          if (allowedTypes.some((item) => item === TextEngineItemTypes.Own)) {
            placeInSentence = index;
          }
        });
        if (placeInSentence !== undefined) {
          sentenceItemsToSubsentence = sentence.items.slice(
            placeInSentence === 0 ? sentence.items.length : -placeInSentence,
          );
          sentenceRestItems = sentence.items.slice(0, placeInSentence === 0 ? sentence.items.length : -placeInSentence);
        }
      }

      return {
        items: [
          ...sentenceRestItems,
          {
            item: {
              items: [...sentenceItemsToSubsentence, hint],
              assignedPhrase: isHintAnyPhrase(hint) ? (hint as TypablePhrase) : undefined,
            },
            type: TextEngineItemTypes.Subsentence,
          },
        ],
        assignedPhrase: sentence.assignedPhrase,
      };
    }

    if (
      sentence.assignedPhrase?.item.result_type === 'value' &&
      !isValueConditionModal(flowType) &&
      sentence.assignedPhrase.item.structure.length === sentence.items.length + 1 &&
      !parent
    ) {
      return {
        items: [
          {
            item: { items: [...sentence.items, hint], assignedPhrase: sentence.assignedPhrase },
            type: TextEngineItemTypes.Subsentence,
          },
        ],
      };
    }

    if (hint.type === TextEngineItemTypes.Own) {
      sentence.assignedPhrase = hint;
    }
    sentence.items.push(hint);

    return sentence;
  }
  return hint.forSubsentence && !isValueConditionModal(flowType)
    ? {
        items: [
          {
            item: {
              items: [hint],
              assignedPhrase: isHintAnyPhrase(hint) ? (hint as TypablePhrase) : undefined,
            },
            type: TextEngineItemTypes.Subsentence,
          },
        ],
      }
    : {
        items: [hint],
        assignedPhrase: isHintAnyPhrase(hint) ? (hint as TypablePhrase) : undefined,
      };
};
