import { useEffect, useState } from 'react';

import { useDocument } from 'common/hooks';
import { MergedStrategy, StrategyListFilters, StrategyListFiltersSections } from 'modules/strategies/types';

export const useFilters = (mergedStrategies?: MergedStrategy[] | null) => {
  const [filteredStrategies, setFilteredStrategies] = useState<MergedStrategy[] | null>(null);
  const [truthyFilters, setTruthyFilters] = useState<StrategyListFilters>();
  const [filters] = useDocument<StrategyListFilters>('filters');

  useEffect(() => {
    if (filters) {
      const truthy = Object.fromEntries(
        Object.entries(filters).map(([key, insideFilters]) => [
          key,
          Object.fromEntries(Object.entries(insideFilters).filter(([, value]) => value)),
        ]),
      ) as StrategyListFilters;
      setTruthyFilters(truthy);
    }
  }, [filters]);

  useEffect(() => {
    if (truthyFilters && mergedStrategies) {
      let tempStrategies = mergedStrategies;
      for (const section in truthyFilters) {
        const typedSection = section as StrategyListFiltersSections;
        const sectionValue = truthyFilters[typedSection];
        for (const filter in sectionValue) {
          switch (typedSection) {
            case 'markets':
              tempStrategies = tempStrategies.filter(({ strategy }) => strategy.asset?.market === filter);
              break;
            case 'states':
              tempStrategies = tempStrategies.filter(
                ({ lastActivities }) =>
                  (!lastActivities && filter === 'draft') ||
                  (lastActivities?.some(({ name }) => name === 'backtest') && filter === 'backtested'),
              );
              break;
            case 'symbols':
              tempStrategies = tempStrategies.filter(({ strategy }) => strategy.asset?.symbol === filter);
              break;
          }
        }
      }
      setFilteredStrategies(tempStrategies);
    } else if (mergedStrategies) {
      setFilteredStrategies(mergedStrategies);
    }
  }, [truthyFilters, mergedStrategies]);

  return filteredStrategies;
};
