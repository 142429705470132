import { HintableItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { Dictionary, TextEngineItemTypes } from 'modules/creator/types';
import { OrderActions } from 'types/StrategyTypes';

type ConvertToHintableArray = (dictionary: Dictionary, flowType: OrderActions) => HintableItem[];

export const convertToHintableArray: ConvertToHintableArray = (
  { indicators, phrases, candle_vars, variables, value_phrases, values },
  flowType,
) => {
  const hintableIndicators = Object.values(indicators).map((indicator) => ({
    item: indicator,
    type: TextEngineItemTypes.Indicator,
  }));
  const hintablePhrases = Object.values(phrases).map((phrase) => ({
    item: phrase,
    type: TextEngineItemTypes.Own,
    phraseType: TextEngineItemTypes.Phrase,
  }));
  const hintableValuePhrases = Object.values(value_phrases)
    .filter((phrase) => (flowType !== OrderActions.CLOSE_POSITION ? phrase.key !== 'risk to reward 1:x' : true))
    .map((phrase) => ({
      item: phrase,
      type: TextEngineItemTypes.Own,
      phraseType: TextEngineItemTypes.ValuePhrase,
      forSubsentence: true,
    }));
  const hintableVariables = Object.values(variables)
    .filter(
      (variable) =>
        (flowType === OrderActions.OPEN ? variable.name !== 'open_price' : true) && variable.type === 'point',
    )
    .map((variable) => ({
      item: variable,
      type: TextEngineItemTypes.Variable,
    }));
  const hintableTableVariables = Object.values(variables)
    .filter(
      (variable) =>
        (flowType === OrderActions.OPEN ? variable.name !== 'open_price' : true) && variable.type === 'table',
    )
    .map((variable) => ({
      item: variable,
      type: TextEngineItemTypes.TableVariable,
    }));
  const hintableCandles = Object.values(candle_vars).map((candle_var) => ({
    item: candle_var,
    type: TextEngineItemTypes.CandleVar,
  }));
  const hintableValues = Object.values(values).map((value) => ({
    item: value,
    type: TextEngineItemTypes.Value,
  }));

  return [
    ...hintableIndicators,
    ...hintablePhrases,
    ...hintableCandles,
    ...hintableVariables,
    ...hintableValuePhrases,
    ...hintableValues,
    ...hintableTableVariables,
  ] as HintableItem[];
};
