import { useMemo } from 'react';

import { useStrategyComponentsStatus } from './useStrategyComponentsStatus';

export const useStrategyChecklist = () => {
  const { name, symbol, open, close, stopLoss, trailingStopLoss } = useStrategyComponentsStatus();

  const checklist = useMemo(
    () => [
      {
        label: 'Strategy name',
        isDone: name,
        optional: false,
      },
      {
        label: 'Chosen symbol',
        isDone: symbol,
        optional: false,
      },
      {
        label: 'Open condition',
        isDone: open,
        optional: false,
      },
      {
        label: 'Stop loss condition',
        isDone: stopLoss,
        optional: false,
      },
      {
        label: 'Trailing stop loss condition (optional)',
        isDone: trailingStopLoss,
        optional: true,
      },
      {
        label: 'Close condition (optional)',
        isDone: close,
        optional: true,
      },
    ],
    [name, symbol, close, open, stopLoss, trailingStopLoss],
  );

  return { isStrategyDone: !checklist.some(({ isDone, optional }) => !optional && !isDone), checklist };
};
