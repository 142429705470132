import { useNavigate } from 'react-router';

import { theme } from 'styles';
import { routes } from 'routes';
import { Layout, Heading, Flex, Button, Icon } from 'common/components';

export const CheckoutErrorView = () => {
  const navigate = useNavigate();

  return (
    <Layout alignContent="center" noSidebar>
      <Flex height="100%" align="center" justify="center">
        <Flex direction="column" align="flex-start" gap={24}>
          <Icon icon="error" color={theme.colors.red[2]} size={68} />
          <Heading level="h1">
            Uh oh... Payment
            <span style={{ color: theme.colors.red[2] }}> failed</span>
          </Heading>
          <Heading level="h3">
            It looks like something went wrong with your payment. Please try again or contact us if the problem
            continues.
          </Heading>
          <Button onClick={() => navigate(routes.dashboard)} variant="outlined">
            Go back home
          </Button>
        </Flex>
      </Flex>
    </Layout>
  );
};
