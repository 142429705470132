import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';

import { StrategyState } from 'types';
import { useDocument, useWriteDocument } from 'common/hooks';
import { Button, Checkbox, Flex, Heading, Icon } from 'common/components';
import { useGetDataSources } from 'modules/creator/hooks';
import { StrategyListFilters, StrategyListFiltersSections } from 'modules/strategies/types';
import { formatDataSources } from 'modules/creator/components/SymbolAndMarketModal/utils';

type StrategyFiltersProps = {
  areFiltersVisible: boolean;
  setAreFiltersVisible: (areFiltersVisible: boolean) => void;
};

export const StrategyFilters = ({ areFiltersVisible, setAreFiltersVisible }: StrategyFiltersProps) => {
  const [expandedMarkets, setExpandedMarkets] = useState<Record<string, boolean> | null>(null);
  const [isSymbolExpanded, setIsSymbolExpanded] = useState(true);
  const [isActivitiesExpanded, setIsActivitiesExpanded] = useState(true);
  const { dataSources } = useGetDataSources();
  const formattedData = useMemo(() => formatDataSources(dataSources), [dataSources]);
  const strategiesStates: StrategyState[] = ['backtested', 'draft'];
  const [filters] = useDocument<Partial<StrategyListFilters> | undefined>('filters');
  const { register, reset, handleSubmit, setValue } = useForm<StrategyListFilters>();
  const writeDocument = useWriteDocument();

  useEffect(() => {
    if (filters) {
      for (const section in filters) {
        const typedSection = section as StrategyListFiltersSections;
        const sectionValue = filters[typedSection];
        for (const filter in sectionValue) {
          setValue(`${typedSection}.${filter}`, sectionValue[filter]);
        }
      }
    }
  }, [filters, setValue]);

  useEffect(() => {
    if (formattedData?.markets && formattedData.markets.length > 0 && expandedMarkets === null) {
      setExpandedMarkets(formattedData.markets.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}));
    }
  }, [formattedData, expandedMarkets]);

  const submitFilters: SubmitHandler<StrategyListFilters> = (data) => {
    writeDocument('filters', data);
    setAreFiltersVisible(false);
  };

  return createPortal(
    <Styled.Form areFiltersVisible={areFiltersVisible} onSubmit={handleSubmit(submitFilters)}>
      <Flex direction="column" width="100%" height="calc(100% - 64px)" align="flex-start" gap={16}>
        <Button variant="ghost" onClick={() => setAreFiltersVisible(false)} noPadding type="button">
          <Icon icon="arrow-right" />
        </Button>
        <Flex justify="space-between" align="center" width="100%">
          <Heading level="h1">Filters</Heading>
          <Button variant="outlined" type="button" onClick={() => reset()}>
            Clear all
          </Button>
        </Flex>
        <Styled.List first>
          <Styled.ListItem>
            <Flex justify="space-between" width="100%">
              <span>Symbol</span>
              <Button variant="ghost" onClick={() => setIsSymbolExpanded((prev) => !prev)} noPadding type="button">
                <Icon icon={isSymbolExpanded ? 'arrow-up' : 'arrow-down'} />
              </Button>
            </Flex>
            {isSymbolExpanded && (
              <Styled.List>
                {formattedData?.markets
                  .filter((market) => market !== 'All')
                  .map((market) => (
                    <Styled.ListItem key={market}>
                      <Flex justify="space-between" width="100%">
                        <Checkbox label={market} {...register(`markets.${market}`)} />
                        <Button
                          type="button"
                          noPadding
                          variant="ghost"
                          onClick={() =>
                            setExpandedMarkets((prev) => (prev ? { ...prev, [market]: !prev[market] } : null))
                          }
                        >
                          <Icon icon={expandedMarkets && expandedMarkets[market] ? 'arrow-up' : 'arrow-down'} />
                        </Button>
                      </Flex>
                      {expandedMarkets &&
                        expandedMarkets[market] &&
                        formattedData.symbols
                          .filter((symbol) => symbol.market === market)
                          .map(({ symbol, icon }) => (
                            <Styled.ListItem key={symbol} nested>
                              <Checkbox
                                label={
                                  <Flex gap={8}>
                                    <img
                                      src={`data:image/svg+xml;base64,${icon}`}
                                      alt={symbol}
                                      width={24}
                                      height={24}
                                    />
                                    {symbol}
                                  </Flex>
                                }
                                {...register(`symbols.${symbol}`)}
                              />
                            </Styled.ListItem>
                          ))}
                    </Styled.ListItem>
                  ))}
              </Styled.List>
            )}
          </Styled.ListItem>
          <Styled.ListItem>
            <Flex justify="space-between" width="100%">
              <span>Last activites</span>
              <Button variant="ghost" onClick={() => setIsActivitiesExpanded((prev) => !prev)} noPadding type="button">
                <Icon icon={isActivitiesExpanded ? 'arrow-up' : 'arrow-down'} />
              </Button>
            </Flex>
            {isActivitiesExpanded &&
              strategiesStates.map((state) => (
                <Styled.ListItem key={state}>
                  <Checkbox label={state} {...register(`states.${state}`)} />
                </Styled.ListItem>
              ))}
          </Styled.ListItem>
        </Styled.List>
      </Flex>
      <Button variant="primary" customWidth="100%" type="submit">
        Apply filters
      </Button>
    </Styled.Form>,
    document.getElementById('modal') as HTMLElement,
  );
};

const Styled = {
  Form: styled.form<Pick<StrategyFiltersProps, 'areFiltersVisible'>>`
    position: fixed;
    top: 0;
    right: -364px;
    bottom: 0;
    height: 100vh;
    width: 364px;
    background-color: ${({ theme }) => theme.colors.black[1]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    z-index: ${({ theme }) => theme.zIndexes.modalLeveling(50)};
    transform: ${({ areFiltersVisible }) => (areFiltersVisible ? 'translateX(-100%)' : 'translateX(100%)')};
    transition: transform 0.5s ease-in-out;
  `,

  List: styled.ul<{ first?: boolean }>`
    margin: 0;
    padding: ${({ first }) => (first ? '0 12px' : 0)};
    overflow-y: ${({ first }) => (first ? 'scroll' : 'hidden')};
    list-style-type: none;
    height: 100%;
    width: 100%;
  `,

  ListItem: styled.li<{ nested?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 0 6px ${({ nested }) => (nested ? '36px' : 0)};
    gap: 16px;
  `,
};
