// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
export const app = initializeApp({
  ...firebaseConfig,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_DEFAULT_URL,
});
export const appNotifications = initializeApp(
  {
    ...firebaseConfig,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_NOTIFICATIONS_URL,
  },
  'appNotifications',
);
export const appFirestore = initializeApp(firebaseConfig, 'appFirestore');

export const db = getDatabase(app);
export const dbNotifications = getDatabase(appNotifications);
export const dbFirestore = getFirestore(appFirestore);

export const auth = getAuth(app);
export const authNotifications = getAuth(appNotifications);
export const authFirestore = getAuth(appFirestore);
