import { ConditionsTreeType, Result } from 'modules/creator/components/ConditionsFlow/types';

export type StrategyState = 'backtested' | 'draft';

export enum BotColor {
  WHITE = 'white',
  BLUE = 'blue',
  PINK = 'pink',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  RED = 'red',
}
export type BotType = 'sad' | 'happy' | 'neutral' | 'progress';

export type AssetType = {
  market: string;
  symbol: string;
  exchange: string;
  icon: string;
  longName: string;
  dsid: string;
  symbolKey: string;
};

export type PendingReportResponseType = {
  testid: string;
  strategyname: string;
  uid: string;
  market: string;
  symbol: string;
  timestamp: number;
};

export type SummaryReportItem = {
  value: number;
  name: string;
};

export type TestedReportResponseType = PendingReportResponseType & {
  summary: SummaryReportItem[];
};

export type ReportType = PendingReportResponseType | TestedReportResponseType;

export enum OrderActions {
  OPEN = 'OPEN',
  STOP_LOSS = 'STOP_LOSS',
  TRAILING_STOP_LOSS = 'TRAILING_STOP_LOSS',
  TRAILING_STOP_LOSS_ACTIVATE = 'TRAILING_STOP_LOSS_ACTIVATE',
  CLOSE_POSITION = 'CLOSE_POSITION',
}

export enum TradingType {
  long = 'long',
  short = 'short',
}

export type OrderAction = {
  details: ConditionsTreeType;
  result?: Result;
};

export type Flow = Record<TradingType, Record<OrderActions, OrderAction>>;

export type StrategyType = {
  asset: AssetType;
  flow: Flow;
  isExpanded: Record<TradingType, Record<OrderActions | 'section', boolean>>;
  modals: Record<TradingType, Record<OrderActions, boolean>>;
  name: string;
  timestamp: number;
  created: number;
  description: string;
  isCompleted?: boolean;
  botColor: BotColor;
  subStrategies: string[];
};

export type PartialStrategy = Partial<StrategyType> & { id: string; timestamp: number; created: number };

export type DraftStrategyType = {
  id: string;
} & Pick<StrategyType, 'name' | 'asset' | 'timestamp' | 'isCompleted' | 'created'>;
