import { isEmpty } from 'lodash';

import { Flow } from 'types';

export const isStrategyFlowEmpty = (flow?: Flow) => {
  if (!flow) {
    return true;
  }

  for (const tradingType of Object.values(flow)) {
    for (const orderAction of Object.values(tradingType)) {
      if (
        orderAction.result ||
        !isEmpty(Object.values(orderAction.details.items).filter((item) => item.type === 'node' && item.rel))
      ) {
        return false;
      }
    }
  }

  return true;
};
