import { DragEventHandler, WheelEventHandler, useRef, useState } from 'react';

import { useStrategyContext } from 'modules/creator/context';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { useLayerPosition } from './useLayerPosition';
import { useZoom } from './useZoom';

export const useDraggableLayer = () => {
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();
  const [dragStartPosition, setDragStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [xLayerPosition, yLayerPosition] = useLayerPosition();
  const zoom = useZoom();
  const draggableRef = useRef<HTMLDivElement>(null);

  const handleDragStart: DragEventHandler<HTMLDivElement> = (e) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setDragImage(new Image(), 0, 0);
    setDragStartPosition({
      x: xLayerPosition - e.clientX,
      y: yLayerPosition - e.clientY,
    });
  };

  const handleDrag: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    writeStrategy(`flow/${tradingType}/${orderAction}/position`, {
      x: e.clientX + dragStartPosition.x,
      y: e.clientY + dragStartPosition.y,
    });
  };

  const handleScrolling: WheelEventHandler<HTMLDivElement> = (e) => {
    const delta = e.deltaY < 0 ? -0.02 : 0.02;
    writeStrategy(`flow/${tradingType}/${orderAction}/zoom`, zoom ? zoom + delta : 1 + delta);
  };

  return { handleScrolling, handleDrag, handleDragStart, draggableRef };
};
