import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';

import { OrderActions, TradingType } from 'types';
import { Icon, Tooltip } from 'common/components';
import { useStrategyContext } from 'modules/creator/context';
import { useStrategy } from 'modules/creator/hooks';
import { ConditionButton } from 'modules/creator/components/StrategyInterface/components/ConditionButton';

import { OrderActionSectionContextProvider } from './context';

type OrderActionSectionProps = {
  type: string;
  subTypes: OrderActions[];
  tradingType: TradingType;
  nested?: boolean;
};

const OpenCloseTypesInfo: Record<string, { text: string; tooltip: string }> = {
  OPEN: { text: 'Open', tooltip: 'Set conditions for opening the position' },
  STOP_LOSS: { text: 'Stop loss', tooltip: 'Set conditions for stop loss for this strategy' },
  TRAILING_STOP_LOSS: { text: 'Trailing stop loss', tooltip: 'Set a trailing stop loss condition' },
  TRAILING_STOP_LOSS_ACTIVATE: {
    text: 'Trailing stop loss activate',
    tooltip: 'Set the condition at which you want the trailing stop loss to take effect',
  },
  CLOSE_POSITION: { text: 'Close position', tooltip: 'Set conditions for closing the position' },
};

export const OrderActionSection = ({
  type,
  subTypes,
  tradingType,
  children,
  nested,
}: PropsWithChildren<OrderActionSectionProps>) => {
  const [isExpanded] = useStrategy(`isExpanded/${tradingType}/${type}`);
  const { writeStrategy } = useStrategyContext();

  return (
    <Styled.ElementsWrapper noArrow={!!children}>
      <Styled.Element noArrow={!!children} nested={nested}>
        <Styled.Head
          onClick={
            type === OrderActions.OPEN
              ? undefined
              : () => writeStrategy(`isExpanded/${tradingType}/${type}`, !isExpanded)
          }
        >
          {<Icon icon={isExpanded ? 'arrow-down' : 'arrow-up'} />}
          {OpenCloseTypesInfo[type].text}
        </Styled.Head>
      </Styled.Element>
      {isExpanded && (
        <>
          {subTypes.map((subType, index) => (
            <Styled.ElementsWrapper index={index} key={subType} nested={nested}>
              <Styled.Element>
                <Tooltip
                  id={subType}
                  content={OpenCloseTypesInfo[subType].tooltip}
                  place="bottom"
                  positionStrategy="fixed"
                >
                  <OrderActionSectionContextProvider tradingType={tradingType} orderAction={subType}>
                    <ConditionButton />
                  </OrderActionSectionContextProvider>
                </Tooltip>
              </Styled.Element>
            </Styled.ElementsWrapper>
          ))}

          {children && (
            <Styled.ElementsWrapper index={subTypes.length} noArrow>
              <Styled.Element noArrow>{children}</Styled.Element>
            </Styled.ElementsWrapper>
          )}
        </>
      )}
    </Styled.ElementsWrapper>
  );
};

const Styled = {
  Head: styled.button`
    background-color: ${({ theme }) => theme.colors.black[3]};
    padding: 4px 12px 4px 8px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
  `,

  Element: styled.div<{ nested?: boolean; noArrow?: boolean }>`
    position: relative;
    padding-left: ${({ noArrow, nested }) => (noArrow ? 0 : nested ? '62px' : '12px')};
    width: 100%;

    ${({ noArrow, nested }) =>
      !noArrow &&
      css`
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          left: ${nested ? '50px' : '0px'};
          border-right: 2px solid ${({ theme }) => theme.colors.black[3]};
          border-bottom: 2px solid ${({ theme }) => theme.colors.black[3]};
          width: 10px;
          height: 10px;
          background-color: inherit;
        }

        &::after {
          content: '';
          position: absolute;
          width: ${nested ? '60px' : '10px'};
          height: 2px;
          background-color: ${({ theme }) => theme.colors.black[3]};
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
        }
      `}
  `,

  ElementsWrapper: styled.section<{ index?: number; nested?: boolean; noArrow?: boolean }>`
    position: relative;
    width: calc(100% - 25px);
    margin: ${({ nested, noArrow }) => (noArrow ? 0 : nested ? '0px 87px' : '0px 25px')};
    padding-top: 15px;
    border-left: ${({ noArrow, theme }) => (noArrow ? 'none' : `solid 2px ${theme.colors.black[3]}`)};

    ${({ noArrow, index }) =>
      !noArrow &&
      css`
        &::before {
          content: '';
          position: absolute;
          width: 2px;
          height: ${index === undefined ? '31px' : index > 0 ? `${(index + 1) * 31}px` : 'calc(11px + 50%)'};
          top: ${index ? `${-2 - index * 31}px` : '-2px'};
          left: -2px;
          background-color: ${({ theme }) => theme.colors.black[3]};
        }

        &:last-child {
          border-left: 2px solid transparent;
        }

        &:first-child {
          border-left: 2px solid transparent;

          &::before {
            height: ${index === undefined || index === 0 ? '100px' : 'inherit'};
            top: ${index === undefined || index === 0 ? '-15px' : 'inherit'};
          }
        }
      `}
  `,
};
