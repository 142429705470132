import { Activity } from 'modules/strategies/types';

export const getLastActivities = (reportType: 'tested' | 'pending', startBacktestTimestamp?: number): Activity[] => {
  const activityDone = { name: 'backtest', status: 'done', tooltip: 'This strategy has a backtest report' } as const;
  const acttivityProgress = {
    name: 'backtest',
    status: 'progress',
    tooltip: 'Backtest in progress',
    startTimestamp: startBacktestTimestamp,
  } as const;

  return [reportType === 'pending' ? acttivityProgress : activityDone];
};
