import { OnboardingStep } from 'types';
import { Flex, Icon } from 'common/components';
import robots from 'assets/images/robots.png';
import andConditions from 'assets/images/OnboardingAndConditions.png';
import orConditions from 'assets/images/OnboardingOrConditions.png';
import strategyCreator from 'assets/images/OnboardingStrategyCreator.png';

export const onboardingStepsDashboard: OnboardingStep[] = [
  {
    placement: 'center',
    data: {
      url: '/',
      image: robots,
      skip: {
        content: 'Explore by myself',
        variant: 'outlined',
        show: true,
      },
      next: {
        content: 'Show me everything',
      },
    },
    target: '.main-window',
    title: 'Meet SkyTrading app',
    content:
      'We introduce you to a faster and simpler way to create your investment strategy. Now, you can create and backtest your strategies. First, let us guide you through our app.',
  },
  {
    target: '.onboarding-nav-home',
    title: (
      <Flex gap={8}>
        Now you’re on <Icon icon="home" />
        Home
      </Flex>
    ),
    content: 'Here you can find all important informations.',
    data: {
      url: '/',
    },
  },
  {
    target: '.onboarding-nav-rewards',
    title: (
      <Flex gap={8}>
        Here are <Icon icon="trophy" />
        Rewards
      </Flex>
    ),
    data: {
      url: '/',
    },
    content: 'There is a list with all your trophies that you have unlocked. Collect them for some extra gifts.',
  },
  {
    target: '.onboarding-nav-strategies',
    title: (
      <Flex gap={8}>
        Here is <Icon icon="chart" />
        My strategies
      </Flex>
    ),
    data: {
      url: '/',
    },
    content: 'Inside you can find all crated bots.',
  },
  {
    target: '.onboarding-nav-discord',
    title: (
      <Flex gap={8} wrap="wrap">
        You find any problem, open <Icon icon="discord" />
        Discord
      </Flex>
    ),
    data: {
      url: '/',
    },
    content:
      'Is something missing, some bugs, or want to give us some feedback? Join our Discord and tell us what you want.',
  },
  {
    target: '.onboarding-nav-create',
    title: (
      <Flex gap={8} wrap="wrap">
        Now, the real thing, click <Icon icon="add" />
        Create strategy
      </Flex>
    ),
    data: {
      url: '/',
      next: {
        content: 'Finish',
      },
    },
    content: 'We will create your first strategy together.',
  },
];

export const onboardingStepsCreateStrategy: OnboardingStep[] = [
  {
    placement: 'center',
    data: {
      url: 'create',
      image: strategyCreator,
      skip: {
        content: 'Create on your own',
        variant: 'outlined',
        show: true,
      },
      next: {
        content: 'Show me  how',
      },
    },
    target: '.main-window',
    title: "Let's create your first strategy!",
    content: 'Together we will make first strategy. Shall we begin?',
  },
  {
    target: '.onboarding-main-box',
    title: 'Main box',
    data: {
      url: 'create',
    },
    content:
      'Here you can name your strategy, choose a symbol, and set the conditions you need. You can include conditions for short and long positions in a strategy.',
  },
  {
    data: { url: 'create' },
    placement: 'bottom',
    target: '.onboarding-checklist',
    title: 'The Checklist',
    content:
      'It will show you in which phase your strategy is. To perform the backtest, your strategy must have an opening condition and at least one closing condition (stop loss, trailing stop loss, close position).',
  },
  {
    target: '.onboarding-strategy-create-name',
    title: 'Name strategy',
    content: 'Type your strategy name.',
    spotlightClicks: true,
    data: {
      url: 'create',
      blockNext: true,
    },
  },
  {
    target: '.onboarding-strategy-set-symbol',
    title: 'Trading symbol',
    content: 'Click on this bar to set trading symbol.',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-set-symbol-modal',
    title: 'Trading symbol',
    content: 'Choose one symbol from following list.',
    disableOverlay: true,
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-set-open',
    title: 'Open condition',
    content: 'Wonderful, now let’s set open condition. Click here',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-set-open-condition',
    title: 'First condition',
    content: 'Here, you will type your first condition. Click here.',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-example',
    title: 'Condition example',
    content: 'This is example, that you can use. Click next to start typing.',
    data: {
      url: 'create',
    },
  },
  {
    target: '.onboarding-strategy-text-box',
    title: 'Condition text box',
    content: 'Type your condition and click save when it’s ready.',
    disableOverlay: true,
    data: {
      url: 'create',
      clickTarget: '.onboarding-strategy-condition-save',
      next: {
        hide: true,
      },
    },
  },
  {
    data: {
      image: andConditions,
      url: 'create',
    },
    target: '.onboarding-strategy-and-button',
    title: 'Button AND',
    content:
      'For a more complex strategy, use the "and" connector. This can be useful when you want to use multiple conditions.',
  },
  {
    data: {
      image: orConditions,
      url: 'create',
    },
    target: '.onboarding-strategy-or-button',
    title: 'Button OR',
    content:
      'It only takes one condition out of several to be met for the strategy to open position. They work independently of each other, but remember that the strategy will not open more than one position at a time.',
  },
  {
    target: '.onboarding-strategy-and-button',
    title: 'Advanced condition',
    content: 'Click “and” to expand your condition',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-set-open-condition',
    title: 'Second condition',
    content: 'Here, you will type your second condition. Click here.',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-example',
    title: 'Condition example',
    content: 'This is example, that you can use. Click next to start typing.',
    data: {
      url: 'create',
    },
  },
  {
    target: '.onboarding-strategy-text-box',
    title: 'Condition text box',
    content: 'Type your condition and click save when it’s ready.',
    disableOverlay: true,
    data: {
      url: 'create',
      clickTarget: '.onboarding-strategy-condition-save',
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'bottom',
    target: '.onboarding-strategy-open-save',
    title: 'Your open condition is ready!',
    content: '',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-set-stop-loss',
    title: 'Stop loss condition',
    content: 'Great, now let’s set stop loss condition. Click here.',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-set-stop-loss-value',
    title: 'Stop loss value',
    content: 'Here, you can set value for stop loss. Click here.',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-strategy-example',
    title: 'Stop loss example',
    content: 'This is example, that you can use. Click next to start typing.',
    data: {
      url: 'create',
    },
  },
  {
    target: '.onboarding-strategy-text-box',
    title: 'Stop loss text box',
    content: 'Type your value and click save when it’s ready.',
    disableOverlay: true,
    data: {
      url: 'create',
      clickTarget: '.onboarding-strategy-condition-save',
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'top',
    target: '.onboarding-strategy-stop-loss-save',
    title: 'Click SAVE.',
    content: '',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'bottom',
    target: '.onboarding-checklist-complete',
    title: 'Your strategy is almost ready. Click COMPLETE',
    content: '',
    spotlightClicks: true,
    data: {
      url: 'create',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-checklist-complete-modal',
    title: 'Summary box',
    content: 'Type description and click save.',
    disableOverlay: true,
    data: {
      navigate: '/strategies',
      url: 'create',
      blockNext: true,
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'bottom',
    target: '.onboarding-strategy',
    title: 'Here is strategy that we created together.',
    content: 'Let’s check details.',
    spotlightClicks: true,
    data: {
      useParam: true,
      navigate: '/strategies',
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'bottom',
    target: '.onboarding-strategy-tabs',
    title: 'Tab with strategy details',
    content: 'Here, you can switch between strategy structure and strategy reports.',
    data: {
      url: 'strategies',
    },
  },
  {
    placement: 'bottom',
    target: '.onboarding-strategy-backtest-button',
    title: (
      <Flex gap={8} wrap="wrap">
        Here you can make <Icon icon="bar-line-chart" />
        Backtest Let’s make one now
      </Flex>
    ),
    content: 'Process of testing a trading strategy on historical data to assess its accuracy.',
    spotlightClicks: true,
    data: {
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-start-backtest-container',
    title: 'Enter the value of your total account, e.g. $1000',
    content: '',
    disableOverlay: true,
    data: {
      blockNext: true,
      clickTarget: '.onboarding-start-backtest-button',
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-start-backtest-container',
    title: 'Enter, e.g. 10% risk management of total capital',
    content: '',
    disableOverlay: true,
    data: {
      blockNext: true,
      clickTarget: '.onboarding-start-backtest-button',
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-start-backtest-container',
    title: 'Enter commission value according to your broker',
    content: 'If you don`t know then enter 0 or skip.',
    disableOverlay: true,
    data: {
      blockNext: true,
      clickTarget: '.onboarding-start-backtest-button',
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-start-backtest-container',
    title: 'Choose a date range for backtest',
    content: '',
    disableOverlay: true,
    data: {
      blockNext: true,
      clickTarget: '.onboarding-start-backtest-button',
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    target: '.onboarding-start-backtest-button',
    title: 'Click Start backtest',
    content: '',
    spotlightClicks: true,
    data: {
      url: 'strategies',
      navigate: '/strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'bottom',
    target: '.onboarding-strategy-activity',
    title: 'Now a backtest is in progress on your strategy',
    content: 'Let`s wait a little bit. Click next when the status changes.',
    data: { url: 'strategies' },
  },
  {
    placement: 'bottom',
    target: '.onboarding-strategy',
    title: 'Backtest is ready!',
    content: 'Let`s see the results. Click here.',
    spotlightClicks: true,
    data: {
      useParam: true,
      navigate: '/strategies',
      url: 'strategies',
      next: {
        hide: true,
      },
    },
  },
  {
    placement: 'top',
    target: '.onboarding-backtest-report',
    title: 'Backtest report',
    content: 'Below you will find all the statistics from the backtest.',
    data: {
      url: 'strategies',
    },
  },
  {
    placement: 'center',
    data: {
      url: 'strategies',
      image: robots,
      next: {
        content: 'Finish',
      },
    },
    target: '.main-window',
    title: 'Congratulations!',
    content:
      'You have completed all the tutorials available in the app. You are now ready to take full advantage of its features.',
  },
];

export const onboardingStrategyCreatorNextBlockers = onboardingStepsCreateStrategy.reduce<number[]>(
  (result, item, index) => {
    if (item.data?.blockNext) {
      return [...result, index];
    }
    return result;
  },
  [],
);

export const onboardingDashboardNextBlockers = onboardingStepsDashboard.reduce<number[]>((result, item, index) => {
  if (item.data?.blockNext) {
    return [...result, index];
  }
  return result;
}, []);
