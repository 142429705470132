import { ConditionsTreeType } from 'modules/creator/components/ConditionsFlow/types';

import { getDefaultFlowItems } from './getDefaultFlowItems';

export const getDefaultConditionsFlowData = (isValueConditionModal: boolean) => {
  const { node, box } = getDefaultFlowItems();

  const defaultConditionsFlowData: ConditionsTreeType = {
    id: 'open',
    position: {
      x: 50,
      y: 130,
    },
    items: isValueConditionModal
      ? { [node.id]: node }
      : {
          [box.id]: box,
          [node.id]: node,
        },
  };

  return defaultConditionsFlowData;
};
