import { useState } from 'react';
import styled from 'styled-components/macro';

import { Icon } from 'common/components';

import { UserDropdownList } from './UserDropdownList';

export const UserDropdown = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Styled.Container>
      <Styled.DropDownHead onClick={() => setIsExpanded((prev) => !prev)}>
        <Icon icon="user" size={32} />
        <Icon icon="arrow-down" />
      </Styled.DropDownHead>
      <UserDropdownList isExpanded={isExpanded} />
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    position: relative;
  `,

  DropDownHead: styled.button`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
    border: none;
    background-color: inherit;
    color: inherit;
  `,
};
