import { v4 as uuidv4 } from 'uuid';

import { BoundingBoxType, NodeType, RelationType } from 'modules/creator/components/ConditionsFlow/types';

export const addNodeOutter = (currentItem: BoundingBoxType, relation: RelationType = 'and') => {
  const { id, children, depth } = currentItem;
  const newBoundingBoxId = uuidv4();
  const newNodeId = uuidv4();

  const newNode: NodeType = {
    type: 'node',
    id: newNodeId,
    parent: newBoundingBoxId,
    arrow: false,
    relationType: relation,
    whichInOrder: 1,
    text: '',
    candles: '',
    indicators: '',
    rel: '',
  };
  const newBoundingBox: BoundingBoxType = {
    ...currentItem,
    type: 'box',
    id: id,
    parent: newBoundingBoxId,
    arrow: true,
    selfRelationType: relation,
    name: '',
    depth: depth + 1,
    children,
  };

  const newItemsIds = [id, newNodeId];

  const currentBox: BoundingBoxType = {
    ...currentItem,
    children: newItemsIds,
    childrenRelationType: relation,
    id: newBoundingBoxId,
    depth: 0,
  };

  return {
    [newBoundingBoxId]: currentBox,
    [newNodeId]: newNode,
    [id]: newBoundingBox,
  };
};
