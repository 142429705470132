import { useMemo, useEffect, useState } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { theme } from 'styles/theme';
import { Flex, Icon, Input, Modal, SelectInput, Tooltip } from 'common/components';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { Candle, Scope, TextEngineItemTypes } from 'modules/creator/types';
import {
  createResultCandleObject,
  getLastUsedCandle,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';
import { SelectOption } from 'types';
import { useConditionSetterContext } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';

import { timeFrames } from './timeFrames';
import { useIndicatorParams } from './hooks';

type OutData = {
  displayKey: string;
  name: string;
  scope?: Scope;
};

type Frame = {
  scope: string;
  unit: SelectOption<{ symbol: string }>;
};

type IndicatorValues = {
  candle: SelectOption<Candle> | null;
  frame: Frame;
  rangeScope: string;
  chosenIn: SelectOption | null;
  chosenOut: SelectOption<OutData> | null;
  paramValues: Record<string, string>;
};

const defaultUnit = { symbol: '', label: '', value: '' };

export const IndicatorSetterModal = () => {
  const [isDefaultCandleUnitRespected, setIsDefaultCandleUnitRespected] = useState(true);
  const { resultSentence, setResultSentence, setDisplayedModalInfo, updateSentenceAndHints, editedItem } =
    useConditionSetterContext();
  const {
    indicator,
    displayableParams,
    nonDisplayableParams,
    inDataOptions,
    outDataOptions,
    defaultChosenIn,
    defaultChosenOut,
  } = useIndicatorParams(editedItem);
  const { dictionary } = useStrategyContext();
  const {
    control,
    handleSubmit,
    setValue,
    resetField,
    watch,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<IndicatorValues>({
    defaultValues: {
      candle: null,
      frame: {
        unit: defaultUnit,
        scope: '',
      },
      rangeScope: '',
      paramValues: {
        ...displayableParams.reduce(
          (prev, { backendName, defaultValue }) => ({
            ...prev,
            [backendName]: defaultValue,
          }),
          {},
        ),
      },
      chosenIn: !indicator.params.indata.fixed ? inDataOptions[0] : undefined,
    },
  });
  const watchCandle = watch('candle');
  const candleTypes = useMemo(
    () => Object.values(dictionary?.candles || {}).map((item) => ({ ...item, label: item.name, value: item.name })),
    [dictionary?.candles],
  );

  const { lastUsedResultCandle, lastUsedCandleType, frameDefaultValue } = useMemo(
    () =>
      getLastUsedCandle({
        sentence: resultSentence,
        candlesList: candleTypes,
        index: editedItem?.index,
        isDefaultCandleUnitRespected,
      }),
    [resultSentence, candleTypes, editedItem?.index, isDefaultCandleUnitRespected],
  );

  useEffect(() => {
    if (lastUsedCandleType) {
      setValue('candle', lastUsedCandleType);
    }
  }, [lastUsedCandleType, setValue]);

  useEffect(() => {
    if (lastUsedResultCandle?.unit) {
      if (lastUsedResultCandle.unit.value !== 'pip') {
        setValue('frame.scope', lastUsedResultCandle.value);
      } else {
        setValue('rangeScope', lastUsedResultCandle.value);
      }
    }
  }, [lastUsedResultCandle, setValue]);

  useEffect(() => {
    if (frameDefaultValue) {
      setValue('frame.unit', frameDefaultValue.unit);
    }
  }, [frameDefaultValue, setValue]);

  useEffect(() => {
    if (defaultChosenOut) {
      setValue('chosenOut', defaultChosenOut);
    }
  }, [setValue, defaultChosenOut]);

  useEffect(() => {
    if (defaultChosenIn) {
      setValue('chosenIn', defaultChosenIn);
    }
  }, [setValue, defaultChosenIn]);

  const isValidAndReadyToSubmit = ({ candle, frame, chosenIn, chosenOut, rangeScope }: IndicatorValues) => {
    const inData = indicator.params.indata;

    if (!inData.fixed && !chosenIn) {
      return false;
    }

    if (indicator.params.outdata.length > 1 && !chosenOut) {
      return false;
    }

    if (candle?.windowtype === 'time' && !frame?.unit && !frame?.scope) {
      return false;
    }

    if (candle?.windowtype === 'range' && !rangeScope) {
      return false;
    }

    return !!candle;
  };

  const cancelIndicator = () => {
    setDisplayedModalInfo(null);
  };

  const submitIndicator: SubmitHandler<IndicatorValues> = (indicatorValues) => {
    const { candle, frame, chosenIn, chosenOut, paramValues, rangeScope } = indicatorValues;
    if (!isValidAndReadyToSubmit(indicatorValues) || !candle) return;

    const { fixed, data } = indicator.params.indata;

    const createIndicatorId = () => {
      const parameters = [];
      for (const { backendName, defaultValue } of displayableParams) {
        parameters.push(Number(paramValues[backendName] ?? defaultValue));
      }
      return `${indicator.name}${parameters.join('_')}${!fixed ? chosenIn?.value : ''}`;
    };
    const displayableParamsResult: Record<string, number> = {};
    const nonDisplayableParamsResult: Record<string, number> = {};
    displayableParams.forEach(
      ({ backendName, defaultValue }) =>
        (displayableParamsResult[backendName] = Number(paramValues[backendName] ?? defaultValue)),
    );
    nonDisplayableParams.forEach(
      ({ backendName, defaultValue }) => (nonDisplayableParamsResult[backendName] = defaultValue),
    );
    const resultIndicator = {
      id: createIndicatorId().replaceAll('.', 'p'),
      lib: 'TA',
      name: indicator.name.toLowerCase(),
      candle: `${candle.type}${candle.windowtype === 'time' ? frame.scope : rangeScope}${
        frame.unit ? frame.unit.symbol : 'p'
      }`,
      indata: `${fixed ? data.join() : chosenIn?.value}`,
      ...nonDisplayableParamsResult,
      ...displayableParamsResult,
      outdata: indicator.params.outdata.map(({ name }) => name).join(','),
      chosenOut: chosenOut?.value ?? 'single',
      result_type: indicator.result_type,
    };
    const resultCandle = createResultCandleObject(candle, {
      scope: candle.windowtype === 'time' ? frame.scope : rangeScope,
      unit: frame.unit,
    });
    const stringContent = {
      main: indicator.name.toUpperCase(),
      params: `${chosenOut?.displayKey ?? ''} (${[
        `${candle.windowtype === 'time' ? frame.scope : rangeScope}${frame.unit ? frame.unit.symbol : 'p'}`,
        ...Object.values(displayableParamsResult),
      ].join()})`,
    };

    setResultSentence((prev) =>
      editedItem?.index !== undefined && editedItem?.subItems
        ? prev.map((item, index) =>
            index === editedItem.index
              ? {
                  stringContent,
                  origin: indicator,
                  type: TextEngineItemTypes.Indicator,
                  items: { indicator: resultIndicator, candle: resultCandle },
                }
              : item,
          )
        : [
            ...prev,
            {
              stringContent,
              origin: indicator,
              type: TextEngineItemTypes.Indicator,
              items: { indicator: resultIndicator, candle: resultCandle },
            },
          ],
    );
    if (editedItem?.index === undefined && !editedItem?.subItems) {
      updateSentenceAndHints({ hint: { item: indicator, type: TextEngineItemTypes.Indicator } });
    }
    setDisplayedModalInfo(null);
  };

  return (
    <Modal
      heading={indicator.fullname.toUpperCase()}
      cancel={{
        onClick: cancelIndicator,
        variant: 'outlined',
      }}
      formProps={{ onSubmit: handleSubmit(submitIndicator), noValidate: true }}
      overlayProps={{ zIndex: theme.zIndexes.modalLeveling(1) }}
      width="400px"
    >
      <Flex gap={32} direction="column" align="flex-start" width="100%">
        <Styled.Container
          isTopContainer
          isSingleContainer={!(indicator.params.outdata.length > 1 || displayableParams.length > 0)}
        >
          <Styled.Heading>Candles</Styled.Heading>
          <Styled.FormPosition>
            <Styled.Text>Candle type</Styled.Text>
            <Controller
              control={control}
              name="candle"
              rules={{
                required: {
                  value: true,
                  message: 'Choose candle',
                },
              }}
              render={({ field }) => (
                <SelectInput<Candle>
                  {...field}
                  placeholder="Type"
                  options={candleTypes}
                  defaultValue={lastUsedCandleType}
                  onChange={(option) => {
                    resetField('rangeScope', { keepError: true });
                    resetField('frame', { keepError: true });
                    setIsDefaultCandleUnitRespected(false);
                    setValue('candle', option);
                    clearErrors('candle');
                  }}
                  error={errors.candle as FieldError}
                />
              )}
            />
          </Styled.FormPosition>
          {/*The two conditional renders below, must be in separate logical products. Do not change them to ternary oparator!*/}
          {watchCandle?.windowtype !== 'range' && (
            <Styled.FormPosition>
              <Styled.Text>Time frame</Styled.Text>
              <Controller
                control={control}
                name="frame"
                rules={{
                  required: {
                    value: true,
                    message: 'Choose time frame',
                  },
                  validate: () => {
                    const frameValues = getValues('frame');
                    const isScopeSet = !!frameValues['scope'].length;
                    const isUnitSet = !!frameValues['unit'].value.length;

                    return (isScopeSet && isUnitSet) || 'Choose time frame';
                  },
                }}
                render={({ field }) => (
                  <SelectInput
                    {...field}
                    placeholder="Time"
                    defaultValue={frameDefaultValue}
                    onChange={(option) => {
                      setValue('frame.scope', String(option?.scope) ?? '', { shouldValidate: true });
                      setValue('frame.unit', option?.unit ?? defaultUnit, { shouldValidate: true });
                      clearErrors('frame');
                    }}
                    options={timeFrames}
                    disabled={!watchCandle && !lastUsedCandleType}
                    error={errors.frame as FieldError}
                  />
                )}
              />
              <Tooltip
                id="time-frame-help"
                content="The period of time that each candlestick represents"
                wrapperProps={{ height: '46px', align: 'center' }}
              >
                <Icon icon="bold-help" color={theme.colors.black[3]} />
              </Tooltip>
            </Styled.FormPosition>
          )}
          {watchCandle?.windowtype === 'range' && (
            <Styled.FormPosition>
              <Styled.Text>Range frame</Styled.Text>
              <Controller
                control={control}
                name="rangeScope"
                rules={{
                  required: {
                    value: true,
                    message: 'Choose range frame',
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onKeyDown={(e) => ['-', '+', 'e'].includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      setValue('rangeScope', e.target.value);
                      setValue('frame.unit', { label: 'pip', value: 'pip', symbol: 'p' });
                    }}
                    type="number"
                    disabled={!watchCandle && !lastUsedCandleType}
                    min={0}
                    error={errors.rangeScope}
                  />
                )}
              />
              <Tooltip
                id="time-range-help"
                content="As unit of candle range we use smallest value of current strategy asset e.g. cent/pence"
                wrapperProps={{ height: '46px', align: 'center' }}
              >
                <Icon icon="bold-help" color={theme.colors.black[3]} />
              </Tooltip>
            </Styled.FormPosition>
          )}
        </Styled.Container>
        {(indicator.params.outdata.length > 1 || displayableParams.length > 0) && (
          <Styled.Container>
            <Styled.Heading>Smoothing</Styled.Heading>
            {!indicator.params.indata.fixed && (
              <Styled.FormPosition>
                <Styled.Text>Source</Styled.Text>
                <Controller
                  control={control}
                  name="chosenIn"
                  rules={{
                    required: {
                      value: true,
                      message: 'Choose input source',
                    },
                  }}
                  render={({ field }) => (
                    <SelectInput
                      {...field}
                      defaultValue={defaultChosenIn ?? inDataOptions[0]}
                      options={inDataOptions}
                      error={errors.chosenIn as FieldError}
                    />
                  )}
                />
                <Tooltip
                  id="choose-input-help"
                  content="Input value by which indicator is calculated"
                  wrapperProps={{ height: '46px', align: 'center' }}
                >
                  <Icon icon="bold-help" color={theme.colors.black[3]} />
                </Tooltip>
              </Styled.FormPosition>
            )}
            {displayableParams.map(({ label, backendName }) => (
              <Styled.FormPosition key={backendName}>
                <Styled.Text>{label}</Styled.Text>
                <Controller
                  control={control}
                  name={`paramValues.${backendName}`}
                  rules={{
                    required: {
                      value: true,
                      message: 'This value must be set',
                    },
                    min: {
                      value: backendName.toLowerCase().includes('period') ? 1 : 0,
                      message: 'Invalid value',
                    },
                    pattern: {
                      value: backendName.toLowerCase().includes('period') ? /^[1-9]\d*$/ : /^\d+(?:[.,]\d+)?$/,
                      message: 'Invalid value',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      key={backendName}
                      name={backendName}
                      onKeyDown={(e) => ['-', '+', 'e'].includes(e.key) && e.preventDefault()}
                      onChange={(e) => {
                        field.onChange(
                          e.target.value.includes('e')
                            ? { ...e, target: { ...e.target, value: Number(e.target.value).toFixed(7) } }
                            : e,
                        );
                      }}
                      step={backendName.toLowerCase().includes('period') ? undefined : '0.0000001'}
                      min={0}
                      error={errors.paramValues && errors.paramValues[backendName]}
                    />
                  )}
                />
              </Styled.FormPosition>
            ))}
            {indicator.params.outdata.length > 1 && (
              <Styled.FormPosition>
                <Styled.Text>Returned value</Styled.Text>
                <Controller
                  control={control}
                  name="chosenOut"
                  rules={{
                    required: {
                      value: true,
                      message: 'Choose returned value',
                    },
                  }}
                  render={({ field }) => (
                    <SelectInput<OutData>
                      {...field}
                      name="chosenOut"
                      defaultValue={defaultChosenOut ?? undefined}
                      placeholder="Returned value"
                      options={outDataOptions}
                      error={errors.chosenOut as FieldError}
                    />
                  )}
                />
              </Styled.FormPosition>
            )}
          </Styled.Container>
        )}
      </Flex>
    </Modal>
  );
};

const Styled = {
  Container: styled.section<{ isTopContainer?: boolean; isSingleContainer?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: ${({ theme, isTopContainer, isSingleContainer }) =>
      isTopContainer && !isSingleContainer ? 'none' : `1px solid  ${theme.colors.elevation[4]}`};
    padding: ${({ isTopContainer, isSingleContainer }) =>
      isSingleContainer ? '16px 0 16px 0' : isTopContainer ? '16px 0 0 0' : '0 0 16px 0'};
  `,

  Heading: styled.h6`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.colors.white[1]};
  `,

  Text: styled.p`
    min-height: 46px;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
  `,

  FormPosition: styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: flex-start;

    & > * {
      width: 35%;

      &:first-child {
        width: 45%;
      }

      &:nth-child(3) {
        width: fit-content;
      }
    }
  `,
};
