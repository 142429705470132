import { FC, createContext, PropsWithChildren, useEffect, useState, useContext, SetStateAction, Dispatch } from 'react';
import styled from 'styled-components/macro';
import { onAuthStateChanged, User } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';

import { auth } from 'common/services/firebase';
import { Loader } from 'common/components';
import { setAccessToken, setRefreshToken } from 'modules/auth/utils/token';

type Loading = {
  stateChanged: boolean;
  login: boolean;
};

type AuthContextProps = {
  user: User | null;
  loading: Loading;
  setLoading: Dispatch<SetStateAction<Loading>>;
  error?: FirebaseError;
  setError: (error?: FirebaseError) => void;
};

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  loading: { stateChanged: true, login: false },
  setLoading: () => {},
  setError: () => {},
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState({ stateChanged: true, login: false });
  const [error, setError] = useState<FirebaseError>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setRefreshToken(currentUser.refreshToken);
        currentUser?.getIdToken().then((token) => setAccessToken(token));
      }
      setLoading((prev) => ({ ...prev, stateChanged: false }));
    });

    return unsubscribe;
  }, []);

  if (Object.values(loading).some((item) => item)) {
    return (
      <Styled.Wrapper>
        <Loader />
      </Styled.Wrapper>
    );
  }

  const value = {
    user,
    loading,
    setLoading,
    error,
    setError,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const Styled = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `,
};
