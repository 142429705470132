import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

import { routes } from 'routes/routes';
import { useGetStrategy } from 'modules/strategies/hooks';
import { useCopyStrategy } from 'modules/creator/hooks';

export const useNavigateStrategy = (id?: string) => {
  const navigate = useNavigate();
  const { strategyTested, strategyPending, strategy } = useGetStrategy(id);
  const copyStrategy = useCopyStrategy();
  const newId = uuidv4().split('-').join('_');

  const copyStrategyIfBacktested = (id: string) => {
    if ((!isEmpty(strategyTested) || !isEmpty(strategyPending)) && strategy) {
      copyStrategy(id, newId, strategy);

      return newId;
    }

    return id;
  };

  return () => navigate(`${routes.create}/${id ? copyStrategyIfBacktested(id) : newId}`);
};
