import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import useResizeObserver from 'use-resize-observer';

import { Flex } from 'common/components';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';
import { Status, useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { Checklist } from 'modules/creator/components/Checklist';
import { StrategyInterface } from 'modules/creator/components/StrategyInterface';
import { AutoSaveInfo } from 'modules/creator/components/AutoSaveInfo';
import { Onboarding } from 'modules/onboarding';
import { isStrategyEmpty } from 'common/utils';
import { useDocument } from 'common/hooks';

export const StrategyViewContent = () => {
  const { id } = useParams();
  const { onboardingsState, setOnboardingsState, callback } = useOnboardingContext();
  const [isOnboardingFinished] = useDocument(`tutorials/${OnboardingNames.STRATEGYCREATOR}`);
  const { strategyStatus, strategy } = useStrategyContext();
  const { ref, height } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    if (strategy) {
      if (
        onboardingsState[OnboardingNames.STRATEGYCREATOR].run &&
        ((!onboardingsState[OnboardingNames.STRATEGYCREATOR].strategyId && !isStrategyEmpty(strategy)) ||
          (onboardingsState[OnboardingNames.STRATEGYCREATOR].strategyId &&
            id !== onboardingsState[OnboardingNames.STRATEGYCREATOR].strategyId))
      ) {
        setOnboardingsState((prev) => ({
          ...prev,
          [OnboardingNames.STRATEGYCREATOR]: { ...prev[OnboardingNames.STRATEGYCREATOR], run: false },
        }));
      } else if (
        !onboardingsState[OnboardingNames.STRATEGYCREATOR].run &&
        (isOnboardingFinished === null || isOnboardingFinished === false) &&
        ((!onboardingsState[OnboardingNames.STRATEGYCREATOR].strategyId && isStrategyEmpty(strategy)) ||
          (onboardingsState[OnboardingNames.STRATEGYCREATOR].strategyId &&
            id === onboardingsState[OnboardingNames.STRATEGYCREATOR].strategyId))
      ) {
        setOnboardingsState((prev) => ({
          ...prev,
          [OnboardingNames.STRATEGYCREATOR]: { ...prev[OnboardingNames.STRATEGYCREATOR], run: true },
        }));
      }
    }
  }, [onboardingsState, strategy, setOnboardingsState, isOnboardingFinished, id]);

  if (strategyStatus === Status.LOADING) {
    return null;
  }

  return (
    <Styled.Wrapper ref={ref}>
      <Onboarding
        callback={callback}
        overlayHeight={height ? height + 120 : undefined}
        {...onboardingsState[OnboardingNames.STRATEGYCREATOR]}
        name={OnboardingNames.STRATEGYCREATOR}
      />
      <Styled.ContentWrapper>
        <Flex justify="space-between">
          <Styled.Heading>Strategy creator</Styled.Heading>
          <AutoSaveInfo />
        </Flex>
        <Styled.Content className="onboarding-main-box">
          <StrategyInterface />
        </Styled.Content>
      </Styled.ContentWrapper>
      <Checklist />
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,

  Heading: styled.h1`
    font-size: 36px;
    font-weight: 400;
    margin: 0;
  `,

  ContentWrapper: styled.div`
    width: 55vw;
    min-height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: 1440px) {
      width: 45vw;
    }
  `,

  Content: styled.div`
    width: 100%;
    height: 100%;
  `,
};
