import { RefObject } from 'react';
import styled from 'styled-components/macro';

import { PositionType } from 'modules/creator/components/ConditionsFlow/types';
import { useDraggableLayer, useLayerPosition, useZoom } from 'modules/creator/components/ConditionsFlow/hooks';
import { FlowStarterItems, RelationBox } from 'modules/creator/components/ConditionsFlow/components';

type ConditionsLayerProps = {
  position: PositionType;
  flowItemsRef: RefObject<HTMLDivElement>;
};

export const ConditionsLayer = ({ position, flowItemsRef }: ConditionsLayerProps) => {
  const [xLayerPosition, yLayerPosition] = useLayerPosition();
  const zoom = useZoom();
  const { handleDrag, handleDragStart, handleScrolling } = useDraggableLayer();

  return (
    <Styled.Container
      x={position.x + (xLayerPosition ?? 0)}
      y={position.y + (yLayerPosition ?? 0)}
      zoom={zoom}
      draggable
      onDragOver={handleDrag}
      onDragStart={handleDragStart}
      onWheel={handleScrolling}
      ref={flowItemsRef}
    >
      <FlowStarterItems />
      <RelationBox />
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div.attrs<{
    x: number;
    y: number;
    zoom: number;
  }>(({ x, y, zoom }) => {
    return {
      style: {
        left: 0,
        top: 0,
        transform: zoom
          ? `translate(${x ?? 50}px, ${y ?? 130}px) scale(${zoom})` // don't change the order of transformations, it will change the behavior
          : `translate(${x ?? 50}px, ${y ?? 130}px)`,
      },
    };
  })<{ x: number; y: number; zoom: number }>`
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndexes.modal};
    cursor: grab;
  `,
};
