import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';

import { OrderActions, TradingType } from 'types';

type OrderActionSectionContextType = {
  tradingType: TradingType;
  orderAction: OrderActions;
};

const OrderActionSectionContext = createContext<OrderActionSectionContextType>({
  tradingType: TradingType.long,
  orderAction: OrderActions.OPEN,
});

export const OrderActionSectionContextProvider = ({
  children,
  tradingType,
  orderAction,
}: PropsWithChildren<OrderActionSectionContextType>) => {
  return (
    <OrderActionSectionContext.Provider value={{ tradingType, orderAction }}>
      {children}
    </OrderActionSectionContext.Provider>
  );
};

export const useOrderActionSectionContext = () => {
  const context = useContext(OrderActionSectionContext);

  return context;
};
