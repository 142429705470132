import { OrderActions } from 'types/StrategyTypes';

export const convertFlowTypeToText = (type: OrderActions) => {
  const str = type.toLowerCase().replaceAll('_', ' ');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const convertFlowTypeToBackendText = (type: OrderActions) => {
  if (type === OrderActions.CLOSE_POSITION) {
    return 'close';
  }
  return convertFlowTypeToText(type).split(' ').join('').toLowerCase();
};
