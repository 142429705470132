import { useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

import { theme } from 'styles';
import { Flex, Heading, Layout, Text } from 'common/components';
import { useRemoveEmptyStrategies } from 'common/hooks';
import { onboardingStepsCreateStrategy } from 'data/onboarding';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';
import { Onboarding } from 'modules/onboarding';

import { StrategiesList } from './components';
import { useMergedStrategies } from './hooks';

export const StrategiesView = () => {
  const { onboardingsState, callback } = useOnboardingContext();
  const { mergedStrategies } = useMergedStrategies();
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  useRemoveEmptyStrategies();

  return (
    <Layout>
      <Onboarding
        callback={callback}
        overlayHeight={height ? height + 120 : undefined}
        {...onboardingsState[OnboardingNames.STRATEGYCREATOR]}
        name={OnboardingNames.STRATEGYCREATOR}
      />
      <div ref={ref}>
        <Flex direction="column" align="flex-start" gap={8}>
          <Heading level="h1">My strategies</Heading>
          <Text size="lg" color={theme.colors.white[1]}>
            This sections is only for you. Here you can edit your strategy or calculate it for later
          </Text>
        </Flex>
        <StrategiesList mergedStrategies={mergedStrategies} />
      </div>
    </Layout>
  );
};
