import { ButtonHTMLAttributes, CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

interface BarProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  width?: string;
  className?: string;
  style?: CSSProperties;
}

export const Bar = ({ children, width, style, ...rest }: PropsWithChildren<BarProps>) => (
  <Styled.Button style={{ width, ...style }} {...rest}>
    {children}
  </Styled.Button>
);

const Styled = {
  Button: styled.button`
    display: flex;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.black[1]};
    width: 100%;
    height: 50px;
    padding: 12px 16px;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    border-top: 1px solid ${({ theme }) => theme.colors.black[3]};
  `,
};
