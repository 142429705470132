import {
  SentenceItem,
  TypableVolumeIndicator,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const isHintVolumeInidcator = (hint: SentenceItem): hint is TypableVolumeIndicator => {
  const { type, item } = hint;
  return type === TextEngineItemTypes.Indicator && item.result_type === 'volume';
};
