import { DataSource } from 'modules/creator/types';

export const formatDataSources = (data?: Record<string, DataSource>) => {
  if (!data) {
    return null;
  }

  const markets = ['All', ...new Set(Object.values(data).map((item) => item.market))];
  const symbols = [];

  for (const [symbolKey, { market, symbol, exchanges, longName, icon }] of Object.entries(data)) {
    for (const { name, dsid } of exchanges) {
      symbols.push({
        market,
        symbolKey,
        symbol,
        exchange: name,
        longName,
        icon,
        dsid: dsid.toString(),
      });
    }
  }

  return { markets, symbols };
};
