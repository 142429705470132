import { useCallback } from 'react';

import { useNotificationsContext } from 'modules/notifications/context';
import { NotificationData, NotificationViewed } from 'modules/notifications/types';

export const useMarkNotificationsAsViewed = () => {
  const { markNotificationsAsViewed } = useNotificationsContext();

  const handleMarkNotificationsAsViewed = useCallback(
    (notifications: NotificationData[]) => {
      if (notifications.length > 0) {
        const viewed = notifications.reduce<NotificationViewed[]>((acc, item) => {
          if (!item.viewed) {
            return [...acc, { nid: item.nid, viewed: true }];
          }
          return acc;
        }, [] as NotificationViewed[]);

        if (viewed.length > 0) {
          markNotificationsAsViewed(viewed);
        }
      }
    },
    [markNotificationsAsViewed],
  );

  return { handleMarkNotificationsAsViewed };
};
