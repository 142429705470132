import { useEffect, useState } from 'react';
import _ from 'lodash';

import { useNotificationsContext } from 'modules/notifications/context';
import { NotificationData } from 'modules/notifications/types';

enum DateGroup {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  WEEK = 'Last 7 days',
  OLDER = 'Older than 7 days',
}

export const useDateGroups = () => {
  const { notifications } = useNotificationsContext();
  const [groupedNotifications, setGroupedNotifications] = useState<_.Dictionary<NotificationData[]>>();

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastSevenDays = new Date(today);
    lastSevenDays.setDate(lastSevenDays.getDate() - 7);

    const structuredNotifications = _.groupBy(notifications, (item) => {
      const itemDate = new Date(+item.date * 1000);

      if (itemDate.toDateString() === today.toDateString()) {
        return DateGroup.TODAY;
      } else if (itemDate.toDateString() === yesterday.toDateString()) {
        return DateGroup.YESTERDAY;
      } else if (itemDate > lastSevenDays) {
        return DateGroup.WEEK;
      } else {
        return DateGroup.OLDER;
      }
    });
    setGroupedNotifications(structuredNotifications);
  }, [notifications]);

  return { groupedNotifications };
};
