import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { routes } from 'routes';
import { Button, Checkbox, Flex, Heading, Input, TextLink, Text } from 'common/components';
import { LoginValues } from 'modules/auth/types';
import { useAuth } from 'modules/auth/hooks';

export const LoginForm: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<LoginValues>({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  });
  const { login, error } = useAuth();

  const handleLogin: SubmitHandler<LoginValues> = (data) => {
    login(data);
    reset();
  };

  return (
    <Flex direction="column" align="normal" width="100%" gap={40}>
      <Flex direction="column" align="normal" gap={8}>
        <Heading level="h1">Welcome Back!</Heading>
        <Text size="sm">Welcome back! Please enter your details.</Text>
      </Flex>
      <form onSubmit={handleSubmit(handleLogin)}>
        <Flex direction="column" align="normal" gap={24}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: 'You must specify a email address',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => <Input label="Email" type="email" {...field} error={errors.email} width="MAX" />}
          />
          <Flex direction="column" align="normal" gap={8}>
            <Controller
              control={control}
              name="password"
              rules={{
                required: {
                  value: true,
                  message: 'You must specify a password',
                },
              }}
              render={({ field }) => (
                <Input
                  label="Password"
                  type="password"
                  autoComplete="off"
                  {...field}
                  error={errors.password}
                  width="MAX"
                />
              )}
            />
            <TextLink to={routes.reset}>Forgot password?</TextLink>
            {error && (
              <Styled.Error>
                {error.code === 'auth/too-many-requests'
                  ? 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
                  : 'You have entered an invalid email or password'}
              </Styled.Error>
            )}
          </Flex>
          <Flex direction="column" align="normal" gap={40}>
            <Checkbox label="Remember me" {...register('remember')} />
            <Button type="submit" variant="primary">
              Log in
            </Button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
};

const Styled = {
  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
