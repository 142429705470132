import { isHintSubsentence } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import {
  Sentence,
  SentenceItem,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

export const sentenceDeepSearch = (
  sentence: Sentence,
  isResult: (hint: SentenceItem) => boolean,
): SentenceItem | null => {
  for (const hint of sentence.items) {
    if (isResult(hint)) {
      return hint;
    }

    if (isHintSubsentence(hint)) {
      const result = sentenceDeepSearch(hint.item, isResult);
      if (result) {
        return result;
      }
    }
  }

  return null;
};
