import styled from 'styled-components/macro';
import useResizeObserver from 'use-resize-observer';

import { theme } from 'styles';
import { useRemoveEmptyStrategies } from 'common/hooks';
import { Box, CircleProgressChart, EmptyStrategies, Flex, Heading, Layout, Text, TextLink } from 'common/components';
import { useAuth } from 'modules/auth/hooks';
import { StrategiesList } from 'modules/strategies/components';
import { useMergedStrategies } from 'modules/strategies/hooks';
import { Onboarding } from 'modules/onboarding';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';

export const DashboardView = () => {
  const { name } = useAuth();
  const { mergedStrategies, testedReportsCount } = useMergedStrategies();
  const { onboardingsState, callback } = useOnboardingContext();
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  useRemoveEmptyStrategies();

  return (
    <Layout className="main-window">
      <Onboarding
        callback={callback}
        overlayHeight={height ? height + 120 : undefined}
        {...onboardingsState[OnboardingNames.DASHBOARD]}
        name={OnboardingNames.DASHBOARD}
      />
      <div ref={ref}>
        <Flex direction="column" align="stretch" gap={16}>
          <Heading level="h1">Good morning, {name}!</Heading>
          <Flex align="stretch" gap={16}>
            <Box width="60%">
              <Flex direction="column" align="flex-start" gap={8}>
                <Heading level="h1">Welcome to Beta tests!</Heading>
                <Text size="sm" color={theme.colors.white[1]}>
                  You are participating in beta testing, which means that you have access to the software before its
                  official release. During this stage of development, some features may not work properly or may be
                  unstable.
                </Text>
                <Flex direction="column" align="flex-start" gap={4}>
                  <Heading level="h2">What you can expect:</Heading>
                  <Text size="sm" color={theme.colors.white[1]}>
                    Bugs: You may experience bugs or performance issues.
                  </Text>
                  <Text size="sm" color={theme.colors.white[1]}>
                    Changes: Features and interface may change before the official release.
                  </Text>
                </Flex>
                <Flex direction="column" align="flex-start" gap={4}>
                  <Heading level="h2">Your feedback is important!</Heading>
                  <Text size="sm" color={theme.colors.white[1]}>
                    Please report any problems, bugs, or suggestions using{' '}
                    <TextLink to="https://discord.gg/Vr9E8ZCGxw" target="_blank" rel="noreferrer">
                      our Discord server.
                    </TextLink>
                  </Text>
                </Flex>
                <Heading level="h2">Thanks for your help!</Heading>
              </Flex>
            </Box>
            <Box width="40%">
              <Flex justify="center" align="center" height="100%">
                <Heading level="h1">Coming soon!</Heading>
              </Flex>
            </Box>
          </Flex>
          <Box>
            <Flex justify="space-between">
              <Flex direction="column" align="flex-start" gap={8}>
                <Text size="sm">Backtests left</Text>
                <CircleProgressChart value={testedReportsCount} maxValue={100} />
              </Flex>
              <Flex direction="column" align="flex-start" gap={8}>
                <Styled.Inactive>
                  <Text size="sm">...</Text>
                  <CircleProgressChart value={10} maxValue={10} />
                </Styled.Inactive>
              </Flex>
              <Flex direction="column" align="flex-start" gap={8}>
                <Styled.Inactive>
                  <Text size="sm">....</Text>
                  <CircleProgressChart value={10} maxValue={10} />
                </Styled.Inactive>
              </Flex>
              <Flex direction="column" align="flex-start" gap={8}>
                <Styled.Inactive>
                  <Text size="sm">...</Text>
                  <CircleProgressChart value={10} maxValue={10} />
                </Styled.Inactive>
              </Flex>
            </Flex>
            <Styled.Blur>
              <Flex justify="center" align="center" height="100%">
                <Heading level="h1">Coming soon!</Heading>
              </Flex>
            </Styled.Blur>
          </Box>
          <Box>
            <StrategiesList mergedStrategies={mergedStrategies} />
            {mergedStrategies?.length === 0 && <EmptyStrategies />}
          </Box>
        </Flex>
      </div>
    </Layout>
  );
};

const Styled = {
  Inactive: styled.div`
    opacity: 0.1;
  `,

  Blur: styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 75%;
    padding: 24px;
    border-radius: 12px;
    backdrop-filter: blur(10px);
  `,
};
