import { useCallback } from 'react';

import { useWriteDocument } from 'common/hooks';
import { BotColor, OrderActions, TradingType } from 'types';

const AccessibleBotColors = Object.values(BotColor);

export const useCreateStrategy = () => {
  const writeDocument = useWriteDocument();

  const createStrategy = useCallback(
    (id: string) => {
      writeDocument(`strategies/${id}/created`, Date.now());
      writeDocument(`strategies/${id}/timestamp`, Date.now());
      writeDocument(
        `strategies/${id}/botColor`,
        AccessibleBotColors[Math.floor(Math.random() * AccessibleBotColors.length)],
      );

      for (const tradingType of Object.values(TradingType)) {
        writeDocument(`strategies/${id}/isExpanded/${tradingType}/section`, true);
        for (const orderAction of Object.values(OrderActions)) {
          writeDocument(`strategies/${id}/isExpanded/${tradingType}/${orderAction}`, true);
        }
      }
    },
    [writeDocument],
  );

  return createStrategy;
};
