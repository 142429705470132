import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import {
  BoundingBoxType,
  ConditionFlowItemType,
  NodeType,
  RelationType,
} from 'modules/creator/components/ConditionsFlow/types';
import { useFlowData, useFlowItems, useNodeActions } from 'modules/creator/components/ConditionsFlow/hooks';
import {
  createRelationString,
  formatWords,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';
import { Sentence, Word } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { getBoxTheme, shouldShowNodeArrow } from 'modules/creator/components/ConditionsFlow/utils';
import { ConditionSetterModal } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';
import { FlowArrow, ExpandButton } from 'modules/creator/components/ConditionsFlow/components/FlowItems';
import { ConditionSetterContextProvider } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';

import { ConditionNodeHover } from './components';

interface ConditionNodeProps extends NodeType {
  siblings: ConditionFlowItemType[];
  currentItem: NodeType;
  isSingleNode?: boolean;
}

export const ConditionNode = ({
  text,
  arrow,
  id,
  relationType,
  siblings,
  currentItem,
  parent,
  whichInOrder,
  isSingleNode,
}: ConditionNodeProps) => {
  const [activeNodeModal, setActiveNodeModal] = useState(false);
  const [parentElement, setParentElement] = useState<BoundingBoxType>();
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();
  const [treeItems] = useFlowItems();
  const { addNodeDown, addNodeDownWithBoundingBox, addNodeRight, addNodeRightWithBoundingBox, deleteNode } =
    useNodeActions(currentItem, siblings, parentElement);
  const [isInPreviewMode] = useFlowData('isInPreviewMode');

  useEffect(() => {
    if (treeItems) {
      setParentElement(treeItems[parent] as BoundingBoxType);
    }
  }, [treeItems, parent]);

  const handleClick = () => {
    setActiveNodeModal(true);
  };

  const onNodeModalClose = () => {
    setActiveNodeModal(false);
  };

  const onNodeSubmit = (sentence: Word[], workSentence: Sentence | null) => {
    onNodeModalClose();
    const formattedText = formatWords(sentence);
    const candles = sentence.map((item) => item.items?.candle).filter(Boolean);
    const indicators = sentence.map((item) => item.items?.indicator).filter(Boolean);
    const rel = createRelationString(sentence);

    writeStrategy(`flow/${tradingType}/${orderAction}/details/items`, {
      ...(treeItems || {}),
      [id]: {
        ...currentItem,
        text: formattedText,
        candles,
        indicators,
        rel,
        state: {
          workSentence,
          resultSentence: sentence,
        },
      },
    });
  };

  const addNode = ({ isChild }: { isChild: boolean }) => {
    if (isChild) {
      if (!relationType || relationType === 'or') {
        addNodeDown();
      } else {
        addNodeDownWithBoundingBox();
      }
    } else {
      if (!relationType || relationType === 'and') {
        addNodeRight();
      } else {
        addNodeRightWithBoundingBox();
      }
    }
  };

  const formatNodeText = () => {
    if (text) {
      return `${text.slice(0, 14)}...`;
    }

    return isSingleNode ? 'Set value' : 'Set condition';
  };

  const getClassName = () => {
    if (text) {
      return undefined;
    }

    if (isSingleNode) {
      return 'onboarding-strategy-set-stop-loss-value';
    }

    return 'onboarding-strategy-set-open-condition';
  };

  if (isInPreviewMode && !text) {
    return null;
  }

  return (
    <>
      <Styled.NodeWithArrowContainer relationType={relationType}>
        <Styled.Container onClick={handleClick} className={getClassName()}>
          <ConditionNodeHover siblings={siblings} deleteNode={deleteNode} />
          {formatNodeText()}
          {(!arrow || (arrow && relationType === 'and')) && !isInPreviewMode && !isSingleNode && (
            <ExpandButton
              className={text ? 'onboarding-strategy-or-button' : undefined}
              buttonType="node"
              layout="bottom"
              disabled={!text}
              onClick={(e) => {
                e.stopPropagation();
                addNode({ isChild: true });
              }}
            />
          )}
          {(!arrow || (arrow && relationType === 'or')) && !isInPreviewMode && !isSingleNode && (
            <ExpandButton
              className={text ? 'onboarding-strategy-and-button' : undefined}
              buttonType="node"
              layout="right"
              disabled={!text}
              onClick={(e) => {
                e.stopPropagation();
                addNode({ isChild: false });
              }}
            />
          )}
        </Styled.Container>
        {arrow && !shouldShowNodeArrow({ isInPreviewMode, siblings, whichInOrder }) && (
          <FlowArrow
            size={32}
            direction={relationType === 'and' ? 'right' : 'down'}
            info={relationType === 'and' ? 'and' : 'or'}
            boxTheme={getBoxTheme(parentElement?.depth ?? 0)}
          />
        )}
      </Styled.NodeWithArrowContainer>
      {activeNodeModal && (
        <ConditionSetterContextProvider nodeId={id}>
          <ConditionSetterModal submitFn={onNodeSubmit} closeFn={onNodeModalClose} />
        </ConditionSetterContextProvider>
      )}
    </>
  );
};

const Styled = {
  Container: styled.button`
    background-color: ${({ theme }) => theme.colors.purple[1]};
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    color: white;
    white-space: nowrap;
  `,

  NodeWithArrowContainer: styled.div<{ relationType?: RelationType }>`
    display: flex;
    gap: 10px;
    flex-direction: ${({ relationType }) => (relationType === 'and' ? 'row' : 'column')};
    align-items: center;
  `,
};
