import { useEffect, useState } from 'react';

import { sendAuthorizedApiRequest } from 'common/utils';

type UseSendAuthorizedApiRequestArgs = {
  url: string;
  data?: unknown;
  method?: string;
  headers?: Record<string, string>;
};

export const useSendAuthorizedApiRequest = <T = unknown>({
  url,
  data,
  method,
  headers,
}: UseSendAuthorizedApiRequestArgs) => {
  const [errors, setErrors] = useState<string[]>();
  const [result, setResult] = useState<T>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    sendAuthorizedApiRequest({ url, data, method, headers })
      .then((res) => {
        setResult(res);
      })
      .catch((e) => {
        console.log(e);
        setErrors([e]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, data, method, headers]);

  return { errors, result, loading };
};
