import styled, { css, keyframes } from 'styled-components/macro';

type CircleProgressChartProps = {
  value: number;
  maxValue: number;
};

export const CircleProgressChart = ({ value, maxValue }: CircleProgressChartProps) => {
  const progress = 125 * (value / maxValue);

  return (
    <Styled.Container>
      <Styled.Svg viewBox="0 0 100 50">
        <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
          <stop offset="0%" stopColor="#F3AADF" />
          <stop offset="100%" stopColor="#AAC3F3" />
        </linearGradient>
        <Styled.Track d="M10,50 A40,40 0 0,1 90,50" />
        <Styled.ProgressBar progress={progress} d="M10,50 A40,40 0 0,1 90,50" />
      </Styled.Svg>
      <Styled.ProgressText>
        {value}/{maxValue}
      </Styled.ProgressText>
    </Styled.Container>
  );
};

const dash = (progress: number) => keyframes`
    0% {
      stroke-dashoffset: 126;
    }
    100% {
      stroke-dashoffset: 126 - ${progress};
    }
`;

const Styled = {
  Container: styled.div`
    position: relative;
  `,

  Svg: styled.svg`
    margin: auto;
    display: block;
    height: 144px;
  `,

  Track: styled.path`
    fill: none;
    stroke-width: 12;
    stroke: url(#gradient);
    opacity: 0.2;
  `,

  ProgressBar: styled.path<{ progress: number }>`
    fill: none;
    stroke-width: 12;
    stroke-linecap: round;
    stroke: url(#gradient);
    stroke-dasharray: 126;
    stroke-dashoffset: ${({ progress }) => 126 - progress};
    animation: ${({ progress }) =>
      css`
        ${dash(progress)} 1s ease-out forwards;
      `};
  `,

  ProgressText: styled.span`
    font-size: 44px;
    position: absolute;
    left: 50%;
    bottom: 12px;
    z-index: 1;
    transform: translateX(-50%);
  `,
};
