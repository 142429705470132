import { useLocation, useParams } from 'react-router-dom';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';

import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';

import { OnboardingTooltip } from './components';

type OnboardingProps = {
  steps: Step[];
  name: OnboardingNames;
  stepIndex: number;
  run?: boolean;
  callback?: (data: CallBackProps, onboardingName: OnboardingNames) => void;
  overlayHeight?: number;
};

export const Onboarding = ({ steps, name, stepIndex, run, callback, overlayHeight }: OnboardingProps) => {
  const { setHelpers } = useOnboardingContext();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { setOnboardingsState, onboardingsState } = useOnboardingContext();

  const defaultStepsConfig: Partial<Step> = {
    disableBeacon: true,
    placement: 'right',
  };

  const handleCallback = (data: CallBackProps) => {
    if (id && !onboardingsState[name].strategyId) {
      setOnboardingsState((prev) => ({ ...prev, [name]: { ...prev[name], strategyId: id } }));
    }

    if (callback) {
      callback(data, name);
    }
  };

  if (!pathname.includes(steps[stepIndex].data.url)) {
    return null;
  }

  return (
    <ReactJoyride
      run={run}
      getHelpers={setHelpers}
      callback={handleCallback}
      tooltipComponent={(props) => OnboardingTooltip({ name, ...props })}
      steps={steps.map((step) => ({ ...defaultStepsConfig, ...step }))}
      showProgress
      showSkipButton
      hideBackButton
      continuous
      scrollOffset={300}
      stepIndex={stepIndex}
      spotlightPadding={0}
      disableOverlayClose
      styles={{
        options: { arrowColor: '#66CEAC', zIndex: 150 },
        overlay: { height: Math.max(overlayHeight ?? 1, window.innerHeight) },
        beacon: { display: 'none' },
      }}
      floaterProps={{ styles: { arrow: { spread: 28, length: 16 } }, placement: 'left' }}
    />
  );
};
