import { useEffect, useState } from 'react';

import { DraftStrategyType, PartialStrategy } from 'types/StrategyTypes';
import { useDocument } from 'common/hooks';

export const useGetStrategies = () => {
  const [rawStrategies] = useDocument<Record<string, DraftStrategyType>>('strategies');
  const [strategies, setStrategies] = useState<PartialStrategy[]>([]);

  useEffect(() => {
    if (rawStrategies) {
      const data = Object.entries(rawStrategies)
        .map(([key, value]) => ({ ...value, id: key }))
        .sort((a, b) => +b.timestamp - +a.timestamp);

      if (data.length > 0) {
        setStrategies(data);
      }
    }
  }, [rawStrategies]);

  return { strategies };
};
