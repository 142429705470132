import styled from 'styled-components/macro';

import { useFirstBox, useNodeForValueModal } from 'modules/creator/components/ConditionsFlow/hooks';
import { isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import { BoundingBox, ConditionNode } from 'modules/creator/components/ConditionsFlow/components';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

export const RelationBox = () => {
  const { orderAction } = useOrderActionSectionContext();
  const startBox = useFirstBox();
  const singleNode = useNodeForValueModal();
  const isValueModal = isValueConditionModal(orderAction);

  if ((!isValueModal && !startBox) || (isValueModal && !singleNode)) {
    return null;
  }

  return (
    <Styled.Container>
      {isValueModal && singleNode ? (
        <ConditionNode {...singleNode} currentItem={singleNode} siblings={[]} isSingleNode />
      ) : (
        <BoundingBox {...startBox} currentItem={startBox} isFirst />
      )}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.black[2]};
    border-radius: 3px;
  `,
};
