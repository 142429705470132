import { useEffect } from 'react';

import { PartialStrategy } from 'types';
import { isStrategyEmpty } from 'common/utils';

import { useDocument } from './useDocument';
import { useRemoveDocument } from './useRemoveDocument';

export const useRemoveEmptyStrategies = () => {
  const [strategies] = useDocument<Record<string, PartialStrategy>>(`strategies`);
  const removeStrategy = useRemoveDocument();

  useEffect(() => {
    if (strategies) {
      for (const [id, strategy] of Object.entries(strategies)) {
        if (isStrategyEmpty(strategy)) {
          removeStrategy(`strategies/${id}`);
        }
      }
    }
  }, [strategies, removeStrategy]);
};
