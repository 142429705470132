import { TextEngineItemTypes } from 'modules/creator/types';
import { SentenceItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

import { isAssignedCandlePhrase } from './isAssignedCandlePhrase';
import { isHintAbsoluteCandle } from './isHintAbsoluteCandle';
import { isHintAbsoluteIndicator } from './isHintAbsoluteIndicator';

export const isAbsoluteEntity = (hint: SentenceItem) =>
  hint.type === TextEngineItemTypes.Variable ||
  hint.type === TextEngineItemTypes.TableVariable ||
  isHintAbsoluteCandle(hint) ||
  (hint.type === TextEngineItemTypes.Subsentence &&
    !isAssignedCandlePhrase(hint.item.assignedPhrase) &&
    hint.item.items.some((hint) => isHintAbsoluteCandle(hint) || isHintAbsoluteIndicator(hint))) ||
  (hint.type === TextEngineItemTypes.Subsentence &&
    isAssignedCandlePhrase(hint.item.assignedPhrase) &&
    hint.item.items.length > 1 &&
    (isHintAbsoluteCandle(hint.item.items[1]) || isHintAbsoluteIndicator(hint.item.items[1])));
