import { useDocument } from 'common/hooks/useDocument';
import { useAuth } from 'modules/auth/hooks';
import { Rel } from 'modules/creator/components/ConditionsFlow/types';
import { convertFlowTypeToBackendText } from 'modules/creator/components/ConditionsFlow/utils';
import { OrderActions, StrategyType } from 'types';

export const usePrepareStrategyForBacktest = (strategyID: string) => {
  const [strategy] = useDocument<StrategyType>(`strategies/${strategyID}`);
  const { uid } = useAuth();

  if (strategy === undefined) {
    return null;
  }

  const getFlowConditions = () => {
    const openConditions = [];
    const closeConditions = [];
    const rest: Record<string, Rel> = {};
    for (const [tradingType, flow] of Object.entries(strategy.flow)) {
      for (const [flowType, flowData] of Object.entries(flow)) {
        if (flowData.result) {
          if (
            [OrderActions.OPEN, OrderActions.CLOSE_POSITION, OrderActions.TRAILING_STOP_LOSS_ACTIVATE].includes(
              flowType as OrderActions,
            )
          ) {
            const condition = [
              {
                seq: `${convertFlowTypeToBackendText(flowType as OrderActions)}${tradingType}`,
              },
              {
                rel: flowData.result.rel,
              },
            ];
            if (flowType === OrderActions.OPEN) {
              openConditions.push({ condition });
            } else {
              closeConditions.push({ condition });
            }
          } else if (flowData.result.rel) {
            rest[`${convertFlowTypeToBackendText(flowType as OrderActions)}${tradingType}`] = flowData.result.rel;
          }
        }
      }
    }

    return { openconditions: openConditions, closeconditions: closeConditions, ...rest };
  };

  const result = {
    strategyname: strategy.name,
    timestamp: String(strategy.timestamp / 1000),
    description: strategy.description,
    id: strategyID,
    stuser: uid,
    candles: Object.values(strategy.flow)
      .map((flow) =>
        Object.values(flow)
          .map((flowType) => flowType.result?.candles ?? [])
          .flat()
          .map((candle) => ({ ...candle, unit: candle.unit.value === 'pip' ? 'range' : candle.unit.value })),
      )
      .flat()
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)),
    indicators: Object.values(strategy.flow)
      .map((flow) =>
        Object.values(flow)
          .map((flowType) => flowType.result?.indicators ?? [])
          .flat()
          .map(({ chosenOut, ...rest }) => ({ ...rest })),
      )
      .flat()
      .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id && t.candle === value.candle)),
    ...getFlowConditions(),
  };

  return result;
};
