import { useState, MouseEvent } from 'react';
import styled from 'styled-components/macro';

import { Button, Icon, ConfirmationModal } from 'common/components';
import { isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import { ConditionFlowItemType } from 'modules/creator/components/ConditionsFlow/types';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

type ConditionNodeHoverProps = {
  siblings: ConditionFlowItemType[];
  deleteNode: () => void;
};

export const ConditionNodeHover = ({ siblings, deleteNode }: ConditionNodeHoverProps) => {
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const { orderAction } = useOrderActionSectionContext();

  const openModal = (e: MouseEvent) => {
    e.stopPropagation();
    setIsConfirmationPopupOpen(true);
  };

  return (
    <>
      {isConfirmationPopupOpen && (
        <ConfirmationModal
          setIsModalOpen={setIsConfirmationPopupOpen}
          action={deleteNode}
          name="Delete"
          content={`Do you want to delete this ${isValueConditionModal(orderAction) ? 'value' : 'condition'}?`}
        />
      )}
      <Styled.HoverWrapper>
        {siblings.length > 1 && (
          <Styled.DeleteButton noPadding onClick={openModal} className="clear-position-action-button">
            <Icon icon="trash" size={16} />
          </Styled.DeleteButton>
        )}
      </Styled.HoverWrapper>
    </>
  );
};

const Styled = {
  HoverWrapper: styled.div`
    position: absolute;
    right: 0;
    left: 0;
    top: -4px;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndexes.modalOverlay};

    &:hover {
      .clear-position-action-button {
        display: flex;
      }
    }
  `,

  DeleteButton: styled(Button)`
    display: none;
    height: fit-content;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: -28px;
    right: 0;
    padding: 6px;
  `,
};
