import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { slideIn, slideOut } from 'styles';
import { Icon } from 'common/components';
import { Notification } from 'modules/notifications/components';
import { useNotificationsContext } from 'modules/notifications/context';
import { NotificationData } from 'modules/notifications/types';

export const Toast = (props: NotificationData) => {
  const { nid } = props;
  const timerID = useRef<null | ReturnType<typeof setTimeout>>(null);
  const { removeToast, markNotificationsAsViewed } = useNotificationsContext();
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = useCallback(() => {
    setDismissed(true);
    setTimeout(() => {
      removeToast(nid);
    }, 500);
  }, [removeToast, nid]);

  const handleClose = () => {
    handleDismiss();
    markNotificationsAsViewed([
      {
        nid,
        viewed: true,
      },
    ]);
  };

  useEffect(() => {
    timerID.current = setTimeout(() => {
      handleDismiss();
    }, 7000);

    return () => {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }
    };
  }, [handleDismiss]);

  return (
    <Styled.Toast dismissed={dismissed}>
      <Styled.Close onClick={handleClose}>
        <Icon icon="close" />
      </Styled.Close>
      <Notification {...props} isDark />
    </Styled.Toast>
  );
};

const Styled = {
  Toast: styled.div<{ dismissed: boolean }>`
    position: relative;
    border: 2px solid ${({ theme }) => theme.colors.black[2]};
    border-radius: 16px;
    animation: ${({ dismissed }) => (dismissed ? slideOut : slideIn)} 0.4s ease-in-out forwards;

    & > div {
      border-radius: 16px;
    }
  `,

  Close: styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
  `,
};
