import React from 'react';
import styled from 'styled-components/macro';

import { SearchIcon } from 'assets/icons';

type SearchInputProps = {
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
};

export const SearchInput = ({ placeholder, onChange, value }: SearchInputProps) => (
  <Styled.Wrapper>
    <Styled.IconWrapper>
      <SearchIcon />
    </Styled.IconWrapper>
    <Styled.Input type="text" placeholder={placeholder} value={value} onChange={onChange} />
  </Styled.Wrapper>
);

const Styled = {
  Wrapper: styled.div`
    position: relative;
    height: 40px;
  `,

  IconWrapper: styled.div`
    position: absolute;
    transform: translateY(50%);
    top: 0;
    left: 10px;
  `,

  Input: styled.input`
    width: 100%;
    display: block;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.black[2]};
    padding: 10px 10px 10px 40px;
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.white[0]};
    font-size: 16px;

    &:focus {
      outline: none;
    }
  `,
};
