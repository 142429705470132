import { useCallback, useEffect, useState } from 'react';

import { useGetReports } from 'common/hooks';
import { getReport } from 'common/utils';
import { getLastActivities } from 'modules/strategies/utils';
import { MergedStrategy, TestedStrategy } from 'modules/strategies/types';
import { useNotificationsContext } from 'modules/notifications/context';

import { useGetStrategies } from './useGetStrategies';

export const useMergedStrategies = () => {
  const [mergedStrategies, setMergedStrategies] = useState<MergedStrategy[] | null>();
  const { strategies } = useGetStrategies();
  const { reports, pendingReports, testedReportsCount, handleGetAllReports } = useGetReports();
  const { notifications } = useNotificationsContext();

  useEffect(() => {
    handleGetAllReports();
  }, [handleGetAllReports]);

  useEffect(() => {
    if (notifications && notifications[0] && notifications[0].source === 1) {
      handleGetAllReports();
    }
  }, [handleGetAllReports, notifications]);

  const handleMergeStrategies = useCallback(async () => {
    const tempMergedStrategies = [];

    for (const strategy of strategies) {
      let reportInProgress: TestedStrategy | null = null;
      const mergedStrategy: MergedStrategy = { strategy, reports: [] };
      for (const report of reports) {
        if (report.strategyid === strategy.id) {
          mergedStrategy.reports.push(report);
          const fullReport = report.type === 'tested' ? await getReport(report.testid) : null;
          mergedStrategy.capital = fullReport?.report.summary.find(({ name }) => name === 'Initial capital')?.value;
          mergedStrategy.totalReturn = report?.summary?.find(({ name }) => name === 'Return')?.value;
          mergedStrategy.lastActivities = getLastActivities(fullReport ? 'tested' : report.type, report.timestamp);
          mergedStrategy.chart = fullReport?.summary_icon;
        }
      }
      for (const report of pendingReports) {
        if (report.strategyid === strategy.id) {
          reportInProgress = report;
        }
      }
      if (reportInProgress) {
        mergedStrategy.lastActivities = getLastActivities('pending', reportInProgress.timestamp);
      }
      mergedStrategy.reports.sort((a, b) => a.timestamp - b.timestamp);
      tempMergedStrategies.push(mergedStrategy);
    }
    setMergedStrategies(tempMergedStrategies);
  }, [reports, strategies, pendingReports]);

  useEffect(() => {
    handleMergeStrategies();
  }, [handleMergeStrategies]);

  return { mergedStrategies, reports, testedReportsCount, strategies };
};
