import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

import { AddIcon } from 'assets/icons';
import { RelationType } from 'modules/creator/components/ConditionsFlow/types';
import { Tooltip } from 'common/components';

type Layout = 'right' | 'bottom';
type ButtonType = 'box' | 'node';

interface ExpandButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  layout: Layout;
}

const layoutToConnective: Record<Layout, RelationType> = {
  right: 'and',
  bottom: 'or',
};

export const ExpandButton = ({ buttonType, layout, ...props }: ExpandButtonProps) => {
  const tooltipMessage = `Add condition “${layoutToConnective[layout]}” in ${
    buttonType === 'node' ? 'the same group' : 'next group'
  }`;
  const tooltipId = `expand-button-${layout}-${buttonType}`;

  return (
    <Styled.Container buttonType={buttonType} layout={layout} {...props}>
      {props.disabled ? (
        <>
          <AddIcon />
          {<span>{layoutToConnective[layout]}</span>}
        </>
      ) : (
        <Tooltip id={tooltipId} content={tooltipMessage} offset={7}>
          <AddIcon />
          {<span>{layoutToConnective[layout]}</span>}
        </Tooltip>
      )}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.button<ExpandButtonProps>`
    position: absolute;
    background-color: ${({ theme }) => theme.buttons.primary.default};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    padding: 4px;
    border: none;
    gap: 4px;
    bottom: ${({ layout }) => (layout === 'right' ? '50%' : '-12px')};
    right: ${({ layout }) => (layout === 'right' ? '-12px' : '50%')};
    transform: ${({ layout }) => (layout === 'right' ? 'translateY(50%)' : 'translateX(50%)')};

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }

    ${({ buttonType, layout }) =>
      buttonType === 'node' &&
      css`
        background-color: ${({ theme }) => theme.colors.purple[1]};
        bottom: ${layout === 'right' ? '50%' : '-30px'};
        right: ${layout === 'right' ? '-56px' : '50%'};
        transform: ${layout === 'right' ? 'translateY(50%)' : 'translateX(50%)'};
      `}
  `,
};
