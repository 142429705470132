import { useEffect } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import { theme } from 'styles';
import { Heading, Layout, Text } from 'common/components';

import { Notification } from './components';
import { useNotificationsContext } from './context';
import { useDateGroups, useMarkNotificationsAsViewed } from './hooks';

export const NotificationsView = () => {
  const { notifications } = useNotificationsContext();
  const { groupedNotifications } = useDateGroups();
  const { handleMarkNotificationsAsViewed } = useMarkNotificationsAsViewed();

  useEffect(() => {
    return () => handleMarkNotificationsAsViewed(notifications);
  }, [handleMarkNotificationsAsViewed, notifications]);

  return (
    <Layout>
      {groupedNotifications &&
        Object.keys(groupedNotifications).map((group) => (
          <div key={group}>
            <Heading level="h2" color={theme.colors.white[1]} margin={16}>
              {group}
            </Heading>
            <Styled.Group>
              {groupedNotifications?.[group].map((item, index) => (
                <Notification key={item.nid} {...item} />
              ))}
            </Styled.Group>
          </div>
        ))}
      {notifications.length === 0 && <Text size="lg">You have no notifications</Text>}
    </Layout>
  );
};

const Styled = {
  Group: styled.div`
    border: 2px solid ${({ theme }) => theme.colors.black[2]};
    border-radius: 8px;
    margin-bottom: 24px;

    & > div:first-child {
      border-radius: 8px 8px 0 0;
    }

    & > div:last-child {
      border-radius: 0 0 8px 8px;
    }

    & > div:only-child {
      border-radius: 8px;
    }
  `,
};
