import { useFlowItems } from 'modules/creator/components/ConditionsFlow/hooks';
import { BoundingBoxType, ConditionFlowItemType, RelationType } from 'modules/creator/components/ConditionsFlow/types';
import { normalizeFlowItems, addNodeOutter } from 'modules/creator/components/ConditionsFlow/actions';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

export const useBoxActions = (currentItem: BoundingBoxType) => {
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();
  const [flowItems] = useFlowItems();
  const path = `flow/${tradingType}/${orderAction}/details/items`;

  return {
    addNodeOutter: (relation?: RelationType) => {
      const items = flowItems ?? {};
      const updatedItems: Record<string, ConditionFlowItemType> = {};
      for (const key in items) {
        const item = items[key];
        updatedItems[key] =
          item.type === 'box'
            ? {
                ...item,
                depth: item.depth + 1,
              }
            : item;
      }
      writeStrategy(path, { ...updatedItems, ...addNodeOutter(currentItem, relation) });
    },
    deleteBoundingBox: () => {
      const baseItems = flowItems ?? {};
      const itemsToDelete: Record<string, null> = {};

      const recurrencyDeleting = (items: Record<string, ConditionFlowItemType>) => {
        for (const key in items) {
          const item = items[key];
          if (key === currentItem.id) {
            itemsToDelete[key] = null;

            if (item.type === 'box' && item.children.length > 0) {
              const newItems = Object.fromEntries(
                Object.entries(baseItems).filter(([key]) => item.children.includes(key)),
              );
              recurrencyDeleting(newItems);
            }
          }
        }
      };

      recurrencyDeleting(baseItems);

      writeStrategy(path, normalizeFlowItems({ ...baseItems, ...itemsToDelete }));
    },
  };
};
