import styled, { keyframes } from 'styled-components/macro';

enum CircleSize {
  S = '24px',
  M = '40px',
  L = '64px',
}

enum CircleInnerSize {
  S = '18px',
  M = '30px',
  L = '48px',
}

enum CircleBlockPosition {
  S = '16px',
  M = '26px',
  L = '44px',
}

type CircleLoaderProps = {
  size: 'S' | 'M' | 'L';
  background?: string;
};

export const CircleLoader = ({ background, size }: CircleLoaderProps) => (
  <Styled.Loader background={background} size={size}>
    <Styled.Circle background={background} size={size}>
      <div className="block" />
      <div className="inner" />
    </Styled.Circle>
  </Styled.Loader>
);

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Styled = {
  Loader: styled.div<CircleLoaderProps>`
    .inner,
    .block {
      position: absolute;
      width: ${({ size }) => CircleInnerSize[size]};
      height: ${({ size }) => CircleInnerSize[size]};
      transform: translate(-50%, -50%);
    }

    .inner {
      top: 50%;
      left: 50%;
      background-color: ${({ theme, background }) => background ?? theme.colors.black[0]};
      border-radius: 100%;
    }

    .block {
      top: 0px;
      right: ${({ size }) => CircleBlockPosition[size]};
      background-color: ${({ theme, background }) => background ?? theme.colors.white[0]};
      transform: rotate(-10deg);
      border-radius: 50%;
    }
  `,

  Circle: styled.div<CircleLoaderProps>`
    position: relative;
    width: ${({ size }) => CircleSize[size]};
    height: ${({ size }) => CircleSize[size]};
    overflow: hidden;
    background-color: #fff;
    background-image: -moz-linear-gradient(
      0deg,
      #fff 20%,
      ${({ theme, background }) => background ?? theme.colors.black[0]} 100%
    );
    background-image: -webkit-linear-gradient(
      0deg,
      #fff 20%,
      ${({ theme, background }) => background ?? theme.colors.black[0]} 100%
    );
    background-image: linear-gradient(
      0deg,
      #fff 20%,
      ${({ theme, background }) => background ?? theme.colors.black[0]} 100%
    );
    border-radius: 100%;
    animation: ${rotation} 1s infinite linear;
  `,
};
