import styled from 'styled-components/macro';

import { useLayerPosition, useZoom } from 'modules/creator/components/ConditionsFlow/hooks';
import { ArrowType } from 'modules/creator/components/ConditionsFlow/types';

interface FlowArrowProps extends ArrowType {
  boxTheme?: { background: string; border: string };
}

export const FlowArrow = ({ position, info, direction = 'right', boxTheme }: FlowArrowProps) => {
  const zoom = useZoom();
  const [xLayerPosition, yLayerPosition] = useLayerPosition();

  return (
    <Styled.Container
      x={(position?.x || 0) + (xLayerPosition ?? 0)}
      y={(position?.y || 0) + (yLayerPosition ?? 0)}
      zoom={zoom}
      direction={direction}
    >
      {direction === 'right' ? <Styled.StickRight size={64} /> : <Styled.StickDown size={64} />}
      {info && (
        <Styled.ArrowInfoType direction={direction} background={boxTheme?.background}>
          {info}
        </Styled.ArrowInfoType>
      )}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div<{
    x: number;
    y: number;
    zoom?: number | null;
    direction: 'right' | 'down';
  }>`
    transform-origin: top left;
    position: relative;
    display: flex;
    flex-direction: ${({ direction }) => (direction === 'down' ? 'column' : 'row')};
    width: fit-content;
    height: fit-content;
    margin-right: 2px;
  `,

  StickDown: styled.div<{ size: number }>`
    background-color: ${({ theme }) => theme.colors.black[3]};
    width: 2px;
    height: ${({ size }) => size}px;

    &::after {
      content: '';
      position: absolute;
      left: 1px;
      bottom: 0;
      width: 10px;
      height: 10px;
      border-top: 3px solid ${({ theme }) => theme.colors.black[3]};
      border-right: 3px solid ${({ theme }) => theme.colors.black[3]};
      transform: translateX(-50%) rotate(135deg);
    }
  `,

  StickRight: styled.div<{ size: number }>`
    background-color: ${({ theme }) => theme.colors.black[3]};
    height: 2px;
    width: ${({ size }) => size}px;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 1px;
      width: 10px;
      height: 10px;
      border-top: 3px solid ${({ theme }) => theme.colors.black[3]};
      border-right: 3px solid ${({ theme }) => theme.colors.black[3]};
      transform: translateY(-50%) rotate(45deg);
    }
  `,
  ArrowInfoType: styled.div<{ direction: 'right' | 'down'; background?: string }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: ${({ direction }) => (direction === 'right' ? '1px 4px' : '4px 1px')};
    color: ${({ theme }) => theme.colors.white[1]};
    z-index: ${({ theme }) => theme.zIndexes.modalOverlay};
    background-color: ${({ background, theme }) => background || theme.colors.black[2]};
  `,
};
