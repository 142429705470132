import { useEffect, useState, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';

import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { Modal, SearchInput, Text } from 'common/components';
import { useStrategy } from 'modules/creator/hooks';
import { AssetType } from 'types';
import { theme } from 'styles';

import { formatDataSources } from './utils';

export const SymbolAndMarketModal = () => {
  const [activeMarket, setActiveMarket] = useState<string>();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredList, setFilteredList] = useState<AssetType[]>([]);
  const { writeStrategy, dictionary } = useStrategyContext();
  const formattedData = useMemo(() => formatDataSources(dictionary?.data_sources), [dictionary]);
  const [chosenAsset] = useStrategy<AssetType>('asset');

  const onSave = (option: AssetType) => {
    writeStrategy('asset', option);
    writeStrategy('modals/asset', false);
  };

  useEffect(() => {
    if (!activeMarket && formattedData && formattedData.markets.length > 0) {
      setActiveMarket(formattedData.markets[0]);
    }
  }, [activeMarket, formattedData]);

  useEffect(() => {
    if (formattedData) {
      const filteredByActive = formattedData.symbols.filter(({ market }) =>
        activeMarket !== 'All' ? market === activeMarket : true,
      );
      if (searchQuery) {
        setFilteredList(
          filteredByActive.filter(
            (attrs) =>
              Object.values(attrs).filter((attr) => attr.toLowerCase().includes(searchQuery.toLowerCase())).length > 0,
          ),
        );
      } else {
        setFilteredList(filteredByActive);
      }
    }
  }, [activeMarket, formattedData, searchQuery]);

  return (
    <Modal heading="Choose symbol for your strategy" cancel={{ onClick: () => writeStrategy('modals/asset', false) }}>
      <SearchInput
        placeholder="Search for symbol"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Styled.Wrapper className="onboarding-strategy-set-symbol-modal">
        <Styled.InnerWrapper>
          <Text size="sm" color={theme.colors.white[1]}>
            Category
          </Text>
          <Styled.MenuList>
            {formattedData &&
              formattedData.markets.map((market) => (
                <Styled.MenuListItem
                  key={market}
                  isActive={activeMarket === market}
                  onClick={() => setActiveMarket(market)}
                >
                  {market}
                </Styled.MenuListItem>
              ))}
          </Styled.MenuList>
        </Styled.InnerWrapper>
        <Styled.InnerWrapper>
          <Text size="sm" color={theme.colors.white[1]}>
            Symbol
          </Text>
          <Styled.List>
            {filteredList.map((option) => (
              <Styled.ListItem
                key={`${option.symbol}${option.exchange}`}
                isActive={option.symbol === chosenAsset?.symbol && option.exchange === chosenAsset?.exchange}
                onClick={() => onSave(option)}
              >
                <Styled.SymbolWrapper>
                  <img src={`data:image/svg+xml;base64,${option.icon}`} alt={option.symbol} width={24} height={24} />
                  <span>{option.symbol}</span>
                  <span>{option.longName}</span>
                </Styled.SymbolWrapper>
                <span>{option.exchange}</span>
              </Styled.ListItem>
            ))}
          </Styled.List>
        </Styled.InnerWrapper>
      </Styled.Wrapper>
    </Modal>
  );
};

const Styled = {
  Wrapper: styled.div`
    width: 60vw;
    display: flex;
    gap: 16px;
    max-height: 300px;
  `,
  InnerWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:first-child {
      min-width: 160px;
    }

    &:last-child {
      flex: 1;
    }
  `,

  MenuList: styled.ul`
    flex: 1;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    list-style-type: none;
  `,

  MenuListItem: styled.button<{ isActive?: boolean }>`
    width: 100%;
    padding: 12px 16px;
    border: none;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.black[2] : 'inherit')};
    color: inherit;
    border-radius: 3px;
    text-align: left;
  `,

  List: styled.ul`
    padding: 0 16px 0 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    list-style-type: none;
  `,

  ListItem: styled.li<{ isActive: boolean }>`
    padding: 8px 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white[0]};
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.black[2]};
    }

    ${({ isActive, theme }) =>
      isActive &&
      css`
        background-color: ${theme.colors.black[2]};
      `}
  `,

  SymbolWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 80%;

    & > span {
      min-width: 20%;
    }
  `,
};
