import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { PartialStrategy } from 'types/StrategyTypes';
import { sendAuthorizedApiRequest } from 'common/utils';
import { useDocument } from 'common/hooks';
import { useNotificationsContext } from 'modules/notifications/context';
import { ReportTimeInfo } from 'modules/strategies/types';
import { useStrategiesContext } from 'modules/strategies/context';

export const useGetStrategy = (propsId?: string) => {
  const { optimisticallyInProgress, setOptimisticallyInProgress } = useStrategiesContext();
  const { paramsId } = useParams();
  const id = useMemo(() => propsId ?? paramsId, [propsId, paramsId]);
  const [strategy] = useDocument<PartialStrategy>(`strategies/${id}`);
  const [strategyTested, setStrategyTested] = useState<ReportTimeInfo[]>([]);
  const [strategyPending, setStrategyPending] = useState<ReportTimeInfo[]>([]);
  const { notifications } = useNotificationsContext();

  const handleGetStrategyReports = useCallback(async () => {
    const { data: tested }: { data: ReportTimeInfo[] } = await sendAuthorizedApiRequest({
      url: `${process.env.REACT_APP_API_V1}/reports/getTested/${id}`,
      method: 'GET',
    });
    const { data: pending }: { data: ReportTimeInfo[] } = await sendAuthorizedApiRequest({
      url: `${process.env.REACT_APP_API_V1}/reports/getPending/${id}`,
      method: 'GET',
    });

    setStrategyTested(tested);
    setStrategyPending(pending);
  }, [id]);

  useEffect(() => {
    handleGetStrategyReports();
  }, [handleGetStrategyReports]);

  useEffect(() => {
    if (notifications && notifications[0] && notifications[0].source === 1 && !notifications[0].viewed) {
      handleGetStrategyReports();
    }
  }, [handleGetStrategyReports, notifications]);

  useEffect(() => {
    if (optimisticallyInProgress && setOptimisticallyInProgress) {
      setStrategyPending((prev) => [...prev, optimisticallyInProgress]);
      setOptimisticallyInProgress(undefined);
    }
  }, [optimisticallyInProgress, setOptimisticallyInProgress]);

  return { strategy, strategyTested, strategyPending };
};
