import { useEffect, useMemo } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import styled from 'styled-components/macro';

import { Button, Flex, Heading, Icon, Text } from 'common/components';
import { OnboardingStep } from 'types';
import { theme } from 'styles';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';

export const OnboardingTooltip = ({
  index,
  step,
  skipProps,
  primaryProps,
  tooltipProps,
  size,
  name,
}: Omit<TooltipRenderProps, 'step'> & { step: OnboardingStep; name: OnboardingNames }) => {
  const { onboardingsState, helpers } = useOnboardingContext();
  const targetRef = document.querySelector(step.target.toString());
  const availableSpace = targetRef ? window.innerWidth - targetRef.getBoundingClientRect().right : undefined;

  const isBlocked = useMemo(
    () => onboardingsState[name].blockedSteps.find(({ stepIndex }) => stepIndex === index)?.isBlocked,
    [index, name, onboardingsState],
  );

  useEffect(() => {
    const getToNextStep = () => {
      if (index !== 3 && !isBlocked) {
        setTimeout(() => helpers?.next(), 100);
      }
    };
    const el = document.querySelector((step.data?.clickTarget ?? step.target) as string);
    el?.addEventListener('click', getToNextStep, { once: true });

    return () => el?.removeEventListener('click', getToNextStep);
  }, [step.data?.clickTarget, step.target, index, helpers, isBlocked]);

  const getContainerWidth = () => {
    if (step.data?.image) {
      return { outer: 'none', inner: '100%' };
    }
    if (availableSpace && availableSpace < 355 && step.placement === 'right') {
      return { outer: `${availableSpace - 50}px`, inner: `${availableSpace - 100}px` };
    }
    return { outer: '340px', inner: '290px' };
  };

  return (
    <Styled.Container {...tooltipProps} maxWidth={getContainerWidth().outer}>
      <Styled.CloseButton
        {...skipProps}
        variant="ghost"
        size="S"
        noPadding
        isPaddingTop={step.placement && ['bottom', 'bottom-start', 'bottom-end'].includes(step.placement)}
      >
        <Icon icon="close" color={theme.colors.white[2]} />
      </Styled.CloseButton>
      {step?.data?.image && <img src={step.data.image} style={{ maxWidth: '40vw' }} />}
      <Flex direction="column" gap={16} align="flex-start" width="100%">
        <Flex
          direction="column"
          gap={4}
          align="flex-start"
          width={getContainerWidth().inner}
          style={{ maxWidth: '40vw' }}
        >
          {step.title && <Heading level="h1">{step.title}</Heading>}
          <Text size="lg">{step.content}</Text>
        </Flex>

        <Styled.Footer>
          {step.data?.skip?.show ? (
            <Button {...skipProps} variant={step.data?.skip?.variant ?? 'ghost'}>
              {step.data?.skip?.content ?? 'Skip'}
            </Button>
          ) : (
            <span />
          )}
          <span>{index > 0 && `${index}/${size - 1}`}</span>
          {!step.data?.next?.hide && !isBlocked && (
            <Button {...primaryProps} variant="primary">
              {step.data?.next?.content ?? 'Next'}
              <Icon icon="arrow-right" />
            </Button>
          )}
        </Styled.Footer>
      </Flex>
    </Styled.Container>
  );
};

const Styled = {
  CloseButton: styled(Button)<{ isPaddingTop?: boolean }>`
    position: absolute;
    top: ${({ isPaddingTop }) => (isPaddingTop ? '28px' : '12px')};
    right: 12px;
  `,

  Container: styled.div<{ maxWidth?: string }>`
    width: fit-content;
    max-width: ${({ maxWidth }) => maxWidth};
    height: fit-content;
    background-color: ${({ theme }) => theme.colors.black[2]};
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
    border-radius: 16px;
    border: ${({ theme }) => `4px solid ${theme.colors.green[1]}`};
  `,

  Footer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
      width: 33%;
    }

    & > span {
      text-align: center;
    }
  `,
};
