import { v4 as uuidv4 } from 'uuid';

import { BoundingBoxType, ConditionFlowItemType, NodeType } from 'modules/creator/components/ConditionsFlow/types';

export const addNodeRightWithBoundingBox = (
  currentItem: NodeType,
  siblings: ConditionFlowItemType[],
  parent: BoundingBoxType,
) => {
  const { parent: parentId, whichInOrder, relationType, id } = currentItem;
  const maxOrder = Math.max(...(siblings || []).map((sibling) => sibling.whichInOrder));
  const newBoundingBoxId = uuidv4();
  const EditedCurrentItem: NodeType = {
    ...currentItem,
    relationType: 'and',
    arrow: true,
    whichInOrder: 0,
    parent: newBoundingBoxId,
  };
  const newNodeId = uuidv4();
  const newNode: NodeType = {
    type: 'node',
    parent: newBoundingBoxId,
    id: newNodeId,
    arrow: false,
    relationType: 'and',
    whichInOrder: 1,
    text: '',
    candles: '',
    indicators: '',
    rel: '',
  };
  const newBoundingBox: BoundingBoxType = {
    type: 'box',
    id: newBoundingBoxId,
    parent: parentId,
    arrow: whichInOrder < maxOrder,
    children: [newNodeId, id],
    childrenRelationType: 'and',
    selfRelationType: relationType,
    whichInOrder,
    name: '',
    depth: parent.depth + 1,
  };

  return {
    [EditedCurrentItem.id]: EditedCurrentItem,
    [newNodeId]: newNode,
    [newBoundingBoxId]: newBoundingBox,
    [parentId]: {
      ...parent,
      children: [...parent.children.filter((id) => id !== currentItem.id), newBoundingBoxId],
    },
  };
};
