import { FrameType } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { Candle } from 'modules/creator/types';

export const createResultCandleObject = (candle: Candle & { label: string }, frame: FrameType) => {
  const candleId = `${candle.type}${frame.scope}${frame.unit.symbol}`;

  const resultCandle = {
    id: candleId,
    type: candle.type,
    value: frame.scope,
    unit: frame.unit,
  };

  return resultCandle;
};
