import { ConditionFlowItemType } from 'modules/creator/components/ConditionsFlow/types';

type ShouldShowNodeArrowArgs = {
  isInPreviewMode: boolean;
  siblings: ConditionFlowItemType[];
  whichInOrder: number;
};

export const shouldShowNodeArrow = ({ isInPreviewMode, siblings, whichInOrder }: ShouldShowNodeArrowArgs) => {
  if (!isInPreviewMode) {
    return false;
  }

  const nextElement = siblings.find((sibling) => sibling.whichInOrder === whichInOrder + 1);
  if (nextElement) {
    if (nextElement.type === 'box' || nextElement.text) {
      return false;
    }

    return true;
  }

  return true;
};
