import { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { theme } from 'styles';
import { Text } from 'common/components';
import { FullStarIcon } from 'assets/icons';
import { HintableItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import {
  isHintAnyPhrase,
  isHintInArray,
  isHintIndicator,
  isSentenceFinishedAndValid,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import { TextEngineItemTypes } from 'modules/creator/types';
import { getHintableItemNameOrKey } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';
import { useConditionSetterContext } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { TextEngineListItem } from './components/TextEngineListItem';
import { useFavouriteHints } from './hooks';

enum MenuOptions {
  FAVORITES = 'Favorites',
  INDICATORS = 'Indicators',
  PHRASES = 'Phrases',
  VARIABLES = 'Variables',
}

export const TextEngineList = () => {
  const [accessibleLists, setAccessibleLists] = useState<[MenuOptions, HintableItem[]][]>([]);
  const [activeOption, setActiveOption] = useState(MenuOptions.INDICATORS);
  const [userChosenOption, setUserChosenOption] = useState<MenuOptions | null>(null);
  const [activeList, setActiveList] = useState<HintableItem[] | null>(null);
  const [sortedActiveList, setSortedActiveList] = useState<HintableItem[] | null>(null);
  const [favouriteHints] = useFavouriteHints();
  const { currentHints, allHints, sentence } = useConditionSetterContext();
  const { orderAction } = useOrderActionSectionContext();
  const isConditionSubmittable = useMemo(
    () => isSentenceFinishedAndValid(sentence, orderAction),
    [sentence, orderAction],
  );

  const baseLists: Record<MenuOptions, HintableItem[]> = useMemo(
    () => ({
      [MenuOptions.FAVORITES]: favouriteHints,
      [MenuOptions.INDICATORS]: (isConditionSubmittable ? allHints : currentHints).filter(isHintIndicator),
      [MenuOptions.PHRASES]: (isConditionSubmittable ? allHints : currentHints).filter(isHintAnyPhrase),
      [MenuOptions.VARIABLES]: (isConditionSubmittable ? allHints : currentHints).filter(({ type }) =>
        [TextEngineItemTypes.Variable, TextEngineItemTypes.TableVariable, TextEngineItemTypes.CandleVar].includes(type),
      ),
    }),
    [currentHints, favouriteHints, allHints, isConditionSubmittable],
  );

  useEffect(() => {
    setAccessibleLists(
      Object.entries(baseLists).filter(([, items]) => items.length > 0) as [MenuOptions, HintableItem[]][],
    );
  }, [baseLists]);

  useEffect(() => {
    const sortedNonFavouriteAccessibleLists = accessibleLists
      .filter(([label]) => label !== MenuOptions.FAVORITES)
      .sort(([, a], [, b]) => b.length - a.length);

    const suggestedList =
      sortedNonFavouriteAccessibleLists.length > 0
        ? sortedNonFavouriteAccessibleLists[0]
        : ([MenuOptions.FAVORITES, favouriteHints] as const);

    if (userChosenOption) {
      const found = accessibleLists.find(([label]) => label === userChosenOption);
      if (found) {
        setActiveOption(found[0]);
        setActiveList(found[1]);
      } else {
        setActiveOption(suggestedList[0]);
        setActiveList(suggestedList[1]);
      }
    } else {
      setActiveOption(suggestedList[0]);
      setActiveList(suggestedList[1]);
    }
  }, [userChosenOption, accessibleLists, favouriteHints]);

  useEffect(() => {
    if (activeList) {
      let sorted: HintableItem[] = [];

      if (activeOption === MenuOptions.FAVORITES) {
        const accessibleItems = activeList.filter((item) => isHintInArray(item, currentHints));
        const nonAccessibleItems = activeList.filter((item) => !isHintInArray(item, currentHints));
        sorted = [...accessibleItems, ...nonAccessibleItems];
      } else {
        const favourtieItems = activeList.filter((item) => isHintInArray(item, favouriteHints));
        const nonFavourtieItems = activeList.filter((item) => !isHintInArray(item, favouriteHints));
        sorted = [...favourtieItems, ...nonFavourtieItems];
      }
      setSortedActiveList(sorted);
    }
  }, [activeList, activeOption, currentHints, favouriteHints]);

  return (
    <Styled.Wrapper disabled={isConditionSubmittable}>
      <Styled.InnerWrapper>
        <Text size="sm" color={theme.colors.white[1]}>
          Category
        </Text>
        <Styled.Menu>
          {accessibleLists.map(([option]) => (
            <li key={option}>
              <Styled.MenuItem
                isActive={option === activeOption}
                onClick={() => !isConditionSubmittable && setUserChosenOption(option)}
              >
                {option === MenuOptions.FAVORITES && <FullStarIcon />}
                {option}
              </Styled.MenuItem>
            </li>
          ))}
        </Styled.Menu>
      </Styled.InnerWrapper>
      <Styled.InnerWrapper>
        <Text size="sm" color={theme.colors.white[1]}>
          Element
        </Text>
        <Styled.List>
          {sortedActiveList &&
            sortedActiveList.map((item) => (
              <TextEngineListItem
                key={getHintableItemNameOrKey(item)}
                item={item}
                favouriteItems={favouriteHints}
                disabled={isConditionSubmittable}
              />
            ))}
        </Styled.List>
      </Styled.InnerWrapper>
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div<{ disabled: boolean }>`
    width: 100%;
    height: 250px;
    padding: 10px;
    display: flex;
    gap: 16px;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.4;

        & button {
          cursor: default;
        }
      `}
  `,

  Menu: styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style-type: none;
  `,

  MenuItem: styled.button<{ isActive?: boolean }>`
    width: 100%;
    padding: 12px 16px;
    border: none;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.black[2] : 'inherit')};
    color: inherit;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 16px;
  `,

  List: styled.ul`
    padding: 0 16px 0 0;
    margin: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    list-style-type: none;
  `,
  InnerWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:first-child {
      width: 20%;
    }

    &:last-child {
      flex: 1;
    }
  `,
};
