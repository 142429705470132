import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';

import { routes } from 'routes';
import { Button, Flex, Heading, Input, TextLink, Text } from 'common/components';
import { ResetPasswordValues } from 'modules/auth/types';
import { useAuth } from 'modules/auth/hooks';

export const ResetPasswordForm: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetPasswordValues>({
    defaultValues: {
      email: '',
    },
  });
  const { resetPassword, error } = useAuth();

  const handleResetPassword: SubmitHandler<ResetPasswordValues> = (data) => {
    resetPassword(data.email);
    reset();
  };

  return (
    <Flex direction="column" align="normal" width="100%" gap={40}>
      <Flex direction="column" align="normal" gap={8}>
        <Heading level="h1">Do you want to create a new password?</Heading>
        <Text size="sm">No worries, we’ll send you reset instructions.</Text>
      </Flex>
      <form onSubmit={handleSubmit(handleResetPassword)}>
        <Flex direction="column" align="normal" gap={24}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: 'You must specify a email address',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => <Input label="Email" type="email" {...field} error={errors.email} width="MAX" />}
          />
          <Button type="submit" variant="primary">
            Send email
          </Button>
          {error && <Styled.Error>{error.message}</Styled.Error>}
          <TextLink to={routes.login}>Go back to log in</TextLink>
        </Flex>
      </form>
    </Flex>
  );
};

const Styled = {
  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
