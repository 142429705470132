import { RefObject } from 'react';

import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { useZoom } from './useZoom';

export const useCenterFlowItems = () => {
  const zoom = useZoom();
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();
  const safeCurrentZoom = zoom ?? 1;

  return (container: RefObject<HTMLDivElement>, flowItemsRef: RefObject<HTMLDivElement>) => {
    if (!container || !container.current || !flowItemsRef || !flowItemsRef.current) return;

    const containerSize = {
      width: container.current.getBoundingClientRect().width,
      height: container.current.getBoundingClientRect().height,
    };
    const flowItemsBoxSize = {
      width: flowItemsRef.current.getBoundingClientRect().width,
      height: flowItemsRef.current.getBoundingClientRect().height,
    };

    const basePositionOfTopLeftCorner = [-50, -130]; // for zoom = 1

    const newZoom =
      (flowItemsBoxSize.width / containerSize.width > flowItemsBoxSize.height / containerSize.height
        ? (0.86 * containerSize.width) / flowItemsBoxSize.width
        : (0.86 * containerSize.height) / flowItemsBoxSize.height) * safeCurrentZoom;

    const topLeftCornerPosition = [
      basePositionOfTopLeftCorner[0] - ((1 - newZoom) * flowItemsBoxSize.width) / safeCurrentZoom / 2,
      basePositionOfTopLeftCorner[1] - ((1 - newZoom) * flowItemsBoxSize.height) / safeCurrentZoom / 2,
    ];

    // flowItemsBoxSize/safeCurrentZoom is size of the flowItemsBox for zoom = 1
    // flowItemsBoxSize*newZoom/safeCurrentZoom is size of flowItemsBox after scaling here

    writeStrategy(`flow/${tradingType}/${orderAction}/zoom`, newZoom);
    writeStrategy(`flow/${tradingType}/${orderAction}/position`, {
      x: topLeftCornerPosition[0] + (containerSize.width - (flowItemsBoxSize.width * newZoom) / safeCurrentZoom) / 2,
      y: topLeftCornerPosition[1] + (containerSize.height - (flowItemsBoxSize.height * newZoom) / safeCurrentZoom) / 2,
    });
  };
};
