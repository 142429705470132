import { useSendAuthorizedApiRequest } from 'common/hooks';

type ColumnsRequestData = {
  columns: string[];
  maxtime: number;
};

export const useGetReportColumns = (id: string) => {
  const { result, loading, errors } = useSendAuthorizedApiRequest<{ data: ColumnsRequestData }>({
    url: `${process.env.REACT_APP_API_V1}/analytics/getColumns/${id}`,
  });

  return { columns: result?.data.columns, maxtime: result?.data.maxtime, loading, errors };
};
