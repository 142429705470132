import { useStrategy } from 'modules/creator/hooks';
import { AssetType, OrderActions, StrategyType } from 'types/StrategyTypes';

export const useStrategyComponentsStatus = () => {
  const [asset] = useStrategy<AssetType>('asset');
  const [flow] = useStrategy<StrategyType['flow']>('flow');
  const [name] = useStrategy<string>('name');

  return {
    name: !!name,
    symbol: !!asset,
    open: !!(flow && Object.values(flow).some((tradingType) => tradingType[OrderActions.OPEN]?.result)),
    stopLoss: !!(flow && Object.values(flow).some((tradingType) => tradingType[OrderActions.STOP_LOSS]?.result)),
    trailingStopLoss: !!(
      flow &&
      Object.values(flow).some(
        (tradingType) =>
          tradingType[OrderActions.TRAILING_STOP_LOSS]?.result &&
          tradingType[OrderActions.TRAILING_STOP_LOSS_ACTIVATE]?.result,
      )
    ),
    close: !!(flow && Object.values(flow).some((tradingType) => tradingType[OrderActions.CLOSE_POSITION]?.result)),
  };
};
