import { theme } from 'styles';

import { TextEngineMessageType } from './types';

export const START: TextEngineMessageType = {
  title: 'Start writing...',
  subTitle: "We'll show you when something is wrong.",
  icon: 'pen',
  color: theme.colors.purple[4],
};

export const DEFAULT: TextEngineMessageType = {
  title: 'Everything looks good, keep writing.',
  subTitle: "We'll show you when something is wrong.",
  icon: 'pen',
  color: theme.colors.purple[4],
};

export const SUCCESS: TextEngineMessageType = {
  title: 'Your condition looks good.',
  subTitle: 'You can write more here or go to the next step.',
  icon: 'bold-check',
  color: theme.colors.paleGreen,
};

export const ERROR: TextEngineMessageType = {
  title: 'There’s a mistake in your condition.',
  subTitle: 'We mark the text we don’t understand in red.',
  icon: 'error',
  color: theme.colors.red[2],
};

export const TextEngineMessages = {
  START,
  DEFAULT,
  SUCCESS,
  ERROR,
};
