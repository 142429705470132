import { ReactNode, PropsWithChildren, CSSProperties } from 'react';
import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';
import styled from 'styled-components/macro';

import { theme } from 'styles';

type WrapperProps = {
  width?: string;
  height?: string;
  align?: 'flex-start' | 'flex-end' | 'center' | 'normal' | 'stretch';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'normal' | 'stretch' | 'space-between';
};

interface TooltipProps extends Omit<ITooltip, 'content'> {
  id: string; // must be unique for different tooltips
  content: ReactNode;
  wrapperProps?: WrapperProps;
  border?: string;
}

export const Tooltip = ({
  id,
  content,
  children,
  wrapperProps = {},
  border,
  place = 'right',
  noArrow = true,
  offset = 2,
  ...rest
}: PropsWithChildren<TooltipProps>) => {
  const style: CSSProperties = {
    borderRadius: '8px',
    padding: '8px 12px',
    backgroundColor: theme.colors.elevation[2],
    fontSize: '14px',
    fontWeight: 400,
    color: theme.colors.white[0],
    maxWidth: 215,
    whiteSpace: 'normal',
    zIndex: theme.zIndexes.tooltip,
    textAlign: 'left',
  };

  return (
    <>
      <Styled.Wrapper id={id} wrapperProps={wrapperProps}>
        {children}
      </Styled.Wrapper>
      <ReactTooltip
        anchorSelect={`#${id}`}
        place={place}
        {...rest}
        noArrow
        offset={offset}
        style={style}
        border={border}
      >
        {content}
      </ReactTooltip>
    </>
  );
};

const Styled = {
  Wrapper: styled.div<Required<Pick<TooltipProps, 'wrapperProps'>>>`
    width: ${({ wrapperProps: { width } }) => width ?? 'fit-content'};
    height: ${({ wrapperProps: { height } }) => height ?? 'fit-content'};
    display: flex;
    align-items: ${({ wrapperProps: { align } }) => align ?? 'normal'};
    justify-content: ${({ wrapperProps: { justify } }) => justify ?? 'flex-start'};
  `,
};
