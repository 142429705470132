import {
  SentenceItem,
  TypableRelativeIndicator,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const isHintRelativeIndicator = (hint: SentenceItem): hint is TypableRelativeIndicator => {
  const { type, item } = hint;
  return type === TextEngineItemTypes.Indicator && item.result_type === 'relative';
};
