import { useEffect } from 'react';

import { Layout } from 'common/components';
import { StrategyCreatorDataProvider } from 'modules/creator/context/StrategyCreatorContext';
import { StrategyViewContent } from 'modules/creator/components/StrategyViewContent';

export const StrategyView = () => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && !e.shiftKey && e.code === 'KeyZ') {
      // dispatch(ActionCreators.undo());
    } else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.code === 'KeyZ') {
      // dispatch(ActionCreators.redo());
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <StrategyCreatorDataProvider>
      <Layout className="main-window">
        <StrategyViewContent />
      </Layout>
    </StrategyCreatorDataProvider>
  );
};
