import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { isEmpty } from 'lodash';

import { theme } from 'styles';
import { BotColor, PartialStrategy, BotType } from 'types';
import { Button, CircleLoader, Divider, Flex, Heading, Icon, Modal, Text, Tooltip } from 'common/components';
import { formatDate, getStrategyBotImage } from 'common/utils';
import { useNavigateStrategy } from 'routes/hooks';
import { useAuth } from 'modules/auth/hooks';
import { Activity } from 'modules/strategies/types';

type StrategyInfoProps = {
  strategy: PartialStrategy;
  botType: BotType;
  lastActivities: Activity[];
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const StrategyInfo = ({ strategy, botType, lastActivities, setIsModalOpen }: StrategyInfoProps) => {
  const [isCopyAlertVisible, setIsCopyAlertVisible] = useState(false);
  const { id } = useParams();
  const { name } = useAuth();
  const navigateStrategy = useNavigateStrategy(id);

  const handleEditStrategy = () => {
    if (isEmpty(lastActivities)) {
      return navigateStrategy();
    }

    setIsCopyAlertVisible(true);
  };

  return (
    <Flex direction="column" align="stretch" gap={16}>
      {isCopyAlertVisible && (
        <Modal
          heading="Copy and edit"
          cancel={{ onClick: () => setIsCopyAlertVisible(false), variant: 'outlined' }}
          submit={{ content: 'Copy and edit', onClick: navigateStrategy }}
          width="550px"
        >
          <Flex direction="column" style={{ padding: '0 16px' }} gap={8}>
            <Styled.Heading>Do you want edit on next version of this strategy?</Styled.Heading>
            <Text size="lg" color={theme.colors.white[1]}>
              This strategy already has a backtest, if you edit it, your backtest will be inaccurate. For this reason,
              we will copy for you to edit to your heart&apos;s desire.
            </Text>
          </Flex>
        </Modal>
      )}
      <Flex align="flex-start" justify="space-between" gap={32}>
        <Flex align="flex-start" gap={32}>
          <img
            src={getStrategyBotImage(strategy?.botColor ? strategy.botColor : BotColor.WHITE, botType)}
            alt="bot"
            width={200}
            height={200}
          />
          <Flex direction="column" align="flex-start" gap={16}>
            {(lastActivities && lastActivities.length) > 0 &&
              lastActivities.map(({ name: activityName, status, startTimestamp, tooltip }) => (
                <Tooltip
                  id={`${(name ?? '').replace(/\s+/g, '')}-${activityName}`}
                  content={tooltip}
                  key={activityName}
                  place="top"
                >
                  <Styled.ActivityTag status={status}>
                    {status === 'done' ? <Icon icon="circle-check" /> : <CircleLoader size="S" />}
                    {activityName}
                  </Styled.ActivityTag>
                </Tooltip>
              ))}
            <Heading level="h1">{strategy.name || 'Untitled'}</Heading>
          </Flex>
        </Flex>
        <Flex gap={8}>
          <Button
            variant="primary"
            onClick={() => setIsModalOpen(true)}
            disabled={!strategy.isCompleted}
            className="onboarding-strategy-backtest-button"
          >
            <Icon icon="bar-line-chart" />
            Backtest
          </Button>
          <Button onClick={handleEditStrategy} variant="outlined">
            {isEmpty(lastActivities) ? 'Edit' : 'Copy & edit'}
          </Button>
        </Flex>
      </Flex>
      <Flex gap={16}>
        <Flex direction="column" align="flex-start" gap={8} width="50%">
          <Heading level="h1">Description</Heading>
          <Divider />
          <Styled.Description size="lg">{strategy?.description || '-'}</Styled.Description>
        </Flex>
        <Flex direction="column" gap={8} width="12.5%">
          {strategy?.asset ? (
            <>
              <img
                src={`data:image/svg+xml;base64,${strategy?.asset.icon}`}
                alt={strategy?.asset.symbol}
                width={24}
                height={24}
              />
              <Text size="lg">{strategy?.asset.symbol}</Text>
              <Text size="sm" color={theme.colors.white[1]}>
                {strategy?.asset.market}
              </Text>
            </>
          ) : (
            <>
              <Styled.IconPlaceholder />
              <Text size="lg">-</Text>
              <Text size="sm" color={theme.colors.white[1]}>
                Symbol
              </Text>
            </>
          )}
        </Flex>
        <Flex direction="column" gap={8} width="12.5%">
          <Icon icon="user" size={24} />
          <Text size="lg" align="center">
            {name || '-'}
          </Text>
          <Text size="sm" align="center" color={theme.colors.white[1]}>
            Autor
          </Text>
        </Flex>
        <Flex direction="column" gap={8} width="12.5%">
          <Icon icon="clock" size={24} />
          <Text size="lg" align="center">
            {strategy?.created ? formatDate(strategy.created) : '-'}
          </Text>
          <Text size="sm" align="center" color={theme.colors.white[1]}>
            Created
          </Text>
        </Flex>
        <Flex direction="column" gap={8} width="12.5%">
          <Icon icon="clock" size={24} />
          <Text size="lg" align="center">
            {strategy?.timestamp ? formatDate(strategy.timestamp) : '-'}
          </Text>
          <Text size="sm" align="center" color={theme.colors.white[1]}>
            Last action
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Styled = {
  ActivityTag: styled.div<{ status: Activity['status'] }>`
    display: flex;
    width: fit-content;
    border: 2px solid ${({ theme, status }) => (status === 'progress' ? theme.colors.yellow[1] : theme.colors.green[1])};
    padding: 4px 8px 4px 6px;
    border-radius: 90px;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  `,

  IconPlaceholder: styled.div`
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.elevation[4]};
    border-radius: 50%;
  `,

  Heading: styled.h1`
    margin: 0;
    font-size: 36px;
    font-weight: 400;
    text-align: unset;
  `,

  Description: styled(Text)`
    white-space: pre-wrap;
  `,
};
