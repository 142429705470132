import { useCallback } from 'react';
import { ref, remove } from 'firebase/database';

import { db } from 'common/services/firebase';
import { useAuth } from 'modules/auth/hooks';

export const useRemoveDocument = () => {
  const { uid } = useAuth();
  const remover = useCallback((path: string) => remove(ref(db, `${uid}/${path}`)), [uid]);

  return remover;
};
