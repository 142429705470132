import {
  Bar,
  DatafeedConfiguration,
  ErrorCallback,
  ResolutionString,
  SymbolType,
  LibrarySymbolInfo,
  PeriodParams,
  SubscribeBarsCallback,
  HistoryCallback,
  OnReadyCallback,
} from 'charting_library';

import { AssetType } from 'types';
import { sendAuthorizedApiRequest } from 'common/utils';
import {
  extractAndConvertValues,
  convertValueBack,
  generateSymbol,
  mergeComplexDataArrays,
} from 'modules/strategies/utils';

const supportedCandles = ['Heiken_Ashi', 'Japan', 'Range'];

type UrlParameters = {
  limit: number;
  start_time: number;
  end_time: number;
};

type DatafeedProps = {
  id: string;
  columns: string[];
  maxtime: number;
  asset: AssetType;
};

export const datafeed = ({ id, columns, maxtime, asset }: DatafeedProps) => {
  const { symbolShort, symbolFull } = generateSymbol({ exchange: asset.exchange, symbol: asset.symbolKey });
  const configurationData: DatafeedConfiguration = {
    supported_resolutions: extractAndConvertValues(supportedCandles, columns) as ResolutionString[],
    symbols_types: [{ name: 'crypto', value: 'crypto' }],
  };

  return {
    onReady: async (callback: OnReadyCallback) => {
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: async () => {
      // do not remove! datafeed API needs all methods even if not used
    },

    resolveSymbol: async (
      symbolName: SymbolType,
      onSymbolResolvedCallback: (symbolInfo: LibrarySymbolInfo) => void,
    ) => {
      const symbolInfo: LibrarySymbolInfo = {
        ticker: symbolFull,
        name: symbolShort,
        description: symbolShort,
        type: asset.market,
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: asset.exchange,
        listed_exchange: asset.exchange,
        format: 'price',
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        visible_plots_set: 'ohlc',
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: 'streaming',
      };
      setTimeout(() => onSymbolResolvedCallback(symbolInfo));
    },

    getBars: async (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      periodParams: PeriodParams,
      onHistoryCallback: HistoryCallback,
      onErrorCallback: ErrorCallback,
    ) => {
      const { from, to } = periodParams;
      const parsedResolution = convertValueBack(resolution);
      const startTime = maxtime < to ? maxtime - (to - from) : from;
      const endTime = maxtime < to ? maxtime : to;
      const urlParameters: UrlParameters = {
        limit: 30000,
        start_time: startTime,
        end_time: endTime,
      };
      const query = Object.keys(urlParameters)
        .map((name) => `${name}=${encodeURIComponent(urlParameters[name as keyof UrlParameters])}`)
        .join('&');

      try {
        const data = await sendAuthorizedApiRequest({
          url: `${process.env.REACT_APP_API_V1}/analytics/getData/${id}?columns=Japan${parsedResolution}-close&columns=Japan${parsedResolution}-low&columns=Japan${parsedResolution}-high&columns=Japan${parsedResolution}-open&columns=Japan${parsedResolution}-volume&${query}`,
          method: 'GET',
        });
        const mergeData = Object.values(mergeComplexDataArrays(data));

        if ((data.Response && data.Response === 'Error') || mergeData.length === 0) {
          // "noData" should be set if there is no data in the requested period
          onHistoryCallback([], { noData: true });
          return;
        }

        let bars: Bar[] = [];

        mergeData.forEach((bar) => {
          if (bar.time >= startTime && bar.time < endTime) {
            bars = [
              ...bars,
              {
                time: bar.time * 1000,
                low: bar.low,
                high: bar.high,
                open: bar.open,
                close: bar.close,
                volume: bar.volume,
              },
            ];
          }
        });

        onHistoryCallback(bars, { noData: false });
      } catch (error) {
        if (error instanceof Error) {
          onErrorCallback(error.message);
        } else {
          console.log('Unknown error occurred');
        }
      }
    },

    subscribeBars: (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      onTick: SubscribeBarsCallback,
      subscriberUID: string,
      onResetCacheNeededCallback: () => void,
    ) => {
      // do not remove! datafeed API needs all methods even if not used
    },

    unsubscribeBars: (subscriberUID: string) => {
      // do not remove! datafeed API needs all methods even if not used
    },
  };
};
