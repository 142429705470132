type DataSource = {
  name: string;
  data: { time: number; value: number }[];
};

type MergedData = { [time: number]: { time: number; [key: string]: number } };

export const mergeComplexDataArrays = (dataSources: DataSource[]) =>
  dataSources.reduce<MergedData>((acc, source) => {
    const key: string = source.name.split('.').at(-1) ?? '';

    source.data.forEach(({ time, value }) => {
      if (!acc[time]) {
        acc[time] = { time };
      }
      acc[time][key] = value;
    });

    return acc;
  }, {});
