import { useNavigate } from 'react-router';

import { routes } from 'routes';
import { Flex, Heading, Text, Button } from 'common/components';

import { AuthLayout } from './components';

export const ConfirmPasswordSuccessView = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <Flex direction="column" align="normal" gap={40}>
        <Flex direction="column" align="normal" gap={8}>
          <Heading level="h1">All done!</Heading>
          <Text size="sm">Your password has been reset. Now you can log in with a new password!</Text>
        </Flex>
        <Button onClick={() => navigate(routes.login)} variant="primary">
          Log in
        </Button>
      </Flex>
    </AuthLayout>
  );
};
