import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { ACTIONS, CallBackProps, LIFECYCLE, Step, StoreHelpers } from 'react-joyride';
import { useNavigate } from 'react-router-dom';

import {
  onboardingDashboardNextBlockers,
  onboardingStepsCreateStrategy,
  onboardingStepsDashboard,
  onboardingStrategyCreatorNextBlockers,
} from 'data/onboarding';
import { useDocument, useWriteDocument } from 'common/hooks';

export enum OnboardingNames {
  DASHBOARD = 'dashboard',
  STRATEGYCREATOR = 'strategyCreator',
}

type BlockedSteps = {
  stepIndex: number;
  isBlocked?: boolean;
};

export type OnboardingState = {
  run: boolean;
  stepIndex: number;
  strategyId: string;
  blockedSteps: BlockedSteps[];
  name?: OnboardingNames;
  steps: Step[];
};

type OnboardingContextType = {
  onboardingsState: Record<OnboardingNames, OnboardingState>;
  setOnboardingsState: Dispatch<SetStateAction<Record<OnboardingNames, OnboardingState>>>;
  callback: (data: CallBackProps, onboardingName: OnboardingNames) => void;
  helpers?: StoreHelpers;
  setHelpers: Dispatch<SetStateAction<StoreHelpers | undefined>>;
};

const defaultState: Record<OnboardingNames, OnboardingState> = {
  [OnboardingNames.DASHBOARD]: {
    name: OnboardingNames.DASHBOARD,
    run: false,
    steps: onboardingStepsDashboard,
    stepIndex: 0,
    strategyId: '',
    blockedSteps: onboardingDashboardNextBlockers.map((index) => ({ stepIndex: index, isBlocked: true })),
  },
  [OnboardingNames.STRATEGYCREATOR]: {
    name: OnboardingNames.STRATEGYCREATOR,
    steps: onboardingStepsCreateStrategy,
    run: false,
    stepIndex: 0,
    strategyId: '',
    blockedSteps: onboardingStrategyCreatorNextBlockers.map((index) => ({ stepIndex: index, isBlocked: true })),
  },
};

export const OnboardingContext = createContext<OnboardingContextType>({
  onboardingsState: defaultState,
  setOnboardingsState: () => {},
  setHelpers: () => {},
  callback: () => {},
});

export const OnboardingContextProvider = ({ children }: PropsWithChildren) => {
  const [onboardingsState, setOnboardingsState] = useState<Record<OnboardingNames, OnboardingState>>(defaultState);
  const [helpers, setHelpers] = useState<StoreHelpers>();
  const navigate = useNavigate();
  const writeDocument = useWriteDocument();
  const [accomplishedOnboardings] = useDocument<Record<OnboardingNames, boolean>>(`tutorials`);

  useEffect(() => {
    if (accomplishedOnboardings !== undefined) {
      for (const name of Object.values(OnboardingNames)) {
        const accomplished = Object.keys(
          Object.fromEntries(
            Object.entries(accomplishedOnboardings ?? {}).filter(([, isAccomplished]) => isAccomplished),
          ),
        ).includes(name);
        setOnboardingsState((prev) => ({
          ...prev,
          [name]: { ...prev[name as OnboardingNames], run: accomplished ? false : true },
        }));
      }
    }
  }, [accomplishedOnboardings]);

  const callback = (data: CallBackProps, onboardingName: OnboardingNames) => {
    const onboardingState = onboardingsState[onboardingName];
    const blockChanger = (target: HTMLInputElement) => {
      setOnboardingsState((prev) => ({
        ...prev,
        [onboardingName]: {
          ...prev[onboardingName],
          blockedSteps: [
            ...prev[onboardingName].blockedSteps.map((item) => {
              if (item.stepIndex === prev[onboardingName].stepIndex) {
                return {
                  stepIndex: item.stepIndex,
                  isBlocked: !target.value,
                };
              } else {
                return item;
              }
            }),
          ],
        },
      }));
    };

    if (data.action === ACTIONS.UPDATE && data.lifecycle === LIFECYCLE.TOOLTIP) {
      const elementsToDisable = document.querySelectorAll<HTMLButtonElement>(
        `${data.step.target.toString()} .onboarding-disabled`,
      );
      const elementsMustHaveTruthyValue = document.querySelectorAll<HTMLInputElement>(
        `${data.step.target.toString()} .onboarding-not-falsy-value`,
      );
      elementsMustHaveTruthyValue.forEach((element) => {
        if (element.value) {
          blockChanger(element);
        }
      });
      elementsToDisable.forEach((element) => (element.disabled = true));
      if (elementsMustHaveTruthyValue.length > 0) {
        document.addEventListener('input', (e) => blockChanger(e.target as HTMLInputElement));
      }
    }

    if (
      data.action === ACTIONS.SKIP ||
      data.action === ACTIONS.CLOSE ||
      data.action === ACTIONS.RESET ||
      (data.type === 'step:after' && onboardingState.steps.length - 1 <= onboardingState.stepIndex)
    ) {
      writeDocument(`tutorials/${onboardingName}`, true);
      return setOnboardingsState((prev) => ({ ...prev, [onboardingName]: { ...prev[onboardingName], run: false } }));
    }

    if (data.action === ACTIONS.NEXT && data.lifecycle === LIFECYCLE.COMPLETE) {
      const blocker = onboardingState.blockedSteps.find(({ stepIndex }) => stepIndex === onboardingState.stepIndex);
      if (blocker && blocker.isBlocked && !data.step.data.next.hide) {
        return;
      }

      setOnboardingsState((prev) => ({
        ...prev,
        [onboardingName]: { ...prev[onboardingName], stepIndex: prev[onboardingName].stepIndex + 1 },
      }));
      if (data.step.data?.navigate) {
        navigate(`${data.step.data.navigate}${data.step.data.useParam ? `/${onboardingState.strategyId}` : ''}`);
      }
    }
  };

  return (
    <OnboardingContext.Provider value={{ onboardingsState, setOnboardingsState, callback, helpers, setHelpers }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);

  return context;
};
