import { useNavigate } from 'react-router';
import styled from 'styled-components/macro';

import CheckoutSuccess1 from 'assets/images/checkout-success-1.jpg';
import { routes } from 'routes';
import { Layout, Heading, Text, Flex, Button } from 'common/components';

export const CheckoutSuccessView = () => {
  const navigate = useNavigate();

  return (
    <>
      <Layout alignContent="center" noSidebar>
        <Flex height="100%" align="center" justify="center">
          <Flex direction="column" align="flex-start" gap={32}>
            <img src={CheckoutSuccess1} />
            <Flex direction="column" align="flex-start" gap={24}>
              <Heading level="h1">Thank you, that&apos;s a prefect coffee for a night!</Heading>
              <Text size="lg">Your donation will speed up our development.</Text>
            </Flex>
          </Flex>
        </Flex>
      </Layout>
      <Styled.Footer>
        <Button onClick={() => navigate(routes.dashboard)}>Home</Button>
      </Styled.Footer>
    </>
  );
};

const Styled = {
  Footer: styled.div`
    position: absolute;
    bottom: 32px;
    right: 48px;
  `,
};
