import styled from 'styled-components/macro';

import { formatDate } from 'common/utils';
import { Flex, Heading, Icon, Text } from 'common/components';
import { BasicColors, theme } from 'styles/theme';
import {
  NotificationData,
  NotificationColors,
  NotificationKind,
  NotificationStrategyKind,
  NotificationSource,
} from 'modules/notifications/types';

export const Notification = ({ title, text, date, source, type, viewed, isDark }: NotificationData) => {
  const colorKey = NotificationColors[type] as keyof BasicColors;

  const handleGetIcon = () => {
    if (source === NotificationSource.BACKTESTER) {
      if (type === NotificationKind.DEFAULT) {
        return 'bot';
      } else {
        return `bot-${NotificationStrategyKind[type]}`;
      }
    }
    return 'bell';
  };

  return (
    <Styled.Box type={type} viewed={viewed} isDark={isDark}>
      <Icon
        icon={handleGetIcon()}
        color={type === NotificationKind.DEFAULT ? theme.colors.white[0] : theme.colors[colorKey]}
      />
      <Flex direction="column" align="flex-start" gap={8}>
        <div>
          <Styled.TopText>
            {source === NotificationSource.BACKTESTER ? 'Backtester' : 'System'} • {formatDate(date * 1000)}
          </Styled.TopText>
          <Heading level="h2">{title}</Heading>
        </div>
        <Text size="sm" color={theme.colors.white[1]}>
          {text}
        </Text>
      </Flex>
    </Styled.Box>
  );
};

const Styled = {
  Box: styled.div<Pick<NotificationData, 'type' | 'viewed' | 'isDark'>>`
    display: flex;
    background-color: ${({ theme, viewed, isDark }) => theme.colors.black[viewed || isDark ? 1 : 2]};
    border: 0 solid ${({ theme, type }) => theme.colors[NotificationColors[type] as keyof BasicColors]};
    border-left-width: ${({ type }) => (type !== NotificationKind.DEFAULT ? '16px' : 0)};
    padding: 16px;
    gap: 16px;
  `,

  TopText: styled.div`
    color: ${({ theme }) => theme.colors.white[1]};
    font-size: 14px;
    font-weight: 500;
    margin: 2px 0 4px;
    letter-spacing: 0.1px;
  `,
};
