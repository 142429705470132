import { InputHTMLAttributes } from 'react';
import styled from 'styled-components/macro';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

export const Radio = ({ name, label, ...props }: RadioProps) => {
  return (
    <Styled.Container htmlFor={name}>
      {label}
      <Styled.Input id={name} type="radio" {...props} />
      <Styled.Checkmark className="checkmark" />
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.label`
    display: flex;
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    user-select: none;

    input:checked ~ .checkmark {
      border-color: ${({ theme }) => theme.buttons.primary.default};
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    &:hover input ~ .checkmark {
      border-color: ${({ theme }) => theme.buttons.default.hover};
    }

    &:hover input:checked ~ .checkmark {
      border-color: ${({ theme }) => theme.buttons.primary.hover};
    }

    &:hover input:checked ~ .checkmark:after {
      background-color: ${({ theme }) => theme.buttons.primary.hover};
    }
  `,

  Checkmark: styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid ${({ theme }) => theme.buttons.default.default};
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 3px;
      top: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.buttons.primary.default};
    }
  `,

  Input: styled.input`
    position: absolute;
    appearance: none;
    cursor: pointer;
    margin: 0;
    opacity: 0;
    height: 0;
    width: 0;
  `,
};
