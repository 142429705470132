import { useState, MouseEvent } from 'react';
import styled, { css } from 'styled-components/macro';

import { FullStarIcon, GoldFullStarIcon, StarIcon } from 'assets/icons';
import { TextEngineItemTypes } from 'modules/creator/types';
import { HintableItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { getHintableItemNameOrKey } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';
import { useWriteDocument } from 'common/hooks';
import { useConditionSetterContext } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';

type TextEngineListItemProps = {
  item: HintableItem;
  favouriteItems: HintableItem[];
  disabled: boolean;
};

export const TextEngineListItem = ({ item, favouriteItems, disabled }: TextEngineListItemProps) => {
  const [hoveredStar, setHoveredStar] = useState<null | string>(null);
  const writeDocument = useWriteDocument();
  const { currentHints, handleHintClick } = useConditionSetterContext();

  const name = getHintableItemNameOrKey(item);
  const isSelectable = !!currentHints.find((hint) => getHintableItemNameOrKey(hint) === getHintableItemNameOrKey(item));
  const isFavourite = favouriteItems.find((element) => getHintableItemNameOrKey(element) === name);

  const addToFavourites = (e: MouseEvent) => {
    if (disabled) {
      return;
    }

    e.stopPropagation();
    writeDocument(
      'favouriteIndicators',
      isFavourite
        ? {
            ...favouriteItems.filter((indicator) => getHintableItemNameOrKey(indicator) !== name),
          }
        : [...favouriteItems, item],
    );
  };

  return (
    <Styled.ListItem
      isSelectable={isSelectable}
      key={name}
      onClick={() => isSelectable && !disabled && handleHintClick(item)}
    >
      <span>{name}</span>
      {item.type === TextEngineItemTypes.Indicator && <span>{item.item.fullname}</span>}
      <Styled.Button
        onClick={addToFavourites}
        onMouseEnter={() => !disabled && setHoveredStar(name)}
        onMouseLeave={() => !disabled && setHoveredStar(null)}
        side="left"
      >
        {!isFavourite && (hoveredStar === name ? <FullStarIcon /> : <StarIcon />)}
        {isFavourite && <GoldFullStarIcon />}
      </Styled.Button>
      {/* Help button hidden for now, until internal company wikipedia is ready */}
      {/*
      <Styled.Button side="right">
        <Tooltip id="text-engine-list-read-more" content="Read more">
          <Icon icon="bold-help" />
        </Tooltip>
      </Styled.Button>
    */}
    </Styled.ListItem>
  );
};

const Styled = {
  ListItem: styled.li<{ isSelectable: boolean }>`
    padding: 10px 60px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white[0]};
    display: flex;
    position: relative;
    opacity: ${({ isSelectable }) => (!isSelectable ? '0.5' : '1')};

    ${({ isSelectable }) =>
      isSelectable &&
      css`
        &:hover {
          background-color: #363842;
          cursor: pointer;
        }
      `}

    & > span {
      min-width: 20%;
    }
  `,

  Button: styled.button<{ side: 'left' | 'right' }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: inherit;

    ${({ side }) =>
      side === 'right'
        ? css`
            right: 10px;
          `
        : css`
            left: 10px;
          `}
  `,
};
