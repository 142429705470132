import { useEffect } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'styles';
import { routes } from 'routes';
import { Heading, Text, TextLink } from 'common/components';
import { useNotificationsContext } from 'modules/notifications/context';
import { useMarkNotificationsAsViewed } from 'modules/notifications/hooks';
import { Notification } from 'modules/notifications/components';

export const NotificationsDropdown = () => {
  const { dropdownNotifications } = useNotificationsContext();
  const { handleMarkNotificationsAsViewed } = useMarkNotificationsAsViewed();

  useEffect(() => {
    return () => handleMarkNotificationsAsViewed(dropdownNotifications);
  }, [handleMarkNotificationsAsViewed, dropdownNotifications]);

  return (
    <Styled.Wrapper>
      <Styled.Top>
        <Heading level="h1" color={theme.colors.white[1]}>
          Notifications
        </Heading>
        <TextLink to={routes.notifications} color={theme.colors.white[1]}>
          see all notifications
        </TextLink>
      </Styled.Top>
      {dropdownNotifications.length > 0 ? (
        <Styled.NotificationsContainer>
          {dropdownNotifications.map((item) => (
            <Notification key={item.nid} {...item} />
          ))}
        </Styled.NotificationsContainer>
      ) : (
        <Styled.TextContainer>
          <Text size="sm" color={theme.colors.white[1]}>
            You have no notifications
          </Text>
        </Styled.TextContainer>
      )}
    </Styled.Wrapper>
  );
};

const Styled = {
  Top: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 16px;
  `,

  Wrapper: styled.div`
    position: fixed;
    right: 48px;
    top: 88px;
    z-index: ${({ theme }) => theme.zIndexes.dropdown};
    max-height: calc(100vh - 100px);
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.black[2]};
    background-color: ${({ theme }) => theme.colors.black[1]};
  `,

  TextContainer: styled.div`
    padding: 0 16px 16px;
    text-align: center;
  `,

  NotificationsContainer: styled.div`
    & > div:last-child {
      border-radius: 0 0 8px 8px;
    }
  `,
};
