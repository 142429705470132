import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/macro';
import { widget, IChartingLibraryWidget, ChartingLibraryWidgetOptions, ResolutionString } from 'charting_library';

import { AssetType } from 'types';
import { sendAuthorizedApiRequest } from 'common/utils';
import { generateSymbol } from 'modules/strategies/utils';

import { datafeed } from './services';

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];
  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
  theme: ChartingLibraryWidgetOptions['theme'];
}

interface ReportChartProps {
  id: string;
  columns: string[];
  maxtime: number;
  asset: AssetType;
}

export const ReportChart = ({ id, columns, maxtime, asset }: ReportChartProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { symbolShort } = generateSymbol({ exchange: asset.exchange, symbol: asset.symbolKey });
  const datafeedMemo = useMemo(() => datafeed({ id, columns, maxtime, asset }), [id, columns, maxtime, asset]);

  const defaultProps: Omit<ChartContainerProps, 'container'> = useMemo(() => {
    return {
      symbol: symbolShort,
      interval: '60' as ResolutionString,
      libraryPath: '/charting_library/',
      fullscreen: false,
      theme: 'dark',
      autosize: true,
      // BEWARE: no trailing slash is expected in feed URL
      datafeedUrl: 'https://demo_feed.tradingview.com',
      chartsStorageUrl: 'https://saveload.tradingview.com',
      chartsStorageApiVersion: '1.1',
      clientId: 'tradingview.com',
      userId: 'public_user_id',
      studiesOverrides: {},
    };
  }, [symbolShort]);

  useEffect(() => {
    let tvWidget: IChartingLibraryWidget;

    if (chartContainerRef.current) {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: defaultProps.symbol as string,
        datafeed: datafeedMemo,
        interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
        container: chartContainerRef.current,
        library_path: defaultProps.libraryPath as string,
        fullscreen: defaultProps.fullscreen,
        theme: defaultProps.theme,
        autosize: defaultProps.autosize,
        disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'left_toolbar'],
        locale: 'en',
      };

      tvWidget = new widget(widgetOptions);
      tvWidget.onChartReady(() => {
        const chart = tvWidget.chart();

        chart.onDataLoaded().subscribe(
          null,
          async () => {
            const data = await sendAuthorizedApiRequest({
              url: `${process.env.REACT_APP_API_V1}/analytics/getData/${id}?columns=position`,
              method: 'GET',
            });

            data[0].data.forEach((item: any) => {
              chart.createMultipointShape(
                [
                  { time: item.opentime, price: item.openprice },
                  { time: item.closetime, price: item.closeprice },
                ],
                {
                  shape: 'trend_line',
                  zOrder: 'top',
                  text: item.openprice,
                  overrides: {
                    showPriceRange: true,
                    linecolor: 'white',
                    linewidth: 2,
                    linestyle: 1,
                    text: item.openprice,
                  },
                  lock: true,
                  disableSelection: true,
                },
              );
              chart.createShape(
                { time: item.opentime, price: item.openprice },
                {
                  shape: 'icon',
                  overrides: {
                    size: 15,
                    color: item.side === 'short' ? '#ff03d1' : '#00FF00',
                    icon: item.side === 'short' ? 0xf0d7 : 0xf0d8,
                  },
                  lock: true,
                  disableSelection: true,
                },
              );
              chart.createShape(
                { time: item.closetime, price: item.closeprice },
                {
                  shape: 'icon',
                  overrides: {
                    size: 15,
                    color: item.side === 'short' ? '#00FF00' : '#ff03d1',
                    icon: item.side === 'short' ? 0xf0d8 : 0xf0d7,
                  },
                  lock: true,
                  disableSelection: true,
                },
              );
            });
          },
          true,
        );
      });
    }

    return () => {
      tvWidget.remove();
    };
  }, [chartContainerRef, datafeedMemo, defaultProps, id]);

  return <Styled.Container ref={chartContainerRef} />;
};

const Styled = {
  Container: styled.div`
    height: 80vh;
  `,
};
