import { useParams } from 'react-router-dom';

import { useDocument } from 'common/hooks';

export const useStrategy = <T = any>(path: string) => {
  const { id } = useParams();
  const [data, setData] = useDocument<T>(`strategies/${id}/${path}`);

  return [data, setData] as const;
};
