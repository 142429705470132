import { RefObject } from 'react';
import styled from 'styled-components/macro';

import { Tooltip } from 'common/components';
import { FocusControlIcon, MinusIcon, PlusControlIcon } from 'assets/icons';
import { useStrategyContext } from 'modules/creator/context';
import { useCenterFlowItems, useZoom } from 'modules/creator/components/ConditionsFlow/hooks';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

type FlowControlsProps = {
  container: RefObject<HTMLDivElement>;
  flowItemsRef: RefObject<HTMLDivElement>;
};

export const FlowControls = ({ container, flowItemsRef }: FlowControlsProps) => {
  const zoom = useZoom();
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();
  const centerFlowItems = useCenterFlowItems();

  const handleZoomIn = () => {
    writeStrategy(`flow/${tradingType}/${orderAction}/zoom`, zoom ? zoom + 0.2 : 1.2);
  };

  const handleZoomOut = () => {
    writeStrategy(`flow/${tradingType}/${orderAction}/zoom`, zoom ? zoom - 0.2 : 0.8);
  };

  return (
    <Styled.Container>
      <Tooltip id="flow-controls-zoom-in" content="Zoom in">
        <Styled.ControlButton onClick={handleZoomIn}>
          <PlusControlIcon />
        </Styled.ControlButton>
      </Tooltip>
      <Tooltip id="flow-controls-zoom-out" content="Zoom out">
        <Styled.ControlButton onClick={handleZoomOut}>
          <MinusIcon />
        </Styled.ControlButton>
      </Tooltip>
      <Tooltip id="flow-controls-fit" content="Fit">
        <Styled.ControlButton>
          <FocusControlIcon onClick={() => centerFlowItems(container, flowItemsRef)} />
        </Styled.ControlButton>
      </Tooltip>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    position: absolute;
    bottom: 30px;
    left: 30px;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    background-color: ${({ theme }) => theme.colors.black[2]};
    border-radius: 5px;
    z-index: ${({ theme }) => theme.zIndexes.modal};
  `,

  ControlButton: styled.button`
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: none;
    background-color: inherit;
    color: inherit;
  `,
};
