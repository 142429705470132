import { useSendAuthorizedApiRequest } from 'common/hooks';
import { DataSource } from 'modules/creator/types/dictionarySchema';

export const useGetDataSources = () => {
  const { result, loading, errors } = useSendAuthorizedApiRequest<{ data_sources: Record<string, DataSource> }>({
    url: `${process.env.REACT_APP_API_V2}/dictionary/data_sources`,
  });

  return { dataSources: result?.data_sources, loading, errors };
};
