import { FC, createContext, PropsWithChildren, useState, useContext, Dispatch, SetStateAction } from 'react';

import { ReportTimeInfo } from 'modules/strategies/types';

type StrategiesContextProps = {
  optimisticallyInProgress?: ReportTimeInfo;
  setOptimisticallyInProgress?: Dispatch<SetStateAction<ReportTimeInfo | undefined>>;
};

export const StrategiesContext = createContext<StrategiesContextProps>({});

export const useStrategiesContext = () => {
  return useContext(StrategiesContext);
};

export const StrategiesContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [optimisticallyInProgress, setOptimisticallyInProgress] = useState<ReportTimeInfo>();

  return (
    <StrategiesContext.Provider value={{ optimisticallyInProgress, setOptimisticallyInProgress }}>
      {children}
    </StrategiesContext.Provider>
  );
};
