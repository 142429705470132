import {
  isSentenceFinishedAndValid,
  isHintRelativeIndicator,
  isSentenceFinished,
  isHintAnyPhrase,
  isHintValue,
  isHintVolumeInidcator,
  isHintVolumeCandle,
  areHintsEqual,
  isHintIndicator,
  isAbsoluteEntity,
  isVolumeEntity,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import { Phrase, ValuePhrase, TextEngineItemTypes } from 'modules/creator/types';
import {
  HintableItem,
  Sentence,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { OrderActions } from 'types';
import { isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import {
  searchForPhrasesWhereItemOnPosition,
  splitHintsByType,
  filterHintsForValueSentenceStart,
  sentenceDeepSearch,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/utils';

export const getNextHints = (allHints: HintableItem[], sentence: Sentence | null, flowType: OrderActions) => {
  const {
    allValuePhrases,
    allIndicators,
    allVariables,
    allValues,
    allAnyPhrases,
    allCandleVariables,
    allTableVariables,
  } = splitHintsByType(allHints);

  let sentenceNextIndex = sentence?.items.length || 0;
  const sentenceCurrentIndex = sentenceNextIndex - 1;

  if (!sentence || sentenceNextIndex === 0) {
    return [
      ...(isValueConditionModal(flowType)
        ? filterHintsForValueSentenceStart(allHints, allValuePhrases)
        : [...allIndicators, ...allVariables, ...allTableVariables, ...allValues, ...allCandleVariables]),
      ...searchForPhrasesWhereItemOnPosition({
        phrases: isValueConditionModal(flowType) ? allValuePhrases : allAnyPhrases,
        itemTypes: [TextEngineItemTypes.Own],
      }),
    ];
  }

  const indicatorInSentence = sentenceDeepSearch(sentence, isHintIndicator);
  const absoluteEntitesInSentence = sentenceDeepSearch(sentence, isAbsoluteEntity);
  const volumeEntitiesInSentence = sentenceDeepSearch(sentence, isVolumeEntity);

  if (isSentenceFinishedAndValid(sentence, flowType)) {
    return [];
  }
  const lastSentenceItem = sentence.items[sentenceCurrentIndex];
  if (lastSentenceItem.type === TextEngineItemTypes.Subsentence && !isSentenceFinished(lastSentenceItem.item)) {
    sentence = lastSentenceItem.item;
    sentenceNextIndex = lastSentenceItem.item.items.length;
  }
  const allowedTypes: TextEngineItemTypes[] = [];
  let availablePhrases = [...(isValueConditionModal(flowType) ? allValuePhrases : allAnyPhrases)];
  if (sentence.assignedPhrase) {
    allowedTypes.push(...sentence.assignedPhrase.item.structure[sentenceNextIndex]);
  } else {
    const itemsTypesAndPositions = sentence.items.map(({ type }, index) => ({ type, position: index }));

    for (const { type, position } of itemsTypesAndPositions) {
      availablePhrases = searchForPhrasesWhereItemOnPosition({
        phrases: availablePhrases,
        itemTypes: [type],
        position,
      });
    }

    const availableTypesWithDuplicates = [];
    for (const phrase of availablePhrases) {
      availableTypesWithDuplicates.push(...phrase.item.structure[sentenceNextIndex]);
    }

    allowedTypes.push(...new Set(availableTypesWithDuplicates));
  }

  let resultHints = allHints.filter((hint) => {
    if (isHintAnyPhrase(hint) && allowedTypes.includes(TextEngineItemTypes.Own)) {
      return availablePhrases
        .map((typablePhrase) => typablePhrase.item.key)
        .includes((hint.item as Phrase | ValuePhrase).key);
    }
    return allowedTypes.includes(hint.type);
  });

  if (indicatorInSentence) {
    if (isHintRelativeIndicator(indicatorInSentence)) {
      resultHints = resultHints.filter(
        (hint) =>
          isHintAnyPhrase(hint) ||
          isHintValue(hint) ||
          (hint.type == TextEngineItemTypes.Indicator && hint.item.name === indicatorInSentence.item.name),
      );
    } else if (isHintVolumeInidcator(indicatorInSentence)) {
      resultHints = resultHints.filter(
        (hint) =>
          isHintAnyPhrase(hint) ||
          isHintValue(hint) ||
          isHintVolumeCandle(hint) ||
          (hint.type == TextEngineItemTypes.Indicator && hint.item.name === indicatorInSentence.item.name),
      );
    } else {
      resultHints = resultHints.filter(
        (hint) => !(isHintRelativeIndicator(hint) || isHintVolumeInidcator(hint) || isHintVolumeCandle(hint)),
      );
    }
  }

  if (absoluteEntitesInSentence) {
    resultHints = resultHints.filter(
      (hint) => !(isHintRelativeIndicator(hint) || isHintVolumeInidcator(hint) || isHintVolumeCandle(hint)),
    );
  } else if (volumeEntitiesInSentence) {
    resultHints = resultHints.filter(
      (hint) => isHintValue(hint) || isHintVolumeInidcator(hint) || isHintVolumeCandle(hint) || isHintAnyPhrase(hint),
    );
  }

  if (allowedTypes.includes(TextEngineItemTypes.Subsentence)) {
    const availableTypesWithDuplicates = [];
    const newSentenceAllowedTypes: TextEngineItemTypes[] = [];
    for (const phrase of allValuePhrases) {
      availableTypesWithDuplicates.push(...phrase.item.structure[0]);
    }

    newSentenceAllowedTypes.push(...new Set(availableTypesWithDuplicates));
    const allowedPhrasesOnStartPosition = searchForPhrasesWhereItemOnPosition({
      phrases: allValuePhrases,
      itemTypes: [TextEngineItemTypes.Own],
    });
    const newSentenceHints = [...allowedPhrasesOnStartPosition].map((hint) => ({ ...hint }));

    const newResultHints = resultHints.filter(
      (resultHint) => !newSentenceHints.find((newHint) => areHintsEqual(newHint, resultHint)),
    );
    resultHints = [...newResultHints, ...newSentenceHints];
  }

  return resultHints;
};
