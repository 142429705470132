import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Input, Text } from 'common/components';
import { BacktestForm } from 'modules/strategies/components/StartBacktestModal/types';

export const StartBacktestCapitalForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BacktestForm>();
  return (
    <Flex gap={8} align="flex-end">
      <Controller
        control={control}
        name="capital"
        rules={{
          required: {
            value: true,
            message: 'You must specify capital',
          },
          min: {
            value: 0,
            message: 'Value must be more than 0',
          },
        }}
        render={({ field }) => (
          <Input
            type="number"
            label="Initial Capital"
            {...field}
            min={0}
            error={errors.capital}
            postInputContent={<Text size="lg">USD</Text>}
            required
            formNoValidate
          />
        )}
      />
    </Flex>
  );
};
