import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { FirebaseError } from '@firebase/util';
import styled from 'styled-components/macro';
import Select, { StylesConfig } from 'react-select';

import { InputWidth, SelectOption } from 'types';
import { theme } from 'styles';
import { Flex, Icon } from 'common/components';
import { getInputLabelColor } from 'common/utils';

type SelectProps<T = any> = {
  options: SelectOption<T>[];
  name: string;
  onChange?: (selectedOption: SelectOption<T> | null) => void;
  label?: string;
  error?: FieldError | FirebaseError;
  disabled?: boolean;
  labelVariant?: 'default' | 'horizontal';
  errorVariant?: 'default' | 'horizontal';
  width?: keyof typeof InputWidth;
  defaultValue?: SelectOption<T>;
  placeholder?: string;
};

export const SelectInput = <T,>({
  options,
  name,
  onChange,
  label,
  labelVariant = 'default',
  errorVariant = 'default',
  error,
  width = 'M',
  disabled,
  defaultValue,
  placeholder,
}: SelectProps<T>) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const customDropdownIndicator = isDropdownOpen ? <Icon icon="arrow-up" /> : <Icon icon="arrow-down" />;

  const selectStyles: StylesConfig<SelectOption<T>, false> = {
    control: (provided, state) => ({
      ...provided,
      width: InputWidth[width],
      border: error ? `2px solid ${theme.colors.red[2]}` : 'none',
      padding: '9px 12px',
      backgroundColor: theme.colors.black[2],
      borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
      boxShadow: 'none',
      fontSize: '16px',
      opacity: state.isDisabled ? 0.5 : 1,
      cursor: 'pointer',
      ':hover': {
        border: error ? `2px solid ${theme.colors.red[2]}` : 'none',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.white[0],
      marginLeft: 0,
    }),
    input: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.elevation[3],
      margin: 0,
      borderRadius: '0 0 4px 4px',
      zIndex: 1000,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `${theme.buttons.default.hover} !important` : theme.colors.elevation[3],
      ':hover': {
        backgroundColor: theme.colors.black[2],
      },
      ':active': {
        backgroundColor: theme.buttons.default.pressed,
      },
      color: theme.colors.white[0],
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.colors.white[0],
      whiteSpace: 'nowrap',
    }),
  };

  const getDefaultValue = () => {
    if (defaultValue) {
      return defaultValue;
    }

    return placeholder ? undefined : options[0];
  };

  return (
    <Flex
      direction={labelVariant === 'horizontal' ? 'row' : 'column'}
      align="flex-start"
      gap={labelVariant === 'horizontal' ? 10 : 8}
    >
      {label && (
        <Styled.Label htmlFor={name} labelVariant={labelVariant} isError={!!error}>
          {label}
        </Styled.Label>
      )}
      <Flex
        direction={errorVariant === 'horizontal' ? 'row' : 'column'}
        align={errorVariant === 'horizontal' ? 'center' : 'flex-start'}
        gap={errorVariant === 'horizontal' ? 10 : 8}
      >
        <Select
          onChange={onChange}
          defaultValue={getDefaultValue()}
          options={options}
          styles={selectStyles}
          components={{
            DropdownIndicator: () => customDropdownIndicator,
          }}
          onMenuOpen={() => setIsDropdownOpen(true)}
          onMenuClose={() => setIsDropdownOpen(false)}
          isDisabled={disabled}
          placeholder={placeholder}
        />
        {error && <Styled.Error>{error.message}</Styled.Error>}
      </Flex>
    </Flex>
  );
};

const Styled = {
  Label: styled.label<Pick<SelectProps, 'labelVariant'> & { isError: boolean }>`
    font-size: ${({ labelVariant }) => (labelVariant === 'horizontal' ? 'inherit' : '14px')};
    color: ${({ isError, labelVariant }) => getInputLabelColor(isError, labelVariant)};
    white-space: ${({ labelVariant }) => (labelVariant === 'horizontal' ? 'nowrap' : 'normal')};
    padding: ${({ labelVariant }) => (labelVariant === 'horizontal' ? '12px 0' : 0)};
  `,

  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
