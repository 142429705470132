import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { theme } from 'styles';
import { routes } from 'routes';
import { formatDate } from 'common/utils';
import { CircleLoader, Flex, Heading, Icon, Tooltip } from 'common/components';
import ArrowLoss from 'assets/images/arrow-circle-down.svg';
import ArrowProfit from 'assets/images/arrow-circle-up.svg';
import { Activity, TestedStrategy } from 'modules/strategies/types';
import { getStrategyBotStatus } from 'modules/strategies/utils';
import { BotColor, PartialStrategy } from 'types';
import { getStrategyBotImage } from 'common/utils/getStrategyBotImage';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';

type StrategyListItemProps = {
  reports: TestedStrategy[];
  capital?: number;
  totalReturn?: number;
  lastActivities?: Activity[];
  chart?: string;
} & PartialStrategy;

export const StrategyListItem = ({
  id,
  timestamp,
  name,
  asset,
  botColor,
  capital,
  totalReturn,
  lastActivities,
  chart,
}: StrategyListItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onboardingsState, helpers } = useOnboardingContext();

  const handleClick = () => {
    if (
      onboardingsState[OnboardingNames.STRATEGYCREATOR].run &&
      pathname.includes(
        onboardingsState[OnboardingNames.STRATEGYCREATOR].steps[
          onboardingsState[OnboardingNames.STRATEGYCREATOR].stepIndex
        ].data.url,
      )
    ) {
      setTimeout(() => helpers?.next(), 100);
    } else {
      navigate(`${routes.strategies}/${id}`);
    }
  };

  return (
    <Styled.ItemWrapper
      onClick={handleClick}
      className={id === onboardingsState.strategyCreator.strategyId ? 'onboarding-strategy' : undefined}
    >
      <Flex gap={24}>
        <img
          src={getStrategyBotImage(botColor ?? BotColor.WHITE, getStrategyBotStatus(lastActivities, totalReturn))}
          alt="bot"
          width={64}
          height={64}
        />
        <Flex direction="column" align="flex-start">
          <Heading level="h1">{name ?? 'Untitled'}</Heading>
          <Flex gap={4}>
            <Icon icon="bot-backtest" color={theme.colors.white[0]} />
            <Heading level="h3" color={theme.colors.white[1]}>
              {formatDate(timestamp)}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        {asset ? (
          <Flex gap={8}>
            <img src={`data:image/svg+xml;base64,${asset.icon}`} alt={asset.symbol} width={24} height={24} />
            {asset.symbol}
          </Flex>
        ) : (
          '-'
        )}
      </Flex>
      <Flex>{capital ? <Heading level="h3">${capital}</Heading> : '-'}</Flex>
      <Flex gap={2}>
        {totalReturn ? (
          <>
            <img src={totalReturn > 0 ? ArrowProfit : ArrowLoss} alt="arrow" />
            <Heading level="h3">
              {totalReturn > 0 && '+'}
              {totalReturn}%
            </Heading>
          </>
        ) : (
          '-'
        )}
      </Flex>
      <Flex>
        {chart ? <img src={`data:image/png+xml;base64,${chart}`} alt={asset?.symbol} width={100} height={40} /> : '-'}
      </Flex>
      <Flex direction="column" align="flex-start">
        {lastActivities && lastActivities.length > 0
          ? lastActivities.map(({ name: activityName, status, startTimestamp, tooltip }) => (
              <Tooltip
                id={`${(name ?? '').replace(/\s+/g, '')}-${activityName}`}
                content={tooltip}
                key={activityName}
                place="top"
              >
                <Styled.ActivityTag
                  status={status}
                  className={
                    id === onboardingsState.strategyCreator.strategyId ? 'onboarding-strategy-activity' : undefined
                  }
                >
                  {status === 'done' ? <Icon icon="circle-check" /> : <CircleLoader size="S" />}
                  {activityName}
                </Styled.ActivityTag>
              </Tooltip>
            ))
          : '-'}
      </Flex>
    </Styled.ItemWrapper>
  );
};

const Styled = {
  ItemWrapper: styled.li`
    padding: 16px 32px 16px 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black[2]};
    cursor: pointer;

    & > * {
      width: 15%;

      &:first-child {
        width: 25%;
      }
    }
  `,

  ActivityTag: styled.div<{ status: Activity['status'] }>`
    display: flex;
    width: fit-content;
    border: 2px solid ${({ theme, status }) => (status === 'progress' ? theme.colors.yellow[1] : theme.colors.green[1])};
    padding: 4px 8px 4px 6px;
    border-radius: 90px;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  `,
};
