import { PropsWithChildren, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';

interface HeadingProps extends HTMLAttributes<HTMLElement> {
  level: 'h1' | 'h2' | 'h3' | 'h4';
  align?: 'unset' | 'left' | 'right' | 'center';
  color?: string;
  margin?: number;
}

const headingSizes = {
  h1: '24px',
  h2: '16px',
  h3: '16px',
  h4: '12px',
};

const headingWeights = {
  h1: '600',
  h2: '600',
  h3: '500',
  h4: '500',
};

export const Heading = ({ children, ...props }: PropsWithChildren<HeadingProps>) => (
  <Styled.Text as={props.level} {...props}>
    {children}
  </Styled.Text>
);

const Styled = {
  Text: styled.div<HeadingProps>`
    margin: ${({ margin = 0 }) => `0 0 ${margin}px`};
    color: ${({ theme, color = theme.colors.white[0] }) => color};
    font-size: ${({ level }) => headingSizes[level]};
    font-weight: ${({ level }) => headingWeights[level]};
    text-align: ${({ align = 'unset' }) => align};
  `,
};
