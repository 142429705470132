import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { routes } from 'routes';
import LogoImage from 'assets/images/logo.svg';
import CoffeeIcon from 'assets/images/coffee-icon.svg';
import { Button, Flex, Icon, DarkOverlay } from 'common/components';
import { useNotificationsContext } from 'modules/notifications/context';
import { NotificationsDropdown } from 'modules/notifications/components';
import { useAuth } from 'modules/auth/hooks';
import { useGetCheckoutUrl } from 'modules/payment/hooks';

import { UserDropdown } from './components';

export const NavBar = () => {
  const { uid } = useAuth();
  const { numberOfNewNotifications } = useNotificationsContext();
  const { getCheckoutUrl, loading } = useGetCheckoutUrl();
  const [isNotificationsVisible, setNotificationsVisible] = useState(false);

  return (
    <>
      <Styled.Wrapper>
        <Link to={routes.dashboard}>
          <Styled.Logo src={LogoImage} alt="logo" />
        </Link>
        <Flex gap={3}>
          <Button
            onClick={() =>
              getCheckoutUrl({ uid, priceId: process.env.REACT_APP_STRIPE_DONATE_ID || '', mode: 'payment' })
            }
            variant="secondary"
            loading={loading}
            disabled={loading}
          >
            <img src={CoffeeIcon} />
            Buy us a coffee
          </Button>
          <Styled.IconContainer>
            <Button variant="ghost" onClick={() => setNotificationsVisible((prev) => !prev)}>
              {numberOfNewNotifications > 0 && <Styled.Dot>{numberOfNewNotifications}</Styled.Dot>}
              <Icon icon="bell" size={32} />
            </Button>
          </Styled.IconContainer>
          <UserDropdown />
        </Flex>
      </Styled.Wrapper>
      {isNotificationsVisible && (
        <>
          <DarkOverlay onClick={() => setNotificationsVisible(false)} />
          <NotificationsDropdown />
        </>
      )}
    </>
  );
};

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    z-index: ${({ theme }) => theme.zIndexes.layoutBelow};
    display: flex;
    justify-content: space-between;
    padding: 24px 48px;
    background-color: ${({ theme }) => theme.colors.black[0]};
  `,

  Logo: styled.img`
    width: 144px;
  `,

  IconContainer: styled.span`
    position: relative;
  `,

  Dot: styled.div`
    position: absolute;
    z-index: ${({ theme }) => theme.zIndexes.layoutBelow};
    top: 2px;
    left: 34px;
    min-width: 16px;
    height: 12px;
    padding: 0 4px;
    background-color: ${({ theme }) => theme.colors.red[2]};
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black[0]};
    font-weight: 500;
    letter-spacing: 0.5;

    &:before {
      content: '';
      position: absolute;
      z-index: ${({ theme }) => theme.zIndexes.dropdown};
      left: 2px;
      right: 2px;
      top: -2px;
      bottom: -2px;
      border: 2px solid ${({ theme }) => theme.colors.red[2]};
    }
  `,
};
