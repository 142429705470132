import styled, { keyframes } from 'styled-components/macro';

export const Loader = () => (
  <Styled.Wrapper>
    <Styled.Loader>
      <Styled.LoaderInner />
    </Styled.Loader>
  </Styled.Wrapper>
);

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loadingInner = keyframes`
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
`;

const Styled = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
  `,

  Loader: styled.div`
    display: inline-block;
    width: 45px;
    height: 45px;
    position: relative;
    border: 5px solid ${({ theme }) => theme.colors.white[1]};
    top: 50%;
    animation: ${loading} 2s infinite ease;
  `,

  LoaderInner: styled.div`
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white[1]};
    animation: ${loadingInner} 2s infinite ease-in;
  `,
};
