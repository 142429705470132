import pluralize from 'pluralize';

import { Word } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

type Candle = {
  label: string;
  value: string;
  name: string;
  order: number;
  type: string;
  windowtype: 'time' | 'range';
};

type GetLastUsedCandleArgs = {
  sentence: Word[];
  candlesList: Candle[];
  isDefaultCandleUnitRespected: boolean;
  index?: number;
};

export const getLastUsedCandle = ({
  sentence,
  candlesList,
  isDefaultCandleUnitRespected,
  index,
}: GetLastUsedCandleArgs) => {
  const foundLastUsedCandle =
    index !== undefined ? sentence.find((item, idx) => idx === index) : sentence.findLast((item) => item.items?.candle);
  const lastUsedCandleType = candlesList.find(({ type }) => type === foundLastUsedCandle?.items?.candle?.type);
  const lastUsedCandleUnit = foundLastUsedCandle?.items?.candle?.unit ?? null;
  const lastUsedResultCandle = foundLastUsedCandle?.items?.candle;

  const frameDefaultValue =
    lastUsedCandleUnit && lastUsedResultCandle && isDefaultCandleUnitRespected
      ? {
          scope: Number(lastUsedResultCandle.value),
          unit: lastUsedCandleUnit,
          label: `${Number(lastUsedResultCandle.value)} ${pluralize(
            lastUsedCandleUnit.label,
            Number(lastUsedResultCandle.value) || 0,
          )}`,
          value: `${Number(lastUsedResultCandle.value)} ${lastUsedCandleUnit.label}}`,
        }
      : undefined;

  return {
    lastUsedResultCandle,
    lastUsedCandleType,
    frameDefaultValue,
  };
};
