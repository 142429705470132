import { useState } from 'react';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';

import { routes } from 'routes';
import { dbFirestore } from 'common/services/firebase';

type CheckoutUrlProps = {
  uid: string;
  priceId: string;
  mode: string;
};

export const useGetCheckoutUrl = () => {
  const [loading, setLoading] = useState(false);

  const getCheckoutUrl = async ({ uid, priceId, mode }: CheckoutUrlProps) => {
    setLoading(true);
    const checkoutSessionRef = collection(dbFirestore, 'customers', uid, 'checkout_sessions');
    const docRef = await addDoc(checkoutSessionRef, {
      price: priceId,
      mode,
      success_url: `${window.location.origin}${routes.checkoutSuccess}`,
      cancel_url: `${window.location.origin}${routes.checkoutError}`,
    });

    const unsubscribe = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data() as { error?: { message: string }; url?: string };

      if (error || url) {
        unsubscribe();
        setLoading(false);
        if (url) {
          window.location.assign(url);
        }
      }
    });
  };

  return {
    getCheckoutUrl,
    loading,
  };
};
