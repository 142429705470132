import { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'styles';
import { Modal } from 'common/components/Modal';

type ConfirmationModalProps = {
  action: MouseEventHandler;
  setIsModalOpen: (isOpen: boolean) => void;
  content: string;
  name: string;
};

export const ConfirmationModal = ({ action, setIsModalOpen, content, name }: ConfirmationModalProps) => (
  <Modal
    width="520px"
    heading={name}
    submit={{ content: name, onClick: action }}
    cancel={{ onClick: () => setIsModalOpen(false), variant: 'outlined' }}
    overlayProps={{ zIndex: theme.zIndexes.modalOverlay }}
  >
    <Styled.Heading>{content}</Styled.Heading>
  </Modal>
);

const Styled = {
  Heading: styled.h2`
    font-size: 36px;
    font-weight: 400;
    margin: 0;
    padding: 8px 16px;
  `,
};
