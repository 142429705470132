import { useState } from 'react';
import styled from 'styled-components/macro';

import { Button, CircleLoader, Flex, Heading, Icon, Text } from 'common/components';
import { useFilters } from 'modules/strategies/hooks';
import { StrategyFilters } from 'modules/strategies/components/StrategiesFilters';
import { MergedStrategy } from 'modules/strategies/types';

import { StrategyListItem } from './components';

export type StrategiesListProps = {
  mergedStrategies?: MergedStrategy[] | null;
};

export const StrategiesList = ({ mergedStrategies }: StrategiesListProps) => {
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const filteredStrategies = useFilters(mergedStrategies);

  return (
    <>
      <Styled.Wrapper>
        <Button variant="ghost" style={{ alignSelf: 'flex-end' }} onClick={() => setAreFiltersVisible(true)}>
          <Icon icon="filter-lines" />
          <Heading level="h2">Filters</Heading>
        </Button>
        <Styled.ListNav>
          <Text size="lg">Strategy name</Text>
          <Text size="lg">Symbol</Text>
          <Text size="lg">Capital</Text>
          <Text size="lg">Total return</Text>
          <Text size="lg">Capital chart</Text>
          <Text size="lg">Last activities</Text>
        </Styled.ListNav>
        {filteredStrategies ? (
          filteredStrategies.length > 0 ? (
            <Styled.List>
              {filteredStrategies.map(({ strategy, reports, capital, totalReturn, lastActivities, chart }) => (
                <StrategyListItem
                  key={strategy.id}
                  reports={reports}
                  {...strategy}
                  capital={capital}
                  totalReturn={totalReturn}
                  lastActivities={lastActivities}
                  chart={chart}
                />
              ))}
            </Styled.List>
          ) : (
            <Flex direction="column" gap={8} align="flex-start" style={{ marginTop: '32px' }}>
              <Heading level="h1">You didn`t create any strategy.</Heading>
              <Text size="lg">We think you should change it!</Text>
            </Flex>
          )
        ) : (
          <Styled.LoaderWrapper>
            <CircleLoader size="L" />
            Collecting your strategies...
          </Styled.LoaderWrapper>
        )}
      </Styled.Wrapper>
      <StrategyFilters areFiltersVisible={areFiltersVisible} setAreFiltersVisible={setAreFiltersVisible} />
    </>
  );
};

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  ListNav: styled.nav`
    display: flex;
    padding: 8px 32px 4px 8px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black[2]};

    & > * {
      width: 15%;

      &:first-child {
        width: 25%;
      }
    }
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
  `,

  LoaderWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin: 60px auto;
    font-size: 22px;
  `,
};
