export enum NotificationSource {
  OTHER = 0,
  BACKTESTER = 1,
  PAPER_TRADING = 2,
  LIVE_TRADING = 3,
}

export enum NotificationKind {
  DEFAULT = 0,
  SUCCESS = 1,
  WARNING = 2,
  ERROR = 3,
}

export enum NotificationColors {
  'green' = 1,
  'yellow',
  'red',
}

export enum NotificationStrategyKind {
  'success' = 1,
  'warning',
  'error',
}

export type NotificationViewed = {
  nid: string;
  viewed: boolean;
};

export type NotificationData = NotificationViewed & {
  title: string;
  text: string;
  date: number;
  source: NotificationSource;
  type: NotificationKind;
  testid?: string;
  uid?: string;
  isDark?: boolean;
};
