import styled from 'styled-components/macro';

import { OrderActions, TradingType } from 'types';
import { Icon, Text } from 'common/components';
import { useStrategyContext } from 'modules/creator/context';
import { useStrategy } from 'modules/creator/hooks';
import { OrderActionSection } from 'modules/creator/components/StrategyInterface/components/OrderActionSection';

const OrderActionSections = {
  [OrderActions.STOP_LOSS]: [OrderActions.STOP_LOSS],
  [OrderActions.TRAILING_STOP_LOSS]: [OrderActions.TRAILING_STOP_LOSS_ACTIVATE, OrderActions.TRAILING_STOP_LOSS],
  [OrderActions.CLOSE_POSITION]: [OrderActions.CLOSE_POSITION],
};

type PositionSectionProps = {
  tradingType: TradingType;
};

export const PositionSection = ({ tradingType }: PositionSectionProps) => {
  const [isExpanded] = useStrategy<boolean>(`isExpanded/${tradingType}/section`);
  const { writeStrategy } = useStrategyContext();

  return (
    <Styled.Container>
      <Styled.Head onClick={() => writeStrategy(`isExpanded/${tradingType}/section`, !isExpanded)}>
        <Icon icon={isExpanded ? 'arrow-down' : 'arrow-up'} />
        <Text size="lg">{tradingType} position</Text>
      </Styled.Head>

      {isExpanded && (
        <Styled.Content>
          <OrderActionSection type={OrderActions.OPEN} subTypes={[OrderActions.OPEN]} tradingType={tradingType}>
            {Object.entries(OrderActionSections).map(([key, value]) => (
              <OrderActionSection type={key} subTypes={value} key={key} tradingType={tradingType} nested />
            ))}
          </OrderActionSection>
        </Styled.Content>
      )}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.elevation[1]};
    height: fit-content;
  `,

  Head: styled.button`
    background-color: ${({ theme }) => theme.colors.black[1]};
    border-top: 1px solid ${({ theme }) => theme.colors.black[3]};
    display: flex;
    padding: 12px 16px;
    gap: 10px;

    p::first-letter {
      text-transform: capitalize;
    }
  `,

  Content: styled.section`
    padding: 16px 8px;
  `,
};
