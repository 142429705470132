import styled from 'styled-components/macro';

import { Button, Flex, Heading, Text, Icon } from 'common/components';
import { useNavigateStrategy } from 'routes/hooks';

export const EmptyStrategies = () => {
  const navigateStrategy = useNavigateStrategy();

  return (
    <Styled.Container>
      <Flex direction="column" align="flex-start" gap={8}>
        <Heading level="h1">There’s nothing here for now </Heading>
        <Text size="lg">Create someting first</Text>
      </Flex>
      <Button variant="primary" onClick={navigateStrategy}>
        <Icon icon="bot-add" />
        New strategy
      </Button>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 76px 0;
  `,
};
