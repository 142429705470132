import { useState } from 'react';
import styled from 'styled-components/macro';

import { Heading, Icon, Tooltip, ConfirmationModal } from 'common/components';
import { theme } from 'styles';
import { useBoxActions } from 'modules/creator/components/ConditionsFlow/hooks';
import { BoundingBoxType } from 'modules/creator/components/ConditionsFlow/types';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

type Option = {
  label: string;
  icon: string;
  color?: string;
  hideOnFirst: boolean;
  action: string;
  confirmationActionName?: string;
  confirmationContent?: string;
};

const Options: Option[] = [
  {
    label: 'Delete',
    icon: 'trash',
    color: theme.colors.red[2],
    hideOnFirst: true,
    action: 'deleteBoundingBox',
    confirmationActionName: 'Delete',
    confirmationContent: 'Do you want to delete this bounding box?',
  },
];

type BoundingBoxOptionsProps = {
  isFirst?: boolean;
  currentItem: BoundingBoxType;
};

export const BoundingBoxOptions = ({ isFirst, currentItem }: BoundingBoxOptionsProps) => {
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [lastClickedOption, setLastClickedOption] = useState<Required<
    Pick<Option, 'action' | 'confirmationActionName' | 'confirmationContent'>
  > | null>(null);
  const { orderAction } = useOrderActionSectionContext();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const actions: Record<string, any> = useBoxActions(currentItem);

  return (
    <Styled.Wrapper>
      {isConfirmationPopupOpen && lastClickedOption && (
        <ConfirmationModal
          name={lastClickedOption.confirmationActionName}
          setIsModalOpen={setIsConfirmationPopupOpen}
          content={lastClickedOption.confirmationContent}
          action={actions[lastClickedOption.action]}
        />
      )}
      <Tooltip id={`bounding-box-options-${orderAction}-${currentItem.id}`} content="More options for: group name ">
        <Styled.MoreButton onClick={() => setIsOptionsOpen((prev) => !prev)}>
          <Icon icon="more" />
        </Styled.MoreButton>
      </Tooltip>
      {isOptionsOpen && (
        <Styled.OptionsWrapper>
          {Options.map(({ label, icon, color, hideOnFirst, action, confirmationActionName, confirmationContent }) =>
            hideOnFirst && isFirst ? null : (
              <li key={label}>
                <Styled.OptionButton
                  onClick={() => {
                    if (confirmationActionName && confirmationContent) {
                      setLastClickedOption({
                        action,
                        confirmationActionName,
                        confirmationContent,
                      });
                      setIsConfirmationPopupOpen(true);
                    } else {
                      actions[action]();
                    }
                  }}
                >
                  <Icon icon={icon} color={color} />
                  <Heading level="h3" color={color}>
                    {label}
                  </Heading>
                </Styled.OptionButton>
              </li>
            ),
          )}
        </Styled.OptionsWrapper>
      )}
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div`
    position: absolute;
    top: 5px;
    transform: translateY(-100%);
    right: 0;
  `,
  MoreButton: styled.button`
    border: none;
    background-color: transparent;
    color: inherit;
    display: flex;
    align-items: center;
  `,

  OptionsWrapper: styled.ul`
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    border: none;
    background-color: ${({ theme }) => theme.colors.elevation[3]};
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  `,

  OptionButton: styled.button`
    padding: 8px 12px;
    display: flex;
    gap: 12px;
    align-items: center;
  `,
};
