import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { BoundingBoxType, ConditionFlowItemType, NodeType } from 'modules/creator/components/ConditionsFlow/types';
import {
  addNode,
  addNodeDownWithBoundingBox,
  addNodeRightWithBoundingBox,
  normalizeFlowItems,
} from 'modules/creator/components/ConditionsFlow/actions';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { useFlowItems } from './useFlowItems';

export const useNodeActions = (currentItem: NodeType, siblings: ConditionFlowItemType[], parent?: BoundingBoxType) => {
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy } = useStrategyContext();
  const [flowItems] = useFlowItems();
  const path = `flow/${tradingType}/${orderAction}/details/items`;

  return {
    addNodeDown: () =>
      parent &&
      writeStrategy(path, {
        ...(flowItems ?? {}),
        ...addNode({ node: currentItem, siblings, parent, nodeRelation: 'or' }),
      }),
    addNodeDownWithBoundingBox: () =>
      parent &&
      writeStrategy(path, { ...(flowItems ?? {}), ...addNodeDownWithBoundingBox(currentItem, siblings, parent) }),
    addNodeRight: () =>
      parent &&
      writeStrategy(path, {
        ...(flowItems ?? {}),
        ...addNode({ node: currentItem, siblings, parent, nodeRelation: 'and' }),
      }),
    addNodeRightWithBoundingBox: () =>
      parent &&
      writeStrategy(path, { ...(flowItems ?? {}), ...addNodeRightWithBoundingBox(currentItem, siblings, parent) }),
    deleteNode: () => {
      const baseItems = flowItems ?? {};
      const itemsToDelete: Record<string, null> = {};

      for (const key in baseItems) {
        if (key === currentItem.id) {
          itemsToDelete[key] = null;
        }
      }

      writeStrategy(path, normalizeFlowItems({ ...baseItems, ...itemsToDelete }));
    },
  };
};
