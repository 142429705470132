import styled from 'styled-components/macro';

import { convertFlowTypeToText, isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import { FlowArrow } from 'modules/creator/components/ConditionsFlow/components';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

export const FlowStarterItems = () => {
  const { tradingType, orderAction } = useOrderActionSectionContext();

  // here check only for undefined, null is ok
  if (tradingType === undefined) {
    return null;
  }

  return (
    <Styled.Container>
      <Styled.SimpleNode>{convertFlowTypeToText(orderAction)}</Styled.SimpleNode>
      <FlowArrow size={64} />
      {!isValueConditionModal(orderAction) && (
        <>
          <Styled.SimpleNode>if</Styled.SimpleNode>
          <FlowArrow size={64} />
        </>
      )}
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div`
    background-color: inherit;
    min-width: 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  `,
  SimpleNode: styled.button`
    background-color: ${({ theme }) => theme.buttons.primary.default};
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    color: white;
    white-space: nowrap;
  `,
};
