import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { AuthSidebar } from 'modules/auth/components';

export const AuthLayout = ({ children }: PropsWithChildren) => (
  <Styled.Wrapper>
    <AuthSidebar>{children}</AuthSidebar>
  </Styled.Wrapper>
);

const Styled = {
  Wrapper: styled.div`
    background: url('./images/triangle-bg.svg') 100% 0 / cover no-repeat ${({ theme }) => theme.colors.black[0]};
    display: flex;
  `,
};
