import { theme } from 'styles/theme';

export const getBoxTheme = (depth: number) => {
  if (depth === 0) {
    return { background: theme.colors.black[2], border: `1px solid ${theme.colors.black[3]}` };
  }
  if (depth % 2) {
    return { background: theme.colors.black[1], border: 'none' };
  }

  return { background: theme.colors.elevation[3], border: `1px dashed ${theme.colors.black[3]}` };
};
