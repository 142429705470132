import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { routes } from 'routes';
import { Button, DarkOverlay, Icon, ConfirmationModal } from 'common/components';
import { OrderActions, TradingType } from 'types/StrategyTypes';
import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useStrategy } from 'modules/creator/hooks';
import { useFlowData } from 'modules/creator/components/ConditionsFlow/hooks';
import { ConditionsFlow } from 'modules/creator/components/ConditionsFlow';
import { convertFlowTypeToText, isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

export const ConditionButton = () => {
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const location = useLocation();
  const isStrategyDetailsView = location.pathname.includes(routes.strategies);
  const { tradingType, orderAction } = useOrderActionSectionContext();
  const { writeStrategy, removeInStrategy } = useStrategyContext();
  const [isModalOpen] = useStrategy(`modals/${tradingType}/${orderAction}`);
  const [condition] = useFlowData('result/text');

  const formatButtonText = () => {
    if (orderAction === OrderActions.TRAILING_STOP_LOSS_ACTIVATE) {
      if (!condition || !tradingType) {
        return 'Activate when';
      }

      return `Activate for ${tradingType} when ${condition}`;
    }

    if (isValueConditionModal(orderAction) && !condition) {
      return 'Set value';
    }

    return condition || 'Set conditions';
  };

  const resetConditions = () => {
    removeInStrategy(`flow/${tradingType}/${orderAction}`);
    setIsConfirmationPopupOpen(false);
  };

  const getClassName = () => {
    if (tradingType !== TradingType.long) {
      return undefined;
    }

    if (orderAction === OrderActions.OPEN) {
      return 'onboarding-strategy-set-open';
    }

    if (orderAction === OrderActions.STOP_LOSS) {
      return 'onboarding-strategy-set-stop-loss';
    }

    return undefined;
  };

  return (
    <>
      <Styled.Wrapper className={getClassName()}>
        {isConfirmationPopupOpen && (
          <ConfirmationModal
            setIsModalOpen={setIsConfirmationPopupOpen}
            action={resetConditions}
            name="Reset"
            content={`Do you want to reset ${convertFlowTypeToText(orderAction)} ${
              isValueConditionModal(orderAction) ? 'value' : 'conditions'
            }?`}
          />
        )}
        {condition && (
          <Styled.DeleteButton
            noPadding
            onClick={() => setIsConfirmationPopupOpen(true)}
            className="clear-position-action-button"
          >
            <Icon icon="trash" size={16} />
          </Styled.DeleteButton>
        )}
        <Styled.InsideWrapper
          onClick={() => writeStrategy(`modals/${tradingType}/${orderAction}`, !isModalOpen)}
          tradingType={tradingType}
        >
          {formatButtonText()}
        </Styled.InsideWrapper>
      </Styled.Wrapper>
      {isModalOpen && !isStrategyDetailsView && (
        <>
          <ConditionsFlow />
          <DarkOverlay zIndex={103} />
        </>
      )}
    </>
  );
};

const Styled = {
  Wrapper: styled.div`
    width: fit-content;
    max-width: 100%;
    position: relative;

    &:hover {
      .clear-position-action-button {
        display: flex;
      }
    }
  `,

  DeleteButton: styled(Button)`
    display: none;
    height: fit-content;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    padding: 6px;
  `,

  InsideWrapper: styled.div<{ tradingType: TradingType }>`
    height: fit-content;
    width: fit-content;
    margin-right: 32px;
    max-width: 100%;
    background-color: ${({ theme, tradingType }) =>
      tradingType === TradingType.long ? theme.colors.purple[2] : theme.colors.red[3]};
    padding: 4px 12px;
    font-weight: 300;
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  `,
};
