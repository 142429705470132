import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import useResizeObserver from 'use-resize-observer';

import { theme } from 'styles';
import { formatDate } from 'common/utils';
import { useGetReport } from 'common/hooks';
import { Button, CircleLoader, Flex, Heading, Icon, Layout, Text, Tab, Tabs, SelectInput } from 'common/components';
import { Onboarding } from 'modules/onboarding';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';
import { onboardingStepsCreateStrategy } from 'data/onboarding';
import { StrategyInterface } from 'modules/creator/components/StrategyInterface';

import { useGetStrategy } from './hooks';
import { StrategyInfo, BacktesterReport, StartBacktestModal } from './components';
import { getLastActivities, getStrategyBotStatus } from './utils';

export const StrategyDetailsView = () => {
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const { id } = useParams();
  const navigate = useNavigate();
  const { onboardingsState, callback } = useOnboardingContext();
  const { strategy, strategyTested, strategyPending } = useGetStrategy(id);
  const lastTested = strategyTested?.at(0);
  const lastPending = strategyPending?.at(0);
  const { report: lastFullRaport } = useGetReport(lastTested?.testid);
  const [activeReport, setActiveReport] = useState(lastTested?.testid);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { report: activeFullReport } = useGetReport(activeReport);

  const lastActivities =
    lastTested || lastPending ? getLastActivities(lastPending ? 'pending' : 'tested', lastPending?.timestamp) : [];
  const totalReturn = lastFullRaport?.report.summary.find(({ name }) => name === 'Return')?.value;
  const options = strategyTested?.map((item) => ({
    value: item.testid,
    label: `Backtest ${formatDate(item.timestamp * 1000)}`,
  }));

  const renderReportsTab = () => {
    if (lastPending) {
      return (
        <Flex direction="column" gap={16}>
          <CircleLoader size="L" />
          <Heading level="h2">Backtest in progress...</Heading>
        </Flex>
      );
    } else if (activeFullReport) {
      return (
        <>
          <Flex direction="column" align="normal" gap={16}>
            <Flex justify="flex-end">
              <SelectInput
                options={options}
                name="backtest"
                onChange={(item) => setActiveReport(item?.value)}
                width="L"
              />
            </Flex>
            <BacktesterReport {...activeFullReport} />
          </Flex>
        </>
      );
    }
    return (
      <Flex direction="column" align="flex-start" gap={24}>
        <Flex direction="column" align="flex-start" gap={8}>
          <Heading level="h1">Weird... This strategy don’t have any backtest</Heading>
          <Text size="lg" color={theme.colors.white[1]}>
            Maybe you want to change it?
          </Text>
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    if (strategyTested && strategyTested.length > 0) {
      setActiveReport(lastTested?.testid);
    }
  }, [lastTested, strategyTested]);

  return (
    <Layout className="main-window">
      <Onboarding
        callback={callback}
        overlayHeight={height ? height + 120 : undefined}
        {...onboardingsState[OnboardingNames.STRATEGYCREATOR]}
        name={OnboardingNames.STRATEGYCREATOR}
      />
      <div ref={ref}>
        <Flex direction="column" align="normal" justify="flex-start" gap={32}>
          <div>
            <Button variant="ghost" onClick={() => navigate(-1)}>
              <Icon icon="arrow-left" size={32} /> back to list
            </Button>
          </div>
          {strategy && (
            <StrategyInfo
              strategy={strategy}
              botType={getStrategyBotStatus(lastActivities, totalReturn)}
              lastActivities={lastActivities}
              setIsModalOpen={setIsModalOpen}
            />
          )}
          <div className="onboarding-backtest-report">
            <Tabs className="onboarding-strategy-tabs">
              <Tab label="Strategy structure">
                <Styled.StrategyContainer>
                  <StrategyInterface />
                </Styled.StrategyContainer>
              </Tab>
              <Tab label="Raport backtester">{renderReportsTab()}</Tab>
            </Tabs>
          </div>
        </Flex>
      </div>
      {isModalOpen && strategy && strategy.asset && (
        <StartBacktestModal
          setIsModalOpen={setIsModalOpen}
          strategyName={strategy.name ?? ''}
          id={id ?? ''}
          asset={strategy.asset}
        />
      )}
    </Layout>
  );
};

const Styled = {
  StrategyContainer: styled.div`
    pointer-events: none;
  `,
};
