export type CandleVar = {
  name: string;
  result_type: 'absolute' | 'volume';
  aliases: string[];
};

export type Candle = {
  name: string;
  order: number;
  type: string;
  windowtype: 'time' | 'range';
};

export type Exchange = {
  dsid: number;
  range: string[];
  name: string;
};

export type DataSource = {
  symbol: string;
  market: string;
  exchanges: Exchange[];
  icon: string;
  longName: string;
};

export type Scope = {
  start?: number | 'inf' | '-inf';
  end?: number | 'inf' | '-inf';
};

export type Indata = {
  fixed: boolean;
  data: string[];
};

export type OutData = {
  displayKey: string;
  name: string;
  scope?: Scope;
}[];

export type OptimizableParam = {
  defaultValue: number;
  label: string;
  toDisplay: boolean;
};

export type Params = {
  indata: Indata;
  outdata: OutData;
} & Record<string, OptimizableParam>;

export type Indicator = {
  market: string[];
  name: string;
  fullname: string;
  params: Params;
  result_type: 'absolute' | 'relative' | 'volume';
  aliases: string[];
};

export enum TextEngineItemTypes {
  Indicator = 'indicators',
  CandleVar = 'candle_vars',
  Phrase = 'phrases',
  ValuePhrase = 'value_phrases',
  Variable = 'variables',
  Value = 'values',
  Subsentence = 'subsentence',
  Own = 'own',
  TableVariable = 'table_variables',
}

type PhraseBase = {
  format: string;
  key: string;
  structure: TextEngineItemTypes[][];
  aliases: string[];
};

export type Phrase = PhraseBase & {
  result_type: 'bool';
};

export type ValuePhrase = PhraseBase & {
  result_type: 'value';
};

export type Variable = {
  name: string;
  aliases: string[];
  type: 'table' | 'point';
};

export type Value = {
  name: string;
  aliases: string[];
};

export type Dictionary = {
  candle_vars: Record<string, CandleVar>;
  candles: Record<string, Candle>;
  data_sources: Record<string, DataSource>;
  indicators: Record<string, Indicator>;
  phrases: Record<string, Phrase>;
  value_phrases: Record<string, ValuePhrase>;
  variables: Record<string, Variable>;
  values: Record<string, Value>;
};
