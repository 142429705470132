import styled from 'styled-components/macro';

export const Divider = () => {
  return <Styled.Line />;
};

const Styled = {
  Line: styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.black[2]};
  `,
};
