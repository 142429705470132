import { useState, useEffect } from 'react';

import {
  ResultCandle,
  ResultIndicator,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { BoundingBoxType, ConditionFlowItemType, Rel, Result } from 'modules/creator/components/ConditionsFlow/types';
import { OrderActions } from 'types/StrategyTypes';
import { isAndRelObject, isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';

import { useFirstBox } from './useFirstBox';
import { useFlowItems } from './useFlowItems';
import { useNodeForValueModal } from './useNodeForValueModal';

type RecurencyArgs = {
  items: ConditionFlowItemType[];
  currentBoxId: string;
  rel?: Rel;
  text?: string;
  indicators?: ResultIndicator[];
  candles?: ResultCandle[];
  depth?: number;
};

export const useFlowResultData = (type: OrderActions) => {
  const [result, setResult] = useState<Result>({ text: '', rel: [], indicators: [], candles: [] });
  const firstBox = useFirstBox();
  const [flowItems] = useFlowItems();
  const singleNode = useNodeForValueModal();

  const recurency = ({
    items,
    currentBoxId,
    rel = [],
    text = '',
    depth = 0,
    indicators = [],
    candles = [],
  }: RecurencyArgs) => {
    const found = items.find((item) => item.id === currentBoxId) as BoundingBoxType | undefined;
    if (!found) {
      return { text, rel, indicators, candles };
    }
    const children = items
      .filter((item) => found.children.includes(item.id))
      .sort((a, b) => a.whichInOrder - b.whichInOrder);
    let iterator = 0;

    for (const child of children) {
      if (child.type === 'node') {
        text = child.text
          ? `${text}${text && child.whichInOrder > 0 ? ` ${found.childrenRelationType} ` : ''}${child.text}`
          : text;
        child.relationType === 'or' || iterator === 0 ? rel.push(child.rel) : rel.push({ and: [child.rel] });
        indicators.push(...(child.indicators ?? []));
        candles.push(...(child.candles ?? []));
      } else {
        if (children[0].id === child.id) {
          text = ` ( ${text}`;
        } else if (text) {
          text = `${text} ${child.selfRelationType} ( `;
        }

        const {
          text: returnedText,
          rel: returnedRel,
          indicators: returnedIndicators,
          candles: ReturnedCandles,
        } = recurency({
          items: items.sort((a, b) => a.whichInOrder - b.whichInOrder),
          currentBoxId: child.id,
          text,
          depth: depth + 1,
        });
        text = returnedText;
        indicators = [...indicators, ...returnedIndicators];
        candles = [...candles, ...ReturnedCandles];
        child.selfRelationType === 'or' ? rel.push(returnedRel) : rel.push({ and: returnedRel });
      }
      iterator++;
    }

    text = depth ? `${text} )` : text;
    return { text, rel, indicators, candles };
  };

  useEffect(() => {
    if (flowItems) {
      if (firstBox) {
        const data = recurency({ items: Object.values(flowItems), currentBoxId: firstBox.id });
        if (isAndRelObject(data.rel[0])) {
          setResult({
            ...data,
            rel: [data.rel[0].and, ...data.rel.slice(1, data.rel.length)],
          });
        } else {
          setResult(data);
        }
      } else if (isValueConditionModal(type) && singleNode?.text) {
        setResult({
          text: singleNode.text,
          rel: singleNode.rel,
          indicators: singleNode.indicators ?? [],
          candles: singleNode.candles ?? [],
        });
      }
    }
  }, [firstBox, flowItems]);

  return result;
};
