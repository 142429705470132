import { useSendAuthorizedApiRequest } from 'common/hooks';
import { Dictionary } from 'modules/creator/types/dictionarySchema';

export const useGetDictionary = () => {
  const { result, loading, errors } = useSendAuthorizedApiRequest<{ dictionary: Dictionary }>({
    url: `${process.env.REACT_APP_API_V2}/dictionary`,
  });

  return { dictionary: result?.dictionary, loading, errors };
};
