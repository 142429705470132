import { ReactElement, ReactNode, useState, FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { theme } from 'styles';
import { Text } from 'common/components';

type TabProps = {
  label: string;
  children: ReactNode;
};

interface TabsProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactElement<TabProps>[];
}

export const Tab: FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

export const Tabs: FC<TabsProps> = ({ children, ...rest }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (label: string) => {
    setActiveTab(label);
  };

  return (
    <>
      <Styled.List {...rest}>
        {children.map((tab) => {
          const label = tab.props.label;
          return (
            <li key={label}>
              <Styled.Item onClick={() => handleClick(label)} isActive={label === activeTab}>
                <Text size="sm" color={theme.colors.white[label === activeTab ? 0 : 1]}>
                  {label}
                </Text>
              </Styled.Item>
            </li>
          );
        })}
      </Styled.List>
      <Styled.Content>
        {children.map((item) => {
          if (item.props.label !== activeTab) return undefined;
          return item.props.children;
        })}
      </Styled.Content>
    </>
  );
};

const Styled = {
  List: styled.ul`
    display: flex;
    list-style-type: none;
    padding: 0;
  `,

  Item: styled.button<{ isActive: boolean }>`
    min-width: 260px;
    padding: 12px 16px;
    text-align: center;
    border-width: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: ${({ theme, isActive }) => (isActive ? theme.colors.white[0] : 'transparent')};
  `,

  Content: styled.div`
    margin-top: 20px;
  `,
};
