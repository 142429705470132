export const routes = {
  dashboard: '/',
  login: '/login',
  reset: '/reset-password',
  confirmPassword: '/confirm-password',
  confirmPasswordSuccess: '/confirm-password-success',
  create: '/create',
  strategies: '/strategies',
  notifications: '/notifications',
  checkoutSuccess: '/checkout-success',
  checkoutError: '/checkout-error',
  rewards: '/rewards' /* change to proper path when it will be ready */,
} as const;
