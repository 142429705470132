import { v4 as uuidv4 } from 'uuid';

import { BoundingBoxType, NodeType } from 'modules/creator/components/ConditionsFlow/types';

export const getDefaultFlowItems = () => {
  const placeholderNodeInBoundingBoxId = uuidv4();

  const placeholderNodeInBoundingBox: NodeType = {
    type: 'node',
    id: placeholderNodeInBoundingBoxId,
    parent: '123',
    arrow: false,
    whichInOrder: 0,
    text: '',
    candles: '',
    indicators: '',
    rel: '',
  };

  const firstBoundingBox: BoundingBoxType = {
    type: 'box',
    id: '123',
    parent: '',
    arrow: false,
    children: [placeholderNodeInBoundingBoxId],
    whichInOrder: 0,
    childrenRelationType: 'or',
    name: '',
    depth: 0,
  };

  return {
    box: firstBoundingBox,
    node: placeholderNodeInBoundingBox,
  };
};
