import { useState } from 'react';
import styled from 'styled-components/macro';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import { TextEngineItemTypes } from 'modules/creator/types';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';
import { HintableItem } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import {
  isHintIndicator,
  isSentenceFinishedAndValid,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import { useConditionSetterContext } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

export const ConditionSetterHints = () => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const { sentence, typingError, filteredHints, handleHintClick } = useConditionSetterContext();
  const { orderAction } = useOrderActionSectionContext();

  const sortHints = (a: HintableItem, b: HintableItem) => {
    if (isHintIndicator(a) && !isHintIndicator(b)) {
      return 1;
    } else if (!isHintIndicator(a) && isHintIndicator(b)) {
      return -1;
    }
    return (
      Math.min(...a.item.aliases.map((alias) => alias.length)) -
      Math.min(...b.item.aliases.map((alias) => alias.length))
    );
  };

  if (typingError) {
    return null;
  }

  return (
    <>
      <Styled.Header>Suggestions</Styled.Header>
      {isSentenceFinishedAndValid(sentence, orderAction) ? (
        <Styled.FinishInfo>That everything you need here</Styled.FinishInfo>
      ) : (
        <Styled.Wrapper>
          <button ref={(node) => setPrevEl(node)}>
            <ArrowLeftIcon />
          </button>
          <Styled.Swiper
            modules={[Navigation]}
            slidesPerView={'auto'}
            navigation={{
              prevEl,
              nextEl,
            }}
            spaceBetween={10}
          >
            {filteredHints.sort(sortHints).map((hint) =>
              !isHintIndicator(hint) ? (
                hint.item.aliases.map((alias) => (
                  <Styled.SwiperSlide key={alias}>
                    <Styled.Hint type="button" hintType={hint.type} onClick={() => handleHintClick(hint, alias)}>
                      {alias}
                    </Styled.Hint>
                  </Styled.SwiperSlide>
                ))
              ) : (
                <Styled.SwiperSlide key={hint.item.fullname}>
                  <Styled.Hint type="button" hintType={hint.type} onClick={() => handleHintClick(hint)}>
                    {hint.item.fullname}
                  </Styled.Hint>
                </Styled.SwiperSlide>
              ),
            )}
          </Styled.Swiper>
          <button ref={(node) => setNextEl(node)}>
            <ArrowRightIcon />
          </button>
        </Styled.Wrapper>
      )}
    </>
  );
};

const Styled = {
  FinishInfo: styled.span`
    color: ${({ theme }) => theme.colors.paleGreen};
    font-weight: 500;
    font-size: 14px;
  `,
  ErrorInfo: styled.span`
    color: ${({ theme }) => theme.colors.red[2]};
    font-weight: 500;
  `,
  Wrapper: styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
  Swiper: styled(Swiper)`
    display: flex;
    width: calc(100% - 72px);
  `,
  Header: styled.header`
    color: ${({ theme }) => theme.colors.white[1]};
  `,
  Hint: styled.button<{ hintType: TextEngineItemTypes }>`
    white-space: nowrap;
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid
      ${({ theme, hintType }) =>
        hintType === TextEngineItemTypes.Own ? theme.colors.blue : theme.buttons.primary.default};
  `,
  SwiperSlide: styled(SwiperSlide)`
    width: fit-content;
  `,
};
