import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { FirebaseError } from '@firebase/util';
import styled from 'styled-components/macro';

import { InputWidth } from 'types';
import { Flex } from 'common/components';
import { getInputLabelColor } from 'common/utils';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: FieldError | FirebaseError;
  labelVariant?: 'default' | 'horizontal';
  errorVariant?: 'default' | 'horizontal';
  width?: 'S' | 'M' | 'MAX';
  postInputContent?: ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { name, label, error, labelVariant = 'default', errorVariant = 'default', width, postInputContent, ...props },
    ref,
  ) => (
    <Flex
      direction={labelVariant === 'horizontal' ? 'row' : 'column'}
      align="flex-start"
      gap={labelVariant === 'horizontal' ? 10 : 8}
    >
      {label && (
        <Styled.Label htmlFor={name} labelVariant={labelVariant} isError={!!error}>
          {label}
        </Styled.Label>
      )}
      <Flex
        direction={errorVariant === 'horizontal' ? 'row' : 'column'}
        align={errorVariant === 'horizontal' ? 'center' : 'flex-start'}
        gap={errorVariant === 'horizontal' ? 10 : 8}
        style={!postInputContent ? { width: InputWidth[width ?? 'M'] } : undefined}
      >
        <Flex gap={16} width="100%">
          <Styled.Field id={name} labelVariant={labelVariant} width={width} {...props} ref={ref} isError={!!error} />
          {postInputContent}
        </Flex>
        {error && <Styled.Error>{error.message}</Styled.Error>}
      </Flex>
    </Flex>
  ),
);

Input.displayName = 'Input';

const Styled = {
  Label: styled.label<Pick<InputProps, 'labelVariant'> & { isError: boolean }>`
    font-size: ${({ labelVariant }) => (labelVariant === 'horizontal' ? 'inherit' : '14px')};
    color: ${({ isError, labelVariant }) => getInputLabelColor(isError, labelVariant)};
    white-space: ${({ labelVariant }) => (labelVariant === 'horizontal' ? 'nowrap' : 'normal')};
    padding: ${({ labelVariant }) => (labelVariant === 'horizontal' ? '12px 0' : 0)};
  `,

  Field: styled.input<Pick<InputProps, 'labelVariant' | 'error' | 'width'> & { isError: boolean }>`
    width: ${({ width }) => InputWidth[width ?? 'M']};
    border: 2px solid ${({ theme, isError }) => (isError ? theme.colors.red[2] : theme.colors.black[2])};
    height: 48px;
    padding: 12px;
    gap: 10px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.black[2]};
    color: ${({ theme }) => theme.colors.white[0]};
    font-size: 16px;
    outline: 0;

    &:disabled {
      opacity: 0.5;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${({ theme }) => theme.colors.white[0]};
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px ${({ theme }) => theme.colors.black[2]};
    }
  `,

  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
