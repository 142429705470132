import { FC, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { FieldError } from 'react-hook-form';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { autoPlacement, Middleware } from '@floating-ui/dom';
import { Modifier, StrictModifierNames } from 'react-popper';

import { theme } from 'styles';
import { Flex, Input } from 'common/components';
import { InputWidth } from 'types';
import { getInputLabelColor } from 'common/utils';

interface DatePickerInputProps {
  name: string;
  label?: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: (Date | null)[]) => void;
  error?: FieldError;
  labelVariant?: 'default' | 'horizontal';
  errorVariant?: 'default' | 'horizontal';
  width?: 'S' | 'M' | 'MAX';
  minDate?: Date | null;
  maxDate?: Date | null;
}

export const DatePickerInput: FC<DatePickerInputProps> = ({
  name,
  startDate,
  endDate,
  label,
  onChange,
  error,
  labelVariant = 'default',
  errorVariant = 'default',
  width,
  minDate,
  maxDate,
}) => {
  const middlewares = useMemo<Middleware[]>(
    () => [autoPlacement({ autoAlignment: false, allowedPlacements: ['bottom-start'] })],
    [],
  );

  return (
    <>
      <Flex
        direction={labelVariant === 'horizontal' ? 'row' : 'column'}
        align="flex-start"
        gap={labelVariant === 'horizontal' ? 10 : 8}
      >
        {label && (
          <Styled.Label htmlFor={name} labelVariant={labelVariant} isError={!!error}>
            {label}
          </Styled.Label>
        )}
        <Flex
          direction={errorVariant === 'horizontal' ? 'row' : 'column'}
          align={errorVariant === 'horizontal' ? 'center' : 'flex-start'}
          gap={errorVariant === 'horizontal' ? 10 : 8}
          style={{ width: InputWidth[width ?? 'MAX'] }}
        >
          <DatePicker
            popperPlacement="bottom-start"
            onChange={onChange}
            monthsShown={2}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            customInput={<Input name="startDate" width="MAX" />}
            popperClassName="custom-datepicker"
            selectsRange
            disabledKeyboardNavigation
            dateFormat="dd-MM-yyyy"
            popperModifiers={middlewares as unknown as ReadonlyArray<Modifier<StrictModifierNames>>}
          />
          {error && <Styled.Error>{error.message}</Styled.Error>}
        </Flex>
      </Flex>
      <DatePickerWrapperStyles />
    </>
  );
};

const DatePickerWrapperStyles = createGlobalStyle`
  .custom-datepicker {
    &[data-placement^=bottom] {
      z-index: ${({ theme }) => theme.zIndexes.modalLeveling(100)};
    }

    .react-datepicker {
      padding: 20px;
      border: 1px solid ${theme.colors.black[2]};
      border-radius: 8px;
      background-color: ${theme.colors.black[1]};
      font-size: 12px;
      color: ${theme.colors.white[1]};
    }

    .react-datepicker__triangle {
      display: none;
    }
    
    .react-datepicker__header {
      padding: 0;
      border-bottom: none;
      background-color: ${theme.colors.black[1]};
    }

    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
      font-size: 14px;
      font-weight: 400;
      color: ${theme.colors.white[1]};
    }

    .react-datepicker__day--outside-month {
      color: transparent !important;
      pointer-events: none;
    }

    .react-datepicker__navigation {
      top: 16px;
    }

    .react-datepicker__month {
      margin: 8px;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      width: 42px;
      margin: 2px 0;
      line-height: 32px;
      color: ${theme.colors.white[1]};

      &:hover {
        border-radius: 3px;
        background-color: ${theme.buttons.primary.hover};
        color: ${theme.colors.white[0]};
      }
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      border-radius: 0;
      background-color: ${theme.colors.black[2]};
      color: ${theme.colors.white[0]};

      &.react-datepicker__day--range-start, &.react-datepicker__day--selecting-range-start {
        border-radius: 3px 0 0 3px;
        background-color: ${theme.buttons.primary.default};
      }

      &.react-datepicker__day--range-end, &.react-datepicker__day--selecting-range-end {
        border-radius: 0 3px 3px 0;
        background-color: ${theme.buttons.primary.default};
      }
    }

    .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
      font-weight: 600;
      color: ${theme.colors.white[0]};
    }

    .react-datepicker__day--disabled {
      color: ${({ theme }) => theme.colors.white[2]};

      &:hover {
         background-color: inherit;
         color: ${({ theme }) => theme.colors.white[2]};
      }
    }
  }
`;

const Styled = {
  Label: styled.label<Pick<DatePickerInputProps, 'labelVariant'> & { isError: boolean }>`
    font-size: ${({ labelVariant }) => (labelVariant === 'horizontal' ? 'inherit' : '14px')};
    color: ${({ isError, labelVariant }) => getInputLabelColor(isError, labelVariant)};
    white-space: ${({ labelVariant }) => (labelVariant === 'horizontal' ? 'nowrap' : 'normal')};
    padding: ${({ labelVariant }) => (labelVariant === 'horizontal' ? '12px 0' : 0)};
  `,

  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
