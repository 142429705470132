import { Word } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { CandleVar, TextEngineItemTypes } from 'modules/creator/types';

export const createRelationString = (sentence: Word[]) => {
  let rel = '';
  for (const { items, type, origin, stringContent } of sentence) {
    if (type === TextEngineItemTypes.Indicator && items?.candle && items.indicator) {
      rel += `${rel ? ' ' : ''}${items.candle.id}.${items.indicator.id}${
        items.indicator.outdata !== 'single' ? `$${items.indicator.chosenOut}` : ''
      }`;
    } else if (type === TextEngineItemTypes.CandleVar && items?.candle) {
      rel += `${rel ? ' ' : ''}${items.candle.id}.${(origin as CandleVar).name}`;
    } else {
      rel += `${rel ? ' ' : ''}${stringContent.main}`;
    }
  }

  return rel;
};
