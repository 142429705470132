import { v4 as uuidv4 } from 'uuid';

import {
  BoundingBoxType,
  ConditionFlowItemType,
  NodeType,
  RelationType,
} from 'modules/creator/components/ConditionsFlow/types';

type AddNodeArgs = {
  node: NodeType;
  siblings: ConditionFlowItemType[];
  parent: BoundingBoxType;
  nodeRelation: RelationType;
};

export const addNode = ({ node: { parent: parentId }, siblings, parent, nodeRelation }: AddNodeArgs) => {
  const maxOrder = Math.max(...(siblings || []).map((sibling) => sibling.whichInOrder));
  const newNode: NodeType = {
    type: 'node',
    id: uuidv4(),
    parent: parentId,
    arrow: false,
    relationType: nodeRelation,
    whichInOrder: maxOrder + 1,
    text: '',
    candles: '',
    indicators: '',
    rel: '',
  };

  const newNodes = [
    ...(siblings?.map((item: any) => ({
      ...item,
      relationType: nodeRelation,
      arrow: true,
    })) || []),
    newNode,
  ];

  const result: Record<string, ConditionFlowItemType> = {};

  newNodes.forEach((node) => {
    result[node.id] = node;
  });

  result[parentId] = {
    ...parent,
    children: [...parent.children, newNode.id],
    childrenRelationType: nodeRelation,
  };

  return result;
};
