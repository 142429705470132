import { MouseEvent } from 'react';
import styled, { css } from 'styled-components/macro';

import { TextEngineItemTypes } from 'modules/creator/types';
import { isSentenceFinishedAndValid } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/cotracts';
import { Flex } from 'common/components';
import { useConditionSetterContext } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/context';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';
import { Word } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';

export const ConditionSetterTextInput = () => {
  const {
    sentence,
    typingError,
    resultSentence,
    conditionText,
    setConditionText,
    handleKeyPress,
    inputRef,
    onDelete,
    setDisplayedModalInfo,
    setEditedItem,
  } = useConditionSetterContext();
  const { orderAction } = useOrderActionSectionContext();

  const handleSentenceItemClick = (e: MouseEvent, item: Word, index: number) => {
    e.stopPropagation();
    if (item.type === TextEngineItemTypes.CandleVar || item.type === TextEngineItemTypes.Indicator) {
      setEditedItem({ item: item.origin, index, subItems: item.items });
      setDisplayedModalInfo(item.type);
    }
  };

  return (
    <Styled.Wrapper typingError={typingError} isReadyToSubmit={isSentenceFinishedAndValid(sentence, orderAction)}>
      {resultSentence.map((item, index) => (
        <Flex
          key={`${item.stringContent.main}${index}`}
          align="flex-end"
          style={{
            cursor:
              item.type === TextEngineItemTypes.CandleVar || item.type === TextEngineItemTypes.Indicator
                ? 'pointer'
                : 'default',
          }}
          onClick={(e) => handleSentenceItemClick(e, item, index)}
        >
          <Styled.WordWrapper type={item.type}>{item.stringContent.main}</Styled.WordWrapper>
          <Styled.ParamsWrapper>{item.stringContent.params}</Styled.ParamsWrapper>
        </Flex>
      ))}
      {!isSentenceFinishedAndValid(sentence, orderAction) ? (
        <Styled.TextInput
          type="text"
          placeholder="Type here"
          value={conditionText}
          onChange={(e) => setConditionText(e.target.value)}
          onKeyDown={handleKeyPress}
          autoFocus
          ref={inputRef}
          error={typingError}
        />
      ) : (
        <Styled.RestWrapper onClick={() => isSentenceFinishedAndValid(sentence, orderAction) && onDelete()} />
      )}
    </Styled.Wrapper>
  );
};

const Styled = {
  Wrapper: styled.div<{ typingError: boolean; isReadyToSubmit: boolean }>`
    width: 100%;
    border-bottom: 2px solid ${({ theme, typingError }) => (typingError ? theme.colors.red[2] : theme.colors.white[0])};
    font-size: 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;

    ${({ isReadyToSubmit }) =>
      isReadyToSubmit &&
      css`
        height: 100%;
        align-items: center;
        font-size: 25px;
        padding: 10px;
      `}
  `,

  RestWrapper: styled.div`
    flex: 1;
    height: 28px;
  `,

  WordWrapper: styled.span<{ type: TextEngineItemTypes }>`
    white-space: nowrap;
    font-weight: 400;
    color: ${({ theme, type }) =>
      type === TextEngineItemTypes.Own ? theme.colors.blue : theme.buttons.primary.default};
  `,

  ParamsWrapper: styled.span`
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.buttons.primary.default};
  `,

  TextInput: styled.input<{ error: boolean }>`
    display: flex;
    flex: 1;
    height: 100%;
    background-color: inherit;
    border: none;
    color: ${({ error, theme }) => (error ? theme.colors.red[2] : 'inherit')};
    font-size: 20px;
    padding-bottom: 5px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.white[2]};
    }
  `,
};
