import styled from 'styled-components/macro';

import { BoundingBoxType, ConditionFlowItemType, RelationType } from 'modules/creator/components/ConditionsFlow/types';
import { useBoxActions, useFlowData, useSortedFlowItemChildren } from 'modules/creator/components/ConditionsFlow/hooks';
import { ConditionFlowItem } from 'modules/creator/components/ConditionsFlow/components/FlowConditionItems';
import { getBoxTheme } from 'modules/creator/components/ConditionsFlow/utils';
import { ExpandButton, FlowArrow } from 'modules/creator/components/ConditionsFlow/components/FlowItems';

import { BoundingBoxName, BoundingBoxOptions } from './components';

interface BoundingBoxProps extends BoundingBoxType {
  currentItem: BoundingBoxType;
  siblings?: ConditionFlowItemType[];
  isFirst?: boolean;
}

export const BoundingBox = ({
  arrow,
  childrenRelationType,
  selfRelationType,
  whichInOrder,
  siblings,
  currentItem,
  isFirst,
  id,
  name,
  depth,
}: BoundingBoxProps) => {
  const [isInPreviewMode] = useFlowData('isInPreviewMode');
  const { addNodeOutter } = useBoxActions(currentItem);
  const sortedItems = useSortedFlowItemChildren(id);

  const successorItem = siblings?.find((item) => item.whichInOrder - 1 === whichInOrder);
  const isSaccessorEmptyNode = successorItem && successorItem.type === 'node' && !successorItem.text;

  const isExpandButtonDisabled = (type: RelationType = 'and') => {
    if (sortedItems.length === 1 && sortedItems[0].type === 'node') {
      return true;
    }

    return childrenRelationType === type;
  };

  return (
    <Styled.NodeWithArrowContainer relationType={selfRelationType}>
      <Styled.Container relationType={childrenRelationType} isFirst={isFirst} depthTheme={getBoxTheme(depth)}>
        <BoundingBoxName id={id} currentName={name} />
        <BoundingBoxOptions isFirst={isFirst} currentItem={currentItem} />
        {sortedItems.map((item) => (
          <ConditionFlowItem {...item} siblings={sortedItems} currentItem={item} key={item.id} />
        ))}
        {isFirst && !isInPreviewMode && (
          <ExpandButton
            buttonType="box"
            layout="bottom"
            onClick={() => addNodeOutter('or')}
            disabled={isExpandButtonDisabled('or')}
          />
        )}
        {isFirst && !isInPreviewMode && (
          <ExpandButton
            buttonType="box"
            layout="right"
            onClick={() => addNodeOutter()}
            disabled={isExpandButtonDisabled()}
          />
        )}
      </Styled.Container>
      {arrow && !(isInPreviewMode && isSaccessorEmptyNode) && (
        <FlowArrow
          size={32}
          direction={selfRelationType === 'and' ? 'right' : 'down'}
          info={selfRelationType === 'and' ? 'and' : 'or'}
          boxTheme={getBoxTheme(depth - 1)}
        />
      )}
    </Styled.NodeWithArrowContainer>
  );
};

const Styled = {
  Container: styled.div<{
    relationType?: RelationType;
    isFirst?: boolean;
    depthTheme: { background: string; border: string };
  }>`
    position: relative;
    display: flex;
    flex-direction: ${({ relationType }) => (relationType === 'and' ? 'row' : 'column')};
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    padding: 16px 100px 70px 16px;
    gap: 40px;
    border-radius: 3px;
    border: ${({ depthTheme }) => depthTheme.border};
    background-color: ${({ depthTheme }) => depthTheme.background};
  `,

  NodeWithArrowContainer: styled.div<{ relationType?: RelationType }>`
    display: flex;
    width: fit-content;
    height: fit-content;
    gap: 10px;
    flex-direction: ${({ relationType }) => (relationType === 'and' ? 'row' : 'column')};
    align-items: center;
  `,
};
