import { BoundingBox, ConditionNode } from 'modules/creator/components/ConditionsFlow/components';
import { BoundingBoxType, ConditionFlowItemType, NodeType } from 'modules/creator/components/ConditionsFlow/types';

type ConditionFlowItemProps = ConditionFlowItemType & {
  siblings: ConditionFlowItemType[];
  currentItem: ConditionFlowItemType;
};

export const ConditionFlowItem = (props: ConditionFlowItemProps) => {
  return props.type === 'node' ? (
    <ConditionNode {...props} currentItem={props.currentItem as NodeType} />
  ) : (
    <BoundingBox {...props} currentItem={props.currentItem as BoundingBoxType} />
  );
};
