import { ChangeEvent, KeyboardEventHandler, RefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useStrategyContext } from 'modules/creator/context/StrategyCreatorContext';
import { useStrategy } from 'modules/creator/hooks';
import { Bar, Icon, Tooltip } from 'common/components';
import { useDocument, useWriteDocument } from 'common/hooks';
import { theme } from 'styles';

export const StrategyBar = () => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const ref: RefObject<HTMLInputElement> = useRef(null);
  const { id } = useParams();
  const { writeStrategy } = useStrategyContext();
  const writeDocument = useWriteDocument();
  const [strategyName] = useStrategy('name');
  const [strategiesNames] = useDocument<Record<string, string>>('indexes/strategiesNames');

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter' && ref.current) {
      ref.current.blur();
    }
  };

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    writeStrategy('name', e.target.value);
    writeDocument(`indexes/strategiesNames/${id}`, e.target.value);

    if (strategiesNames && Object.values(strategiesNames).some((name) => name === e.target.value)) {
      setErrorMessage('You already have a strategy with this name');
    } else if (errorMessage) {
      setErrorMessage(null);
    }
  };

  return (
    <Bar onClick={() => ref.current?.focus()} style={{ borderTop: 'none' }} className="onboarding-strategy-create-name">
      <Icon icon="bot" />
      <Styled.Input
        onClick={(e) => e.stopPropagation()}
        placeholder="Type your strategy name here"
        value={strategyName ?? ''}
        onChange={updateName}
        ref={ref}
        onKeyDown={onKeyDown}
        className="onboarding-not-falsy-value"
      />
      {errorMessage && (
        <Tooltip
          id="strategy-name-error"
          content={errorMessage}
          place="top"
          border={`2px solid ${theme.colors.red[0]}`}
        >
          <Icon icon="error" color={theme.colors.red[2]} />
        </Tooltip>
      )}
    </Bar>
  );
};

const Styled = {
  Input: styled.input`
    background-color: inherit;
    border: none;
    width: 100%;
    color: inherit;
    font-size: 24px;
    outline: none;

    &::placeholder {
      color: inherit;
    }
  `,
};
