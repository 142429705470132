import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import LogoImage from 'assets/images/logo.svg';

export const AuthSidebar = ({ children }: PropsWithChildren) => (
  <Styled.Container>
    <Styled.Header>
      <Styled.Logo src={LogoImage} alt="logo" />
    </Styled.Header>
    {children}
  </Styled.Container>
);

const Styled = {
  Container: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: normal;
    width: 436px;
    height: 100vh;
    padding: 48px;
    background-color: ${({ theme }) => theme.colors.black[1]};
  `,

  Header: styled.header`
    position: absolute;
    top: 48px;
    left: 48px;
  `,

  Logo: styled.img`
    width: 222px;
  `,
};
