import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'styles';
import { OrderActions } from 'types';
import { Button, Flex, Heading, Icon, Text, Tooltip } from 'common/components';
import { useOrderActionSectionContext } from 'modules/creator/components/StrategyInterface/components/OrderActionSection/context';

import { TextEngineMessageType as TextEngineMessageProps } from './types';

export const TextEngineMessage = ({ title, color, icon, subTitle }: TextEngineMessageProps) => {
  const [example, setExmaple] = useState<string>();
  const { orderAction } = useOrderActionSectionContext();

  const reloadExample = useCallback(
    (prev?: string) => {
      const orderActionToExamplesMap = {
        [OrderActions.OPEN]: ['SMA(20) cross from below SMA(50)', 'rsi(14) cross from below level 20'],
        [OrderActions.STOP_LOSS]: ['minimum of 10 low(1h candle)', 'current low(1d candle)'],
        [OrderActions.TRAILING_STOP_LOSS_ACTIVATE]: [
          'last_price cross from below open(1d, candle)',
          'last_price is above SMA(20)',
        ],
        [OrderActions.TRAILING_STOP_LOSS]: ['previous low(1h, candle)', 'minimum of 2 low(1h, candle)'],
        [OrderActions.CLOSE_POSITION]: ['last_price cross form above SMA(20)', 'last_price cross form above SMA(20)'],
      };

      const properExamples = [...orderActionToExamplesMap[orderAction].filter((value) => value !== prev)];
      const randomIndex = Math.floor(Math.random() * properExamples.length);

      return properExamples[randomIndex];
    },
    [orderAction],
  );

  useEffect(() => {
    if (!example) {
      setExmaple((prev) => reloadExample(prev));
    }
  }, [example, reloadExample]);

  return (
    <Styled.Container className="onboarding-strategy-example">
      <Styled.IconWrapper color={color}>
        <Icon icon={icon} />
      </Styled.IconWrapper>
      <Styled.ContentWrapper>
        <Styled.Heading level="h1">{title}</Styled.Heading>
        <Flex direction="column" align="flex-start" width="100%" gap={4}>
          <Text size="sm" color={theme.colors.white[2]}>
            {subTitle}
          </Text>
          <Flex gap={6} direction="column" align="flex-start" width="100%">
            <Styled.Description>Example:</Styled.Description>
            <Flex justify="space-between" width="100%">
              {example && <Text size="sm">{example}</Text>}
              <Tooltip id="text-engine-refresh-button" content="Refresh example">
                <Button
                  variant="ghost"
                  onClick={() => setExmaple((prev) => reloadExample(prev))}
                  style={{ padding: 0, height: 'fit-content' }}
                >
                  <Icon icon="refresh" />
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      </Styled.ContentWrapper>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.section`
    display: flex;
    width: 500px;
    min-height: 120px;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.black[3]};
    background-color: ${({ theme }) => theme.colors.black[2]};
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  `,

  IconWrapper: styled.div<Pick<TextEngineMessageProps, 'color'>>`
    display: flex;
    justify-content: flex-start;
    padding: 9px;
    max-width: 42px;
    flex: 1;
    border-radius: 8px 0px 0px 8px;
    background-color: ${({ color }) => color};
  `,

  ContentWrapper: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 0px 8px 8px 0px;
    padding: 16px;
  `,

  Description: styled.p`
    margin: 0;
    color: ${({ theme }) => theme.colors.white[2]};
    font-size: 12px;
    font-weight: 400;
  `,

  Heading: styled(Heading)`
    font-size: 22px;
    font-weight: 400;
  `,
};
