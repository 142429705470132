import { AuthenticatedRootRouter, UnauthenticatedRouter } from 'routes';
import { useAuth } from 'modules/auth/hooks';
import { NotificationsProvider } from 'modules/notifications/context';
import { StrategiesContextProvider } from 'modules/strategies/context';
import { OnboardingContextProvider } from 'modules/onboarding/context';

const App = () => {
  const { uid } = useAuth();

  return uid ? (
    <NotificationsProvider>
      <StrategiesContextProvider>
        <OnboardingContextProvider>
          <AuthenticatedRootRouter />
        </OnboardingContextProvider>
      </StrategiesContextProvider>
    </NotificationsProvider>
  ) : (
    <UnauthenticatedRouter />
  );
};

export default App;
