import { Activity } from 'modules/strategies/types';

export const getStrategyBotStatus = (lastActivities: Activity[] | undefined, totalReturn?: number) => {
  if (!lastActivities) {
    return 'neutral';
  }
  if (lastActivities[0]?.status === 'progress') {
    return 'progress';
  }

  if (lastActivities[0]?.status === 'done') {
    if (totalReturn && totalReturn > 0) {
      return 'happy';
    }

    return 'sad';
  }

  return 'neutral';
};
