import { HTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  size: 'lg' | 'md' | 'sm';
  align?: 'unset' | 'left' | 'right' | 'center';
  color?: string;
  margin?: number;
}

const textSizes = {
  lg: '16px',
  md: '16px',
  sm: '14px',
};

const textWeights = {
  lg: '400',
  md: '300',
  sm: '400',
};

export const Text = ({ children, ...props }: PropsWithChildren<TextProps>) => (
  <Styled.Text {...props}>{children}</Styled.Text>
);

const Styled = {
  Text: styled.p<TextProps>`
    margin: ${({ margin = 0 }) => `0 0 ${margin}px`};
    color: ${({ theme, color = theme.colors.white[0] }) => color};
    font-size: ${({ size }) => textSizes[size]};
    font-weight: ${({ size }) => textWeights[size]};
    text-align: ${({ align = 'unset' }) => align};
  `,
};
