import {
  SentenceItem,
  TypableAbsoluteCandleVar,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

export const isHintAbsoluteCandle = (hint: SentenceItem): hint is TypableAbsoluteCandleVar => {
  const { type, item } = hint;
  return type === TextEngineItemTypes.CandleVar && item.result_type === 'absolute';
};
