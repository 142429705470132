import { MouseEvent } from 'react';
import styled from 'styled-components/macro';

import { AssetType } from 'types';
import { Bar, Text, Tooltip } from 'common/components';
import { useStrategyContext } from 'modules/creator/context';
import { useStrategy } from 'modules/creator/hooks';
import { SymbolAndMarketModal } from 'modules/creator/components/SymbolAndMarketModal';

export const SymbolBar = () => {
  const { writeStrategy } = useStrategyContext();
  const [isModalOpen] = useStrategy('modals/asset');
  const [asset] = useStrategy<AssetType>('asset');

  const onBarClick = (e: MouseEvent) => {
    e.stopPropagation();
    writeStrategy('modals/asset', true);
  };

  return (
    <Bar onClick={onBarClick} className="onboarding-strategy-set-symbol">
      {asset ? (
        <>
          <img src={`data:image/svg+xml;base64,${asset.icon}`} alt={asset.symbol} width={24} height={24} />
          <Styled.Aligner>
            <Tooltip id="symbol-bar" content="Specify the symbol on which the strategy will run." place="right">
              <span>{asset.symbol}</span>
            </Tooltip>
            <span>{asset.exchange.toUpperCase()}</span>
          </Styled.Aligner>
        </>
      ) : (
        <>
          <Styled.IconPlaceholder />
          <Tooltip id="symbol-bar" content="Specify the symbol on which the strategy will run." place="right">
            <Text size="lg">Set symbol for your strategy</Text>
          </Tooltip>
        </>
      )}
      {isModalOpen && <SymbolAndMarketModal />}
    </Bar>
  );
};

const Styled = {
  IconPlaceholder: styled.div`
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.colors.elevation[4]};
    border-radius: 50%;
  `,

  Aligner: styled.span`
    width: 100%;
    padding-left: 2px;
    display: flex;
    justify-content: space-between;
  `,
};
