import { Sentence } from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { isValueConditionModal } from 'modules/creator/components/ConditionsFlow/utils';
import { OrderActions } from 'types';

import { areSubsentencesFinished } from './areSubsentencesFinished';
import { isSentenceFinished } from './isSentenceFinished';

export const isSentenceFinishedAndValid = (sentence: Sentence | null, flowType: OrderActions) => {
  if (!sentence || !isSentenceFinished(sentence) || !areSubsentencesFinished(sentence)) {
    return false;
  }

  const allowedResultType = isValueConditionModal(flowType) ? 'value' : 'bool';

  return sentence.assignedPhrase?.item.result_type === allowedResultType;
};
