import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';

import { theme } from 'styles';
import { routes } from 'routes';
import LogoImage from 'assets/images/logo.svg';
import { Button, Flex, Heading, Icon } from 'common/components';
import { useNavigateStrategy } from 'routes/hooks';
import { useWriteDocument } from 'common/hooks';
import { OnboardingNames, useOnboardingContext } from 'modules/onboarding/context';

export const NavSidebar = () => {
  const navigate = useNavigate();
  const navigateStrategy = useNavigateStrategy();
  const writeDocument = useWriteDocument();
  const { setOnboardingsState } = useOnboardingContext();

  const launchOnboarding = (name: OnboardingNames) => {
    writeDocument(`tutorials/${name}`, false);
    setOnboardingsState((prev) => ({ ...prev, [name]: { ...prev[name], stepIndex: 0, run: true, strategyId: '' } }));
    if (name === OnboardingNames.STRATEGYCREATOR) {
      navigateStrategy();
    } else {
      navigate('/');
    }
  };

  return (
    <Styled.Sidebar>
      <Styled.LogoContainer>
        <NavLink to={routes.dashboard}>
          <Styled.Logo src={LogoImage} alt="logo" />
        </NavLink>
      </Styled.LogoContainer>
      <Button
        onClick={navigateStrategy}
        variant="primary"
        size="L"
        justify="flex-start"
        className="onboarding-nav-create"
      >
        <Icon icon="add" /> Create strategy
      </Button>
      <Styled.NavItem to={routes.dashboard} className="onboarding-nav-home">
        <Icon icon="home" /> Home
      </Styled.NavItem>
      <Styled.NavItem to={routes.rewards} className="onboarding-nav-rewards">
        <Icon icon="trophy" /> Rewards
      </Styled.NavItem>
      <Flex direction="column" align="stretch">
        <Heading level="h3" color={theme.colors.white[1]} margin={8}>
          Strategies
        </Heading>
        <Styled.NavItem to={routes.strategies} className="onboarding-nav-strategies">
          <Icon icon="chart" />
          My strategies
        </Styled.NavItem>
      </Flex>
      <Flex direction="column" align="stretch">
        <Heading level="h3" color={theme.colors.white[1]} margin={8}>
          Support
        </Heading>
        <Styled.NavItem
          to="https://discord.gg/Vr9E8ZCGxw"
          target="_blank"
          rel="noreferrer"
          className="onboarding-nav-discord"
        >
          <Icon icon="discord" />
          Discord
          <Icon icon="arrow-up-right" />
        </Styled.NavItem>
      </Flex>
      <Flex direction="column" align="stretch">
        <Heading level="h3" color={theme.colors.white[1]} margin={8}>
          Tutorials
        </Heading>
        <Styled.Button
          variant="ghost"
          justify="flex-start"
          size="L"
          onClick={() => launchOnboarding(OnboardingNames.DASHBOARD)}
        >
          Dashboard
        </Styled.Button>
        <Styled.Button
          variant="ghost"
          justify="flex-start"
          size="L"
          onClick={() => launchOnboarding(OnboardingNames.STRATEGYCREATOR)}
        >
          Strategy creator
        </Styled.Button>
      </Flex>
    </Styled.Sidebar>
  );
};

const Styled = {
  Sidebar: styled.aside`
    position: fixed;
    z-index: ${({ theme }) => theme.zIndexes.layoutBelow};
    top: 0;
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 100%;
    padding: 16px 32px;
    gap: 24px;
    background-color: ${({ theme }) => theme.colors.black[1]};
  `,

  Button: styled(Button)`
    &:hover {
      background-color: ${({ theme }) => theme.colors.black[3]};
    }
  `,

  LogoContainer: styled.div`
    padding: 24px 16px;
  `,

  Logo: styled.img`
    width: 144px;
  `,

  NavItem: styled(NavLink)`
    color: ${({ theme }) => theme.colors.white[0]};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border-radius: 4px;
    gap: 16px;
    font-size: 16px;
    padding: 12px 16px;

    &[aria-current] {
      background-color: ${({ theme }) => theme.colors.black[2]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.black[3]};
    }
  `,
};
