import {
  FC,
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
  useReducer,
} from 'react';
import { ref, onChildAdded, onValue } from 'firebase/database';
import _ from 'lodash';

import { dbNotifications } from 'common/services/firebase';
import { sendAuthorizedApiRequest } from 'common/utils';
import { useAuth } from 'modules/auth/hooks';
import { NotificationData, NotificationViewed } from 'modules/notifications/types';
import { ToastsContainer } from 'modules/notifications/components';
import { toastReducer, ToastActionKind } from 'modules/notifications/reducers';

type NotificationsContextProps = {
  notifications: NotificationData[];
  dropdownNotifications: NotificationData[];
  numberOfNewNotifications: number;
  markNotificationsAsViewed: (data: NotificationViewed[]) => void;
  addToast: (data: NotificationData) => void;
  removeToast: (nid: string) => void;
};

export const NotificationsContext = createContext<NotificationsContextProps>({} as NotificationsContextProps);

export const useNotificationsContext = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [numberOfNewNotifications, setNumberOfNewNotifications] = useState(0);
  const [state, dispatch] = useReducer(toastReducer, { toasts: notifications });
  const { uid } = useAuth();
  const notificationsRef = useMemo(() => ref(dbNotifications, `notifications/${uid}`), [uid]);

  const markNotificationsAsViewed = useCallback(async (data: NotificationViewed[]) => {
    return await sendAuthorizedApiRequest({
      url: `${process.env.REACT_APP_API_V1}/notifications`,
      method: 'POST',
      data,
    });
  }, []);

  const addToast = (data: NotificationData) => {
    dispatch({ type: ToastActionKind.ADD_TOAST, payload: data });
  };

  const removeToast = (nid: string) => {
    dispatch({ type: ToastActionKind.DELETE_TOAST, payload: nid });
  };

  useEffect(() => {
    const unsubscribe = onChildAdded(notificationsRef, (snapshot) => {
      const data: NotificationData = { ...snapshot.val() };
      const isFound = notifications.some((item) => item.nid === data.nid);

      if (!isFound) {
        setNotifications((prevState) => [data, ...prevState]);
        if (!data.viewed) {
          addToast(data);
        }
      }
    });

    return unsubscribe;
  }, [notifications, notificationsRef]);

  useEffect(() => {
    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      const data: NotificationData[] = _.toArray(snapshot.val()).sort((a, b) => +b.date - +a.date);

      if (data.length > 0) {
        setNotifications(data);
      }
    });

    return unsubscribe;
  }, [notificationsRef]);

  useEffect(() => {
    if (notifications.length > 0) {
      const count = notifications.filter((item) => !item.viewed).length;

      setNumberOfNewNotifications(count);
    }
  }, [notifications]);

  const contextValue = {
    notifications,
    dropdownNotifications: notifications.slice(0, 7),
    numberOfNewNotifications,
    markNotificationsAsViewed,
    addToast,
    removeToast,
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
      <ToastsContainer toasts={state.toasts.slice(0, 3)} />
    </NotificationsContext.Provider>
  );
};
