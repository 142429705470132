import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

interface TextLinkProps extends LinkProps {
  color?: string;
}

export const TextLink = ({ children, ...props }: TextLinkProps) => <Styled.Link {...props}>{children}</Styled.Link>;

const Styled = {
  Link: styled(Link)<TextLinkProps>`
    cursor: pointer;
    color: ${({ theme, color }) => color || theme.colors.white[0]};
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
};
