import { MouseEventHandler, PropsWithChildren, FormHTMLAttributes, ReactEventHandler, HTMLAttributes } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';

import { Heading, Button, DarkOverlay, DarkOverlayProps, ButtonProps, Flex } from 'common/components';
import { CloseIcon } from 'assets/icons';
import { AllReactEvents, allReactEvents } from 'constants/events';

type ModalProps = {
  heading: string;
  overlayProps?: DarkOverlayProps;
  width?: string;
  height?: string;
  close?: MouseEventHandler;
  cancel?: ButtonProps & { content?: string };
  leftCancel?: ButtonProps & { content?: string };
  submit?: ButtonProps & { content?: string };
  buttonsAlign?: 'right' | 'space-between';
  formProps?: FormHTMLAttributes<HTMLFormElement>;
  eventsAllowedToBubble?: AllReactEvents;
  position?: 'absolute' | 'fixed';
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
};

type WrapperProps = {
  formProps?: FormHTMLAttributes<HTMLFormElement>;
};

const Wrapper = ({ formProps, children }: PropsWithChildren<WrapperProps>) =>
  formProps ? <Styled.Form {...formProps}>{children}</Styled.Form> : children;

export const Modal = ({
  children,
  heading,
  overlayProps = {},
  width,
  height,
  close,
  cancel,
  leftCancel,
  submit,
  formProps,
  eventsAllowedToBubble = [],
  position,
  wrapperProps,
}: PropsWithChildren<ModalProps>) => {
  const stopEvent: ReactEventHandler = (e) => {
    e.stopPropagation();
  };

  const eventsToStop = allReactEvents.filter((event) => !eventsAllowedToBubble.includes(event));
  const stoppedEventsProps = Object.fromEntries(eventsToStop.map((key) => [key, stopEvent]));

  return createPortal(
    <>
      <Styled.Wrapper
        zIndex={(overlayProps?.zIndex ?? 101) + 1}
        width={width}
        height={height}
        position={position}
        {...stoppedEventsProps}
        {...wrapperProps}
      >
        <Styled.TopBar>
          <Heading level="h2">{heading}</Heading>
          <Button
            variant="ghost"
            onClick={close ?? cancel?.onClick}
            disabled={cancel?.disabled}
            className={cancel?.className}
            style={{ padding: 0 }}
            type="button"
          >
            <CloseIcon />
          </Button>
        </Styled.TopBar>
        <Wrapper formProps={formProps}>
          <Flex direction="column" gap={16} align="stretch" height="100%">
            {children}
          </Flex>
          {(submit || formProps) && (
            <Styled.ButtonsWrapper placeLeft={!leftCancel}>
              {leftCancel && (
                <Button size="M" fitWidth {...leftCancel} type="button">
                  {leftCancel.content ?? 'Cancel'}
                </Button>
              )}
              <Flex style={{ minWidth: '250px' }} justify="space-between">
                {cancel && (
                  <Button size="M" fitWidth {...cancel} type="button">
                    {cancel.content ?? 'Cancel'}
                  </Button>
                )}
                <Button
                  variant="primary"
                  size="M"
                  fitWidth
                  type={formProps ? 'submit' : 'button'}
                  disabled={submit?.disabled || submit?.loading}
                  {...submit}
                >
                  {submit?.content ?? 'Ok'}
                </Button>
              </Flex>
            </Styled.ButtonsWrapper>
          )}
        </Wrapper>
      </Styled.Wrapper>
      <DarkOverlay {...stoppedEventsProps} {...overlayProps} />
    </>,
    document.getElementById('modal') as HTMLElement,
  );
};

const Styled = {
  Wrapper: styled.div<{ zIndex: number; width?: string; height?: string; position?: 'absolute' | 'fixed' }>`
    display: flex;
    flex-direction: column;
    position: ${({ position = 'fixed' }) => position};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
    gap: 16px;
    background-color: ${({ theme }) => theme.colors.black[1]};
    z-index: ${({ zIndex }) => zIndex};
    width: ${({ width = 'fit-content' }) => width};
    height: ${({ height = 'auto' }) => height};
    border-radius: 4px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  `,

  ButtonsWrapper: styled.div<{ placeLeft: boolean }>`
    display: flex;
    justify-content: ${({ placeLeft }) => (placeLeft ? 'flex-end' : 'space-between')};
    width: 100%;
    z-index: ${({ theme }) => theme.zIndexes.modal};

    button {
      min-width: 120px;
      flex: 0;
    }
  `,

  TopBar: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  Form: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
  `,
};
