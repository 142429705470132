import {
  TypablePhrase,
  TypableValuePhrase,
} from 'modules/creator/components/ConditionsFlow/components/ConditionSetterModal/types';
import { TextEngineItemTypes } from 'modules/creator/types';

type SearchForPhrasesWhereItemOnPositionArgs = {
  phrases: (TypablePhrase | TypableValuePhrase)[];
  itemTypes: TextEngineItemTypes[];
  position?: number;
};

export const searchForPhrasesWhereItemOnPosition = ({
  phrases,
  itemTypes,
  position = 0,
}: SearchForPhrasesWhereItemOnPositionArgs) => {
  const result = [];
  for (const phrase of phrases) {
    if (phrase.item.structure[position].some((item) => itemTypes.includes(item))) {
      result.push(phrase);
    }
  }
  return result;
};
