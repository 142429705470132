import styled from 'styled-components/macro';

import { Toast } from 'modules/notifications/components';
import { NotificationData } from 'modules/notifications/types';

interface ToastsContainerProps {
  toasts: NotificationData[];
}

export const ToastsContainer = ({ toasts }: ToastsContainerProps) => (
  <Styled.Container>
    {toasts?.map((toast) => (
      <Toast key={toast.nid} {...toast} />
    ))}
  </Styled.Container>
);

const Styled = {
  Container: styled.div`
    position: fixed;
    z-index: ${({ theme }) => theme.zIndexes.notification};
    display: flex;
    flex-direction: column;
    bottom: 24px;
    left: 32px;
    gap: 8px;
  `,
};
