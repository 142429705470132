import { useCallback } from 'react';
import { ref, set } from 'firebase/database';

import { db } from 'common/services/firebase';
import { useAuth } from 'modules/auth/hooks';

export const useWriteDocument = () => {
  const { uid } = useAuth();
  const setter = useCallback((path: string, data: any) => set(ref(db, `${uid}/${path}`), data), [uid]);

  return setter;
};
