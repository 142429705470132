import { useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

import { DatePickerInput } from 'common/components';
import { BacktestForm } from 'modules/strategies/components/StartBacktestModal/types';

type StartBacktestTimeFrameFormProps = {
  allowedDateRange?: (Date | null)[];
};

export const StartBacktestTimeFrameForm = ({ allowedDateRange }: StartBacktestTimeFrameFormProps) => {
  const [dateRange, setDateRange] = useState<(Date | null)[]>([null, null]);
  const [startDate, endDate] = dateRange;
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<BacktestForm>();
  const date = watch('date');

  useEffect(() => {
    if (date && date[0] && date[1]) {
      setDateRange(date);
    }
  }, [date, setDateRange]);

  return (
    <Controller
      control={control}
      name="date"
      rules={{
        required: {
          value: true,
          message: 'You must specify date range',
        },
      }}
      render={({ field }) => (
        <DatePickerInput
          {...field}
          startDate={startDate}
          endDate={endDate}
          label="Time frame for test"
          minDate={allowedDateRange ? allowedDateRange[0] : null}
          maxDate={allowedDateRange ? allowedDateRange[1] : null}
          onChange={(date) => {
            setDateRange(date);
            field.onChange(date);
          }}
          error={errors.date as FieldError}
        />
      )}
    />
  );
};
